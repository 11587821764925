import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-reborn';

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#dbdbdb',
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: '0.75rem',
    marginBottom: '8px',
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(54,54,54, 0.3)',
  }),
};

const MultiSelect = ({
  field,
  form: { touched, errors, /* handleChange */ setFieldValue, setTouched },
  list,
  ...props
}) => {
  const [value, setValue] = useState(() => null);

  const isLoading = false;

  const handleChange = value => {
    const newValue = Array.isArray(value) ? value.map(item => item.value) : [];
    setFieldValue(field.name, newValue);
    setTouched({ ...touched, [field.name]: true });
  };

  useEffect(() => {
    const newValue = list.filter(o => field.value.includes(o.value));
    setValue(() => newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div>
      <Select
        name={field.name}
        styles={customStyles}
        isClearable
        isMulti
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        options={list}
        value={value}
        placeholder={props.placeholder}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  list: PropTypes.array.isRequired,
};

export default MultiSelect;
