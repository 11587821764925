import {
  FETCH_STARBOOK_DATA,
  FETCH_STARBOOK_DATA_SUCCESS,
  FETCH_STARBOOK_DATA_ERROR,
  SET_SEARCH_PARAMS,
} from '../store/constants';

const initalState = {
  loading: false,
  error: false,
  response: false,
  employees: [],
  search: {},
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_STARBOOK_DATA:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_STARBOOK_DATA_SUCCESS:
      return { ...state, loading: false, employees: action.payload };
    case FETCH_STARBOOK_DATA_ERROR:
      return { ...state, loading: false, error: true };
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        search: { ...state.search, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;