import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './NavbarLink.module.scss';

const routerSelector = state => state.router;

const NavbarLink = ({ history, to, children }) => {
  const router = useSelector(routerSelector);
  const handleNavigate = () => {
    history.push(to);
  };

  // const active = router.location.pathname.includes(to) ? styles.active : '';
  const active = router.location.pathname === to ? styles.active : '';

  return (
    <div className={`navbar-item ${styles.link} ${active}`} onClick={handleNavigate}>
      {children}
    </div>
  );
};

NavbarLink.propTypes = {
  history: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavbarLink;
