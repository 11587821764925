import {
  PROFILE_FETCH,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_ERROR,
  SET_PROFILE_QUERY,
  CLEAR_PROFILE_QUERY,
} from '../store/constants';
import { getYear, getMonth } from 'date-fns';

const date = new Date();

const initalState = {
  loading: false,
  error: false,
  lines: [],
  profile: null,
  taskDetails: [],
  overTimeTypes: [],
  maconomyId: null,
  year: getYear(date),
  month: getMonth(date) + 1,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_PROFILE_QUERY:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_PROFILE_QUERY:
      return { ...initalState };
    case PROFILE_FETCH:
      return { ...state, loading: true, error: false };
    case PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case PROFILE_FETCH_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default reducer;