import { call, put, takeLatest, all } from 'redux-saga/effects';

import { UPDATE_CV_EXPERIENCES } from '../../store/constants';

import {
  doUpdateCvExperiencesSuccess,
  doUpdateCvExperiencesError,
} from '../../actions/cv';

import { doGetUserDetailsSuccess } from '../../actions/user';

import { doShowMessage } from '../../actions/message';

import { updateCvExperiences } from '../starbookApiCalls';

/* import * as R from 'ramda';
const { ifElse } = R; */

export function* cvExperiencesUpdateWorker(action) {
  try {
    const result = yield call(updateCvExperiences, action.payload);

    yield all([
      put(doGetUserDetailsSuccess(result.data)),
      put(doUpdateCvExperiencesSuccess({})),
      put(
        doShowMessage({
          type: 'success',
          content: 'Successfully updated experiences',
        }),
      ),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doUpdateCvExperiencesError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchCvExperiencesUpdate() {
  yield takeLatest(UPDATE_CV_EXPERIENCES, cvExperiencesUpdateWorker);
}
