import {
  FETCH_STARBOOK_DATA,
  FETCH_STARBOOK_DATA_SUCCESS,
  FETCH_STARBOOK_DATA_ERROR,
  FETCH_QUERY_DATA,
  FETCH_QUERY_DATA_SUCCESS,
  FETCH_QUERY_DATA_ERROR,
  SET_SEARCH_PARAMS,
} from './../store/constants';

const doFetchStarbookData = payload => ({
  type: FETCH_STARBOOK_DATA,
  payload,
});

const doFetchStarbookDataSuccess = payload => ({
  type: FETCH_STARBOOK_DATA_SUCCESS,
  payload,
});

const doFetchStarbookDataError = error => ({
  type: FETCH_STARBOOK_DATA_ERROR,
  payload: error,
});

const doFetchQueryData = () => ({
  type: FETCH_QUERY_DATA,
});

const doFetchQueryDataSuccess = payload => ({
  type: FETCH_QUERY_DATA_SUCCESS,
  payload,
});

const doFetchQueryDataError = error => ({
  type: FETCH_QUERY_DATA_ERROR,
  payload: error,
});

const doSetSearchParams = payload => ({
  type: SET_SEARCH_PARAMS,
  payload,
});

export {
  doFetchStarbookData,
  doFetchStarbookDataSuccess,
  doFetchStarbookDataError,
  doFetchQueryData,
  doFetchQueryDataSuccess,
  doFetchQueryDataError,
  doSetSearchParams,
};
