import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
    GET_BANK_HOLIDAYS,
    GET_BANK_HOLIDAYS_ERROR,
    GET_BANK_HOLIDAYS_SUCCESS,
    POST_BANK_HOLIDAYS,
    POST_BANK_HOLIDAYS_ERROR,
    POST_BANK_HOLIDAYS_SUCCESS,
    SHOW_MESSAGE
} from './../../store/constants';

import { getBankHolidays, updateBankHolidays } from './../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* getBankHolidaysSaga(action) {
    try {
        const token = yield select(getLoginState);
        const bankHolidays = yield call(getBankHolidays, {...action.payload, token});
        
        yield all([
            put({
                type: GET_BANK_HOLIDAYS_SUCCESS,
                payload: bankHolidays.data
            })       
        ]);
    }
    catch(error) {
        console.log(error);
        yield all([
            put({
                type: GET_BANK_HOLIDAYS_ERROR,
                payload: error
            }),
            
        ])
    }
}

export function* updateBankHolidaysSaga(action) {
    try {
        const token = yield select(getLoginState);

        const updateResult = yield call(updateBankHolidays, {data: action.payload, token});

        yield all([
            put({
                type: GET_BANK_HOLIDAYS
            }),
            put({
                type: POST_BANK_HOLIDAYS_SUCCESS,
                payload: updateResult.data
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'success',
                    content: 'Bank holidays updated.'
                }
            })
        ])

    }
    catch(error) {
        console.log(error);
        yield put({
            type: POST_BANK_HOLIDAYS_ERROR,
            payload: error
        });
    }
}

export function* watchGetBankHolidaysSaga() {
    yield takeLatest(GET_BANK_HOLIDAYS, getBankHolidaysSaga); 
}

export function* watchRemoveBankHoliday() {
    yield takeLatest(POST_BANK_HOLIDAYS, updateBankHolidaysSaga);
}