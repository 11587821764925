import {
  UPDATE_EXPENSE_SHEET_META,
  UPDATE_EXPENSE_SHEET_META_SUCCESS,
  UPDATE_EXPENSE_SHEET_META_ERROR,
} from '../../store/constants';

const initState = {
  loading: false,
  error: false,
  success: false,
};

const expense = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_EXPENSE_SHEET_META:
      return { ...state, loading: true, error: false, success: false };
    case UPDATE_EXPENSE_SHEET_META_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_EXPENSE_SHEET_META_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default expense;
