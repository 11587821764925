import {
  IS_AUTHENTICATED,
  AUTHENTICATED,
  NOT_AUTHENTICATED,
  IS_AUTHENTICATED_ERROR,
  DECREASE_PESOS,
} from './../store/constants';

const doIsAuthenticated = () => ({
  type: IS_AUTHENTICATED,
});

const doNotAuthenticated = () => ({
  type: NOT_AUTHENTICATED,
});

const doAuthenticated = (payload) => ({
  type: AUTHENTICATED,
  payload,
});

const doIsAuthenticatedError = (error) => ({
  type: IS_AUTHENTICATED_ERROR,
  payload: error,
});

const doDecreasePeso = (payload) => ({
  type: DECREASE_PESOS,
  payload,
});

export {
  doIsAuthenticated,
  doAuthenticated,
  doIsAuthenticatedError,
  doNotAuthenticated,
  doDecreasePeso,
};
