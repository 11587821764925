import { SHOW_MESSAGE, CLOSE_MESSAGE } from '../store/constants';

const initalState = {
  show: false,
  content: '',
  type: '',
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return { ...state, show: true, ...action.payload };
    case CLOSE_MESSAGE:
      return { ...state, show: false };
    default:
      return state;
  }
};

export default reducer;