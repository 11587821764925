import * as R from 'ramda';
import createReducer from './../reducer-helpers/createReducer';
import { success, error } from './../../common/helpers/constanHelpers';
import { setSimpleLoading, setError } from './../reducer-helpers/reducerHelpers';

import {
  FETCH_INVOICES,
  DELETE_INVOICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  DELETE_ATTACHMENT,
} from '../../store/constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  items: [],
};

const startLoading = _ =>
  R.evolve({
    loading: R.always(true),
    items: R.always([]),
  });

const setSuccessState = action =>
  R.evolve({
    loading: R.always(false),
    items: () => [...action.payload],
  });

const expenseSheetInvoicesReducer = createReducer(INITIAL_STATE, {
  [FETCH_INVOICES]: startLoading,
  [DELETE_INVOICE]: setSimpleLoading,
  [CREATE_INVOICE]: setSimpleLoading,
  [UPDATE_INVOICE]: setSimpleLoading,
  [DELETE_ATTACHMENT]: setSimpleLoading,
  [success(FETCH_INVOICES)]: setSuccessState,
  [success(DELETE_INVOICE)]: setSuccessState,
  [success(CREATE_INVOICE)]: setSuccessState,
  [success(UPDATE_INVOICE)]: setSuccessState,
  [success(DELETE_ATTACHMENT)]: setSuccessState,
  [error(FETCH_INVOICES)]: setError,
  [error(DELETE_INVOICE)]: setError,
  [error(CREATE_INVOICE)]: setError,
  [error(UPDATE_INVOICE)]: setError,
  [error(DELETE_ATTACHMENT)]: setError,
});

export default expenseSheetInvoicesReducer;
