import axios from 'axios';

import { starbookApi } from './api-config';

const requestConfig = {
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

const getExpenseSheetAdmin = params =>
  axios.post(`${starbookApi}/expense-sheets/query`, params, requestConfig);

const getActiveExpenseSheets = params =>
  axios.get(`${starbookApi}/expense-sheets/active`, requestConfig);

const getExpensesForSheet = params =>
  axios.get(`${starbookApi}/expense-sheets/${params.id}/expenses`, requestConfig);

const postExpense = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/expenses/create`,
    params.data,
    requestConfig,
  );

const updateExpense = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/expenses/${params.rowId}/update`,
    params.data,
    requestConfig,
  );

const deleteExpense = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/expenses/${params.rowId}/delete`,
    null,
    requestConfig,
  );

const uploadExpenseAttachment = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/expenses/${params.rowId}/attachment/upload?fileName=${params.fileName}`,
    params.data,
    {
      headers: {
        'Content-Type': `application/octet-stream`,
      },
      withCredentials: true,
      credentials: 'same-origin',
      onUploadProgress: ProgressEvent => params.progressFunc(ProgressEvent),
    },
  );

const clearExpenseRowAttachments = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/expenses/${params.rowId}/attachment/clear`,
    null,
    requestConfig,
  );

const updateExpenseSheetMetaData = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/update-metadata`,
    params.data,
    requestConfig,
  );

const getExpenseSheetInvoices = params =>
  axios.get(`${starbookApi}/expense-sheets/${params.sheetId}/invoices`, requestConfig);

const createExpenseSheetInvoice = params =>
  axios.post(
    `${starbookApi}/expense-sheets/${params.id}/invoices/create`,
    params.data,
    requestConfig,
  );

const updateExpenseSheetInvoice = params =>
  axios.post(
    `${starbookApi}/expense-sheets/invoices/${params.id}/update`,
    params.data,
    requestConfig,
  );

const deleteExpenseSheetInvoice = params =>
  axios.post(`${starbookApi}/expense-sheets/invoices/${params.id}/delete`, null, requestConfig);

const uploadExpenseSheetInvoiceAttachment = params =>
  axios.post(
    `${starbookApi}/expense-sheets/invoices/${params.id}/attachments/create?originalFileName=${params.fileName}`,
    params.data,
    {
      headers: {
        'Content-Type': `application/octet-stream`,
      },
      withCredentials: true,
      credentials: 'same-origin',
      onUploadProgress: ProgressEvent => params.progressFunc(ProgressEvent),
    },
  );

const deleteExpenseSheetInvoiceAttachment = params =>
  axios.post(
    `${starbookApi}/expense-sheets/invoices/attachments/${params.attachmentId}/delete`,
    null,
    requestConfig,
  );

const postExpenseSheet = params =>
  axios.post(`${starbookApi}/expense-sheets/create`, params.data, requestConfig);

const updateExpenseSheet = params =>
  axios.post(`${starbookApi}/expense-sheets/${params.id}/update`, params.data, requestConfig);

export {
  getExpenseSheetAdmin,
  getActiveExpenseSheets,
  getExpensesForSheet,
  postExpense,
  updateExpense,
  deleteExpense,
  uploadExpenseAttachment,
  clearExpenseRowAttachments,
  updateExpenseSheetMetaData,
  getExpenseSheetInvoices,
  createExpenseSheetInvoice,
  updateExpenseSheetInvoice,
  deleteExpenseSheetInvoice,
  uploadExpenseSheetInvoiceAttachment,
  deleteExpenseSheetInvoiceAttachment,
  postExpenseSheet,
  updateExpenseSheet,
};
