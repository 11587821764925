import { 
    FETCH_QUERY_DATA,
    FETCH_QUERY_DATA_SUCCESS,
    FETCH_QUERY_DATA_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    success: false,
    managers: [],
    departments: [],
    positions: [],
    dums: []
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case FETCH_QUERY_DATA:
            return {...state, loading: true, error: false, success: false };
        case FETCH_QUERY_DATA_SUCCESS:
            return {...state, loading: false, success: true, ...action.payload };
        case FETCH_QUERY_DATA_ERROR: 
            return {...state, loading: false, error: action.payload };
        default: 
            return state;
    }
}

export default reducer;