import {
  FETCH_PESO_HISTORY,
  FETCH_PESO_HISTORY_SUCCESS,
  FETCH_PESO_HISTORY_ERROR,
  FETCH_PESO_USER_HISTORY,
  FETCH_PESO_USER_HISTORY_SUCCESS,
  FETCH_PESO_USER_HISTORY_ERROR,
  POST_PESO_TRANSFER,
  POST_PESO_TRANSFER_SUCCESS,
  POST_PESO_TRANSFER_ERROR,
  POST_PESO_GIVE,
  POST_PESO_GIVE_SUCCESS,
  POST_PESO_GIVE_ERROR,
  POST_PESO_TAKE,
  POST_PESO_TAKE_SUCCESS,
  POST_PESO_TAKE_ERROR,
} from '../store/constants';

const initalState = {
  error: false,
  loading: false,
  success: false,
  data: {},
  transfer: {},
};

const pesoReducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_PESO_HISTORY:
    case FETCH_PESO_USER_HISTORY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case FETCH_PESO_HISTORY_SUCCESS:
    case FETCH_PESO_USER_HISTORY_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case FETCH_PESO_HISTORY_ERROR:
    case FETCH_PESO_USER_HISTORY_ERROR:
      return { ...state, loading: false, error: true, data: {} };

    case POST_PESO_TRANSFER:
      return { ...state, loading: true, error: false };
    case POST_PESO_TRANSFER_SUCCESS:
      return { ...state, loading: false, error: false, transfer: action.payload };
    case POST_PESO_TRANSFER_ERROR:
      return { ...state, loading: false, error: action.payload, transfer: {} };

    case POST_PESO_GIVE:
      return { ...state, loading: true, error: false };
    case POST_PESO_GIVE_SUCCESS:
      return { ...state, loading: false, error: false, transfer: action.payload };
    case POST_PESO_GIVE_ERROR:
      return { ...state, loading: false, error: action.payload, transfer: {} };

    case POST_PESO_TAKE:
      return { ...state, loading: true, error: false };
    case POST_PESO_TAKE_SUCCESS:
      return { ...state, loading: false, error: false, transfer: action.payload };
    case POST_PESO_TAKE_ERROR:
      return { ...state, loading: false, error: action.payload, transfer: {} };

    default:
      return state;
  }
};

export default pesoReducer;
