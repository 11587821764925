import * as R from 'ramda';
import { success, error } from './../..//common/helpers/constanHelpers';
import createReducer from '../reducer-helpers/createReducer';
import { setSimpleLoading, setError } from './../reducer-helpers/reducerHelpers';

import {
  START_POLLING,
  STOP_POLLING,
  STATUS_UPDATE,
  HANDLE_TIMESHEET,
  JOB_CLEANUP,
} from '../../store/constants';

const INITIAL_STATE = {
  statusList: [],
  polling: false,
  loading: false,
  error: false,
};

const setStartPolling = (_) => R.assocPath(['polling'], true);

const setStopPolling = (_) => R.assocPath(['polling'], false);

const addItem = (action) => (state) => {
  const item = {
    ...action.payload,
    status: 'in progress',
  };
  return R.evolve(
    {
      statusList: () => R.append(item, R.path(['statusList'], state)),
      loading: R.always(false),
    },
    state,
  );
};

const updateList = (action) => (state) => {
  return R.evolve(
    {
      statusList: () => [...action.payload],
    },
    state,
  );
};

const filterFinished = (action) => (state) => {
  return R.evolve(
    {
      statusList: () => {
        const filetered = R.filter(
          R.complement(R.propEq('status', 'finished')),
          R.path(['statusList'], state),
        );
        return [...filetered];
      },
      loading: R.always(false),
    },
    state,
  );
};

const timsheetHandlerReducer = createReducer(INITIAL_STATE, {
  [START_POLLING]: setStartPolling,
  [STOP_POLLING]: setStopPolling,
  [HANDLE_TIMESHEET]: setSimpleLoading,
  [success(HANDLE_TIMESHEET)]: addItem,
  [error(HANDLE_TIMESHEET)]: setError,
  [success(STATUS_UPDATE)]: updateList,
  //[JOB_CLEANUP]: () => {},
  [success(JOB_CLEANUP)]: filterFinished,
  [error(JOB_CLEANUP)]: setError,
});

export default timsheetHandlerReducer;
