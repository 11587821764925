import { delay } from 'redux-saga';
import { takeEvery, call, put, take, race, all, select, takeLatest } from 'redux-saga/effects';
import * as R from 'ramda';

import { error } from './../..//common/helpers/constanHelpers';
import {
  HANDLE_TIMESHEET,
  START_POLLING,
  STOP_POLLING,
  JOB_CLEANUP,
} from './../../store/constants';

import { postWorker } from './../helpers/post-worker';

import {
  doStartPolling,
  doStopPolling,
  doHandleTimesheetSuccess,
  doHandleTimesheetError,
  doStatusUpdateSuccess,
  doStatusUpdateError,
} from './../../actions/timesheetHandler';

import { doFetchTimeSheets } from './../../actions/timesheet';

import { doShowMessage } from './../../actions/message';

import { getRawStatusList } from './../../selectors/timesheet/timesheet-handler.selectors';
import { getFilter } from './../../selectors/timesheet.selectors';

import { handleTimesheet, checkTimesheetStatuses } from '../apiCalls';

const getLoginState = (state) => state.login.jwtToken;

const statusMapper = (statuses) => (item) => {
  const upDatedStatus = R.find(R.propEq('requestId', R.path(['requestId'], item)))(statuses);
  if (upDatedStatus) {
    return {
      ...item,
      status: upDatedStatus.status,
      message: upDatedStatus.message,
    };
  } else {
    return item;
  }
};

const stopPollingReducer = (acc, value) => {
  if (!acc) {
    return acc;
  }
  return value.status === 'in progress' ? false : acc;
};

const filterForRefresh = R.filter(R.propEq('status', 'finished'));

const shouldRefreshTimesheets = (oldStatueses, newStatuses) => {
  console.log(oldStatueses);
  console.log(newStatuses);
  const oldFinished = filterForRefresh(oldStatueses);
  const newFinished = filterForRefresh(newStatuses);
  console.log('shouldRefreshTimesheets', oldFinished, newFinished);
  return R.length(newFinished) > R.length(oldFinished);
};

function* timesheetHandler(action) {
  try {
    const token = yield select(getLoginState);
    const response = yield call(handleTimesheet, {
      data: action.payload,
      token,
    });

    const handleObject = {
      maconomyId: R.path(['payload', 'maconomyId'], action),
      job: R.path(['payload', 'job'], action),
      requestId: R.path(['data', 'requestId'], response),
    };
    yield all([put(doHandleTimesheetSuccess(handleObject)), put(doStartPolling())]);
  } catch (error) {
    yield all([
      put(doHandleTimesheetError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* pollTask() {
  const token = yield select(getLoginState);
  while (true) {
    try {
      const {
        data: { statuses },
      } = yield call(checkTimesheetStatuses, {
        token,
      });
      const prevStatuses = yield select(getRawStatusList);

      const newStatuses = R.map(statusMapper(statuses), prevStatuses);

      const needRefreshTimesheets = shouldRefreshTimesheets(prevStatuses, statuses);

      if (needRefreshTimesheets) {
        const timesheetFilter = yield select(getFilter);
        yield put(doFetchTimeSheets(timesheetFilter));
      }

      yield put(doStatusUpdateSuccess(newStatuses));

      const shouldStopPolling = R.reduce(stopPollingReducer, true, newStatuses);

      if (shouldStopPolling) {
        yield put(doStopPolling());
      }

      yield call(delay, 5000);
    } catch (error) {
      yield all([
        put(doStatusUpdateError(error)),
        put(doStopPolling()),
        put(
          doShowMessage({
            type: 'error',
            content: error.message,
          }),
        ),
      ]);
    }
  }
}

export function* watchPollTask() {
  while (true) {
    yield take(START_POLLING);
    yield race([call(pollTask), take([STOP_POLLING, error(STOP_POLLING)])]);
  }
}

export function* watchTimesheetHandle() {
  yield takeEvery(HANDLE_TIMESHEET, timesheetHandler);
}

export function* watchTimesheetCleanup() {
  yield takeLatest(JOB_CLEANUP, postWorker);
}
