import { call, put, takeLatest, all } from 'redux-saga/effects';

import { 
    UPDATE_CV_CERTIFICATES
} from '../../store/constants';

import {
    doUpdateCvCertificationsSuccess,
    doUpdateCvCertificationsError
} from '../../actions/cv';

import {
    doGetUserDetailsSuccess
} from '../../actions/user';

import {
    doShowMessage
} from '../../actions/message'

import { 
    updateCvCertificates
 } from '../starbookApiCalls'; 

/* import * as R from 'ramda';
const { ifElse } = R; */



export function* cvCertificatesUpdateWorker(action) {
    try {
        
        const result = yield call(updateCvCertificates, action.payload);
       
        yield all([
            put(doGetUserDetailsSuccess(result.data)),
            put(doUpdateCvCertificationsSuccess({})),
            put(doShowMessage({
                type: 'success',
                content: 'Successfully updated certificates'
            })
        )
        ])

    }
    catch(error) {
        console.log(error);
        yield all([
            put(doUpdateCvCertificationsError(error)),
            put(doShowMessage({
                    type: 'error',
                    content: error.message
                })
            )
        ])
    }
}



export function* watchCvCertificatesUpdate() {
    yield takeLatest(UPDATE_CV_CERTIFICATES, cvCertificatesUpdateWorker); 
}