import { createSelector } from 'reselect';
import * as R from 'ramda';

import { permissionLabels } from './../common/enums';

const permissionTreeMap = R.applySpec({
  label: R.pipe(R.path(['permission']), (key) => R.defaultTo(key, R.path([key], permissionLabels))),
  value: R.path(['permission']),
});

const getUser = ({ login }) => login;

export const getUserState = createSelector([getUser], R.path(['user']));

export const getPermissions = createSelector([getUser], R.path(['permissions']));

export const getMappedPermissionTree = createSelector(
  [getUser],
  R.pipe(R.path(['permissionTree']), R.map(permissionTreeMap)),
);

export const getToken = createSelector([getUser], (login) => login.jwtToken);
