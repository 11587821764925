import { createSelector } from 'reselect';

const starbookState = (state) => state.starbook;

export const getSearchParams = createSelector([starbookState], (starbook) => starbook.search);

export const getEmployees = createSelector([starbookState], (starbook) =>
  starbook.employees.map((e) => ({ value: e.id, label: e.name })),
);

export const getEmployeeObjects = createSelector([starbookState], (starbook) => starbook.employees);

export const getLoadingState = createSelector([starbookState], (starbook) => starbook.loading);
