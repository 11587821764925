import { createSelector } from 'reselect';

const getShowState = state => state.message.show;

export const getShow = createSelector(
    [ getShowState ],
    show => show
);

const getContentState = state => state.message.content;

export const getContent = createSelector(
    [ getContentState ],
    content => content
);

const getTypeState = state => state.message.type;

export const getType = createSelector(
    [ getTypeState ],
    type => {
        if (type === 'success') {
            return 'is-success';
        }
        if (type === 'error') {
            return 'is-danger';
        }
    }
)