import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doFetchStarbookData, doFetchQueryData, doSetSearchParams } from './../../actions/starbook';

import { doFetchSkills } from './../../actions/skills';

import { getUserState } from './../../selectors/login.selectors';
import { getParsedSkills } from './../../selectors/cv/skills.selectors';
import { getSearchParams } from './../../selectors/starbook.seletors';

import styles from './StarBook.module.scss';

import logo from './../../common/logo-black.svg';

import CardList from './card-list/CardList';
import SearchBox from './search-box/SearchBox';
import LoaderContainer from './../../common/loader/LoaderContainer';
import ToTheTopButton from './../../common/to-the-top-button/ToTheTopButton';
import AdvancedSearch from './advanced-search/AdvancedSearch';
import TopLoader from './../../common/top-loader/TopLoader';

import { DESC, ASC } from './../../common/constant-values';

const sortCrits = [
  { value: `WithUs_${ASC}`, label: `With us ${String.fromCharCode(8593)}` },
  { value: `WithUs_${DESC}`, label: `With us ${String.fromCharCode(8595)}` },
  { value: `name_${ASC}`, label: `Name ${String.fromCharCode(8593)}` },
  { value: `name_${DESC}`, label: `Name ${String.fromCharCode(8595)}` },
  { value: `position_${ASC}`, label: `Position ${String.fromCharCode(8593)}` },
  { value: `position_${DESC}`, label: `Position ${String.fromCharCode(8595)}` },
];

const isSearchEmpty = (params) =>
  Object.values(params).reduce(
    (acc, curr) => (!acc && Array.isArray(curr) ? curr.length > 0 : acc),
    false,
  );

const Starbook = ({
  onFetchStarbookData,
  onFetchQueryData,
  onFetchSkills,
  onSetSearchParams,
  loading,
  employees,
  loggedInUser,
  searchParams,
  departments,
  positions,
  dums,
  managers,
  skills,
}) => {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(() => false);
  /*  const initFetch = when(noItems, () => {
    onFetchStarbookData({});
    onFetchQueryData();
    onFetchSkills();
  }); */

  useEffect(() => {
    // initFetch(employees);
    //onFetchStarbookData(searchParams);
    onFetchQueryData();
    onFetchSkills();

    return () => {
      console.log('starbook cleanup');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchEmpty = isSearchEmpty(searchParams);
    if (!showAdvancedSearch) {
      setShowAdvancedSearch(() => searchEmpty);
    }
  }, [searchParams, showAdvancedSearch]);

  /* const handleSearch = params => {
    onSetSearchParams(params);
  }; */

  const handleSearch = React.useCallback(
    (params) => {
      onSetSearchParams(params);
    },
    [onSetSearchParams],
  );

  /*  const handleShow = () => {
    setShowAdvancedSearch(showAdvancedSearch => !showAdvancedSearch);
  }; */

  const handleShow = React.useCallback(() => {
    setShowAdvancedSearch((showAdvancedSearch) => !showAdvancedSearch);
  }, [setShowAdvancedSearch]);

  const employeeList =
    loading && !employees.length > 0 ? (
      <LoaderContainer />
    ) : (
      <CardList users={employees} loggedInUser={loggedInUser} />
    );

  return (
    <section className="section">
      {loading && <TopLoader />}
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className={styles.headingContainer}>
              <img src={logo} alt="Logo" className={styles.logoIcon} />
              <h1 className={'title has-text-left '}>Starbook</h1>
            </div>
          </div>
          <div className="column has-text-left">
            <SearchBox
              onSearch={handleSearch}
              loading={loading}
              params={searchParams}
              show={showAdvancedSearch}
              onShow={handleShow}
            />
          </div>
        </div>
        {showAdvancedSearch && (
          <AdvancedSearch
            onSearch={handleSearch}
            params={searchParams}
            departments={departments}
            positions={positions}
            dums={dums}
            managers={managers}
            skills={skills}
            loading={loading}
            sortCrits={sortCrits}
          />
        )}
        {employees.length > 0 ? (
          <>{employeeList}</>
        ) : (
          <div className="columns">
            <div className="column">
              <div className={styles.noResults}>
                There's no such tetya or tetyina here ¯\_(ツ)_/¯
              </div>
            </div>
          </div>
        )}
        <ToTheTopButton />
      </div>
    </section>
  );
};

Starbook.propTypes = {
  onFetchStarbookData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    employees: state.starbook.employees,
    loading: state.starbook.loading,
    searchParams: getSearchParams(state),
    departments: state.queryData.departments,
    positions: state.queryData.positions,
    dums: state.queryData.dums,
    managers: state.queryData.managers,
    loggedInUser: getUserState(state),
    skills: getParsedSkills(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchStarbookData: (params) => dispatch(doFetchStarbookData(params)),
    onFetchQueryData: () => dispatch(doFetchQueryData()),
    onFetchSkills: () => dispatch(doFetchSkills()),
    onSetSearchParams: (params) => dispatch(doSetSearchParams(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Starbook);
