import * as R from 'ramda';
import { success, error, reset } from './../../common/helpers/constanHelpers';
import createReducer from '../reducer-helpers/createReducer';

import { CREATE_EXPENSE_SHEET, UPDATE_EXPENSE_SHEET } from '../../store/constants';

const INITIAL_STATE = {
  success: false,
  error: false,
  loading: false,
};

const createExpenseSheet = _ =>
  R.evolve({
    loading: R.not,
    success: R.always(false),
  });

const createExpenseSheetSuccess = _ =>
  R.evolve({
    loading: R.not,
    success: R.not,
  });

const createExpenseSheetError = _ =>
  R.evolve({
    loading: R.not,
    error: R.not,
  });

const createExpenseSheetReducer = createReducer(INITIAL_STATE, {
  [CREATE_EXPENSE_SHEET]: createExpenseSheet,
  [success(CREATE_EXPENSE_SHEET)]: createExpenseSheetSuccess,
  [error(CREATE_EXPENSE_SHEET)]: createExpenseSheetError,
  [reset(CREATE_EXPENSE_SHEET)]: _ => _ => R.clone(INITIAL_STATE),
  [UPDATE_EXPENSE_SHEET]: createExpenseSheet,
  [success(UPDATE_EXPENSE_SHEET)]: createExpenseSheetSuccess,
  [error(UPDATE_EXPENSE_SHEET)]: createExpenseSheetError,
});

export default createExpenseSheetReducer;
