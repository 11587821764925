import axios from 'axios';

import { apiUrl } from './api-config';
class AuthApi {
    static verifyToken(params) {
        return axios.post(`${apiUrl}/auth/microsoft`, JSON.stringify({}, null, 2), {
            withCredentials: true,
            credentials: 'same-origin',
            headers: {
                "Access_token": params.accessToken ? params.accessToken : params.token
            }
        });
    }
}

export default AuthApi;