import { createSelector } from 'reselect';

const isLoading = (state) => state.timesheets.loading;

export const isLoadingState = createSelector([isLoading], (loading) => loading);

const isError = (state) => state.timesheets.error;

export const isErrorState = createSelector([isError], (error) => error);

const weekHeadersState = (state) => state.timesheets.weekHeaders;

export const getWeekHeaders = createSelector([weekHeadersState], (weekHeaders) => weekHeaders);

const timesheetState = (state) => state.timesheets.timeSheets;

export const getTimesheets = createSelector([timesheetState], (timesheets) => timesheets);

const filterState = (state) => state.timesheets.filter;

export const getFilter = createSelector([filterState], (filter) => filter);
