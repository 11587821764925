import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { 
    SET_CRON,
    SET_CRON_SUCCESS,
    GET_CRON,
    SET_CRON_ERROR,
    SHOW_MESSAGE
} from '../../store/constants';

import { postCronSettings as postCron } from '../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* postCronSetting(action) {
    try {
        const token = yield select(getLoginState);
        const cronResponse = yield call(postCron, {data: action.payload, token});

        yield all([
           put({
               type: SET_CRON_SUCCESS,
               payload: cronResponse.data
           }),
           put({
               type: GET_CRON
           })
        ]);
    }
    catch(error) {
        console.log(error);
        yield all([
            put({
                type: SET_CRON_ERROR,
                payload: error
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'error',
                    content: 'Error while approveing'
                }
            })
        ])
    }
}

export function* watchSetCron() {
    yield takeLatest(SET_CRON, postCronSetting); 
}