import * as R from 'ramda';

export const setSimpleLoading = _ => R.assocPath(['loading'], true);

export const setSimpleError = _ => R.assocPath(['error'], true);

export const setLoading = _ =>
  R.evolve({
    loading: R.always(true),
    error: R.always(false),
    success: R.always(false),
  });

export const setError = _ =>
  R.evolve({
    loading: R.always(false),
    error: R.always(true),
  });
