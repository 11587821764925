import {
  FETCH_INVOICES,
  CREATE_INVOICE,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  DELETE_ATTACHMENT,
  UPLOAD_INVOICE_ATTACHMENT,
} from './../store/constants';

import { success, error } from './../common/helpers/constanHelpers';
import { createSimpleAction } from './actionCreator';

const doFetchInovices = createSimpleAction(FETCH_INVOICES);

const doFetchInovicesSuccess = createSimpleAction(success(FETCH_INVOICES));

const doFetchInovicesError = createSimpleAction(error(FETCH_INVOICES));

const doCreateInovices = createSimpleAction(CREATE_INVOICE);

const doCreateInovicesSuccess = createSimpleAction(success(CREATE_INVOICE));

const doCreateInovicesError = createSimpleAction(error(CREATE_INVOICE));

const doDeleteInvoice = createSimpleAction(DELETE_INVOICE);

const doDeleteInvoiceSuccess = createSimpleAction(success(DELETE_INVOICE));

const doDeleteInvoiceError = createSimpleAction(error(DELETE_INVOICE));

const doDeleteInvoiceAttachment = createSimpleAction(DELETE_ATTACHMENT);

const doDeleteInvoiceAttachmentSuccess = createSimpleAction(success(DELETE_ATTACHMENT));

const doDeleteInvoiceAttachmentError = createSimpleAction(error(DELETE_ATTACHMENT));

const doUpdateInvoice = createSimpleAction(UPDATE_INVOICE);

const doUpdateInvoiceSuccess = createSimpleAction(success(UPDATE_INVOICE));

const doUpdateInvoiceError = createSimpleAction(error(UPDATE_INVOICE));

const doUploadAttachmentSuccess = createSimpleAction(success(UPLOAD_INVOICE_ATTACHMENT));
const doUploadAttachmentError = createSimpleAction(error(UPLOAD_INVOICE_ATTACHMENT));

export {
  doFetchInovices,
  doFetchInovicesSuccess,
  doFetchInovicesError,
  doCreateInovices,
  doCreateInovicesSuccess,
  doCreateInovicesError,
  doDeleteInvoice,
  doDeleteInvoiceSuccess,
  doDeleteInvoiceError,
  doUpdateInvoice,
  doUpdateInvoiceSuccess,
  doUpdateInvoiceError,
  doDeleteInvoiceAttachment,
  doDeleteInvoiceAttachmentSuccess,
  doDeleteInvoiceAttachmentError,
  doUploadAttachmentSuccess,
  doUploadAttachmentError,
};
