import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Select from 'react-select-reborn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContainer from './../../common/page-container/PageContainer';
import LoaderContainer from './../../common/loader/LoaderContainer';
import { STARSHOP_CREATE_MERCH_MODAL } from '../../common/modalTypes';

import { doOpenModal } from '../../actions/modal';

import {
  doFetchStarshopAdminOrderHistory,
  doCreateStarshopAdminMerch,
} from './../../actions/starshop';

import {
  // getMerchItems,
  getOrderHistory,
  getImageData,
  getLoadingState,
  getErrorState,
} from './../../selectors/starshop.selectors';

import { getModalData } from './../../selectors/modal.selectors';

import StarshopOrderHistoryRow from './starshop-order-history-row/StarshopOrderHistoryRow';
import { getEmployees } from '../../selectors/starbook.seletors';
import { orderStatuses } from '../starshop/starshop.model';

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#dbdbdb',
    boxShadow: 'inset 0 1px 2px rgba(10, 10, 10, 0.1)',
  }),
  container: (provided) => ({
    ...provided,
    marginBottom: '8px',
    marginLeft: '12px',
    textAlign: 'left',
    width: '250px',
    zIndex: 2,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(54,54,54, 0.3)',
  }),
};

const formStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const StarshopAdmin = React.memo(
  ({
    onFetchAdminOrderHistory,
    onCreateAdminMerch,
    onOpenCreateMerchModal,
    list,
    imageData,
    loading,
    error,
    isModalOpen,
  }) => {
    const employeesList = useSelector(getEmployees);
    const selectedUserRef = useRef();
    const selectedOrderStatusRef = useRef();
    const [selectedUser, setSelectecUser] = useState();
    const [selectedOrderStatus, setSelectedOrderStatus] = useState();
    const noItem = !list.length;
    const statusList = Object.entries(orderStatuses).map((status) => ({
      value: status[0],
      label: status[0].charAt(0).toUpperCase() + status[0].slice(1),
    }));

    useEffect(() => {
      const payload = { orderBy: 'purchased', orderByAsc: false };

      if (selectedUser) {
        payload.userIds = [selectedUser];
      }

      if (selectedOrderStatus) {
        payload.statuses = [selectedOrderStatus];
      }

      onFetchAdminOrderHistory({ ...payload });
    }, [onFetchAdminOrderHistory, selectedUser, selectedOrderStatus]);

    return (
      <PageContainer
        title="Starshop Admin"
        icon="coins"
        buttonText="Create Merch"
        buttonFunction={() =>
          onOpenCreateMerchModal({
            type: STARSHOP_CREATE_MERCH_MODAL,
            successFunction: (merch) => {
              console.log('newImageData', merch.imageFileNames);
              if (merch.imageFileNames) {
                onCreateAdminMerch({ ...merch });
              } else {
                alert('error');
              }
            },
            data: imageData,
          })
        }
      >
        <div className="column">
          <Formik>
            <Form style={formStyle}>
              <Select
                isClearable
                name="user"
                placeholder="Select a user"
                styles={customSelectStyles}
                ref={selectedUserRef}
                options={employeesList}
                isDisabled={loading}
                onChange={(selected) => setSelectecUser(selected ? selected.value : undefined)}
              />
              <Select
                isClearable
                name="status"
                placeholder="Select a status"
                styles={customSelectStyles}
                ref={selectedOrderStatusRef}
                options={statusList}
                isDisabled={loading}
                onChange={(selected) =>
                  setSelectedOrderStatus(selected ? selected.value : undefined)
                }
              />
            </Form>
          </Formik>

          {loading && !isModalOpen && <LoaderContainer />}
          <div className={'lol'}>
            {noItem ? <p>No item to display</p> : null}
            {
              // (!loading || isModalOpen) &&
              list.map((item) => (
                // <StarshopCard key={item.title} pesos={user.pesoCount} merchItem={item} />
                <StarshopOrderHistoryRow orderHistory={item} key={item.id} />
              ))
            }
          </div>
        </div>
      </PageContainer>
    );
  },
);

StarshopAdmin.propTypes = {
  onFetchAdminOrderHistory: PropTypes.func,
  onOpenCreateMerchModal: PropTypes.func,
  onCreateAdminMerch: PropTypes.func,
  // list: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    list: getOrderHistory(state),
    imageData: getImageData(state),
    loading: getLoadingState(state),
    error: getErrorState(state),
    isModalOpen: getModalData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchAdminOrderHistory: (params) => dispatch(doFetchStarshopAdminOrderHistory(params)),
    onCreateAdminMerch: (params) => dispatch(doCreateStarshopAdminMerch(params)),
    onOpenCreateMerchModal: (params) => dispatch(doOpenModal(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarshopAdmin);
