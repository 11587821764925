import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import {
  doFetchExpenseRowsSuccess,
  doFetchExpenseRowsError,
  // doDeleteExpenseRowSuccess,
  doDeleteExpenseRowError,
  doDeleteExpenseRowAttachmentSuccess,
  doDeleteExpenseRowAttachmentError,
  doFetchExpenseRows,
} from '../../actions/expense';
import { doShowMessage } from './../../actions/message';

import {
  FETCH_EXPENSE_ROWS,
  DELETE_EXPENSE_ROW,
  DELETE_EXPENSE_ROW_ATTACHMENT,
} from './../../store/constants';

import {
  getExpensesForSheet,
  deleteExpense,
  clearExpenseRowAttachments,
} from '../../api/expense.api';

const getExpenseRows = state => state.expenseRow.rows;
const getExpenseSheetId = state => state.expenseRow.id;

export function* fetchExpenseRows(action) {
  try {
    const result = yield call(getExpensesForSheet, action.payload);

    yield all([put(doFetchExpenseRowsSuccess(result.data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchExpenseRowsError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* deleteExpenseRow(action) {
  try {
    yield call(deleteExpense, action.payload);

    const id = yield select(getExpenseSheetId);

    yield all([
      put(doFetchExpenseRows({ id })),
      put(
        doShowMessage({
          type: 'success',
          content: 'Successfully deleted',
        }),
      ) /* , put(doDeleteExpenseRowSuccess()) */,
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doDeleteExpenseRowError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* deleteExpenseRowAttachment(action) {
  try {
    yield call(clearExpenseRowAttachments, action.payload);

    const rows = yield select(getExpenseRows);
    const newRows = rows.map(row => {
      if (row.rowId === action.payload.rowId) {
        return {
          ...row,
          attachment: null,
        };
      } else {
        return row;
      }
    });

    yield all([put(doDeleteExpenseRowAttachmentSuccess(newRows))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doDeleteExpenseRowAttachmentError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchFetchExpenseRows() {
  yield takeLatest(FETCH_EXPENSE_ROWS, fetchExpenseRows);
}

export function* watchDeleteExpenseRow() {
  yield takeLatest(DELETE_EXPENSE_ROW, deleteExpenseRow);
}

export function* watchDeleteExpenseRowAttachment() {
  yield takeLatest(DELETE_EXPENSE_ROW_ATTACHMENT, deleteExpenseRowAttachment);
}
