import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MultiSelect from './../../../common/multi-select/MultiSelect';
import SkillMultiSelect from './../../../common/skill-multi-select-input/SkillMultiSelectInput';
import SingleSelect from './../../../common/single-select/SingleSelect';

import styles from './AdvancedSearch.module.sass';

import * as R from 'ramda';

const { keys, ifElse } = R;

const shouldShowClearButton = (values) => {
  return keys(values).reduce(
    (accumulator, currentValue) => (accumulator ? accumulator : values[currentValue].length > 0),
    false,
  );
};

const initObject = {
  positions: [],
  managerIds: [],
  dumIds: [],
  departmentIds: [],
  skillsetIds: [],
  orderBy: '',
};

const AdvancedSearchForm = ({
  handleSubmit,
  positions,
  managers,
  dums,
  departments,
  skills,
  values,
  onSearch,
  resetForm,
  params,
  loading,
  dirty,
  touched,
  sortCrits,
}) => {
  const howToHandleReset = (initObject) =>
    ifElse(
      (data) => shouldShowClearButton(data),
      () => onSearch(initObject),
      () => resetForm(),
    );

  const handleReset = () => {
    howToHandleReset(initObject)(params);
  };

  useEffect(() => {
    const shouldSearch = Object.keys(touched).length > 0;
    if (shouldSearch) {
      onSearch(values);
    }
  }, [onSearch, touched, values]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="columns is-variable is-1">
        <div className="column">
          <Field
            component={SkillMultiSelect}
            name="skillsetIds"
            placeholder="Skills"
            list={skills}
          />
        </div>
        <div className="column">
          <Field
            component={MultiSelect}
            name="positions"
            placeholder="Positions"
            list={positions}
          />
        </div>
        <div className="column">
          <Field
            component={MultiSelect}
            className="is-small"
            name="managerIds"
            placeholder="Team Lead"
            list={managers}
          />
        </div>
        <div className="column">
          <Field component={MultiSelect} name="dumIds" placeholder="Delivery Lead" list={dums} />
        </div>
        <div className="column">
          <Field
            component={MultiSelect}
            name="departmentIds"
            placeholder="Unit"
            list={departments}
          />
        </div>
        <div className="column">
          <Field component={SingleSelect} name="orderBy" placeholder="Sort" list={sortCrits} />
        </div>

        <div className="column is-narrow">
          <div className="buttons">
            <button type="submit" className={`button ${styles.advancedSearchButton}`}>
              <FontAwesomeIcon icon="check" />
            </button>
            {shouldShowClearButton(values) && (
              <button
                type="button"
                className={`button ${styles.advancedSearchButton}`}
                onClick={handleReset}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const AdvancedSearch = withFormik({
  displayName: 'AdvancedSearchForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    positions: props.params?.positions ? props.params.positions : [],
    managerIds: props.params?.managerIds ? props.params.managerIds : [],
    dumIds: props.params?.dumIds ? props.params.dumIds : [],
    departmentIds: props.params?.departmentIds ? props.params.departmentIds : [],
    skillsetIds: props.params?.skillsetIds ? props.params.skillsetIds : [],
    orderBy: props.params?.orderBy ? props.params.orderBy : '',
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.onSearch(values);
    setSubmitting(false);
  },
})(AdvancedSearchForm);

AdvancedSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  searchParams: PropTypes.object,
  departments: PropTypes.array,
  positions: PropTypes.array,
  dums: PropTypes.array,
  managers: PropTypes.array,
  sortCrits: PropTypes.array,
};

export default AdvancedSearch;
