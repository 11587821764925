import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useSpring, animated } from 'react-spring';
//import PropTypes from 'prop-types';

import TimesheetStatusItem from './../timesheet-status-item/TimesheetStatusItem';

import { getStatusList } from './../../../selectors/timesheet/timesheet-handler.selectors';

import styles from './TimesheetStatus.module.sass';

const finishedItemsRedcer = (acc, value) => {
  if (value.status === 'in progress') {
    return acc === true ? false : acc;
  } else {
    return true;
  }
};

const TimesheetStatus = (props) => {
  const [show, setShow] = useState(() => false);
  const [showContent, setShowContent] = useState(() => true);
  const statusItems = useSelector(getStatusList);

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: show ? 1 : 0,
    },
  });

  useEffect(() => {
    const allFinished = R.reduce(finishedItemsRedcer, false, statusItems);
    if (!allFinished && R.not(R.isEmpty(statusItems))) {
      console.log('finished');
      setShow(() => true);
    }
    // R.not(R.isEmpty(statusItems)) && setShow(() => true);

    if (show && R.isEmpty(statusItems)) {
      setShow(() => false);
    }

    const timeout = setTimeout(() => {
      //const allFinished = R.reduce(finishedItemsRedcer, false, statusItems);
      allFinished && setShow(() => false);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show, statusItems]);

  const handleClose = () => {
    const allFinished = R.reduce(finishedItemsRedcer, false, statusItems);
    console.log('allfinished', allFinished);
    if (allFinished) {
      setShow(() => false);
    } else {
      setShowContent(() => false);
    }
  };

  const handleOpen = () => {
    setShowContent(() => true);
  };

  const items = statusItems.map((item, index) => <TimesheetStatusItem key={index} item={item} />);

  return (
    <>
      {show && (
        <animated.div style={fadeStyles} className={styles.floating}>
          <article className="message">
            <div className={`message-header ${styles.isBlue}`}>
              <p>Timesheet jobs statuses</p>
              {showContent && (
                <button className="delete" aria-label="delete" onClick={handleClose}></button>
              )}
              {!showContent && (
                <button className={styles.open} aria-label="open" onClick={handleOpen}>
                  <FontAwesomeIcon icon="chevron-up" />
                </button>
              )}
            </div>
            {showContent && <div className={`${styles.isBlueBody} message-body`}>{items}</div>}
          </article>
        </animated.div>
      )}
    </>
  );
};

//TimesheetStatus.propTypes = {};

export default TimesheetStatus;
