import { takeLatest, call, put } from 'redux-saga/effects';
import { IS_AUTHENTICATED, IS_AUTHENTICATED_ERROR } from '../store/constants';
import { checkAuthStatus } from './starbookApiCalls';

import { doNotAuthenticated, doAuthenticated } from './../actions/app';
import { doSetMassageWSSUrl } from '../actions/massage';

const isAuthenticated = (status) => (status === 401 ? true : false);

export function* authenticate() {
  try {
    const response = yield call(checkAuthStatus);

    if (response.data.loggedIn) {
      const userData = {
        permissionTree: response.data.permissionTree,
        loggedIn: response.data.loggedIn,
        ...response.data.session,
      };

      userData.user['isAdmin'] = response.data.session.permissions.includes('Admin');
      yield put(doSetMassageWSSUrl({ wssUrl: response.data.massageSocketUrl }));
      yield put(doAuthenticated(userData));
    } else {
      yield put(doNotAuthenticated());
    }
  } catch (error) {
    console.log(error.response);
    if (error.response && isAuthenticated(error.response.status)) {
      yield put(doNotAuthenticated());
    } else {
      yield put({
        type: IS_AUTHENTICATED_ERROR,
        payload: error,
      });
    }
  }
}

/* 
export function* deAuthenticate() {
    yield put({
        type: DEAUTHENTICATE_SUCCESS,
        payload: {}
    });
} */

export function* loginWatcher() {
  yield takeLatest(IS_AUTHENTICATED, authenticate);
}
