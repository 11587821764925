import axios from 'axios';

import { apiUrl } from './api-config';

const requestConfig = {
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

const withAuthHeader = ({ token }) => ({ Authorization: `Bearer ${token}` });

class AdminApi {
  static postApproval(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    const body = {
      year: params.data.year,
      month: params.data.month,
      maconomyId: params.data.maconomyId,
    };
    return axios.post(`${apiUrl}/admin/approve-timesheet`, body, config);
  }

  static fetchDefaultDays(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/default-days`, config);
  }

  static postDefaultDay(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.post(`${apiUrl}/admin/default-days`, params.data, config);
  }

  static fetchSpecialMonths(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/special-months`, config);
  }

  static postSpecialMonth(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.post(`${apiUrl}/admin/special-months`, params.data, config);
  }

  static deleteSpecialMonth(params) {
    const deleteConfig = {
      ...requestConfig,
      headers: withAuthHeader(params),
      data: params.data,
    };
    return axios.delete(`${apiUrl}/admin/special-months`, deleteConfig);
  }

  static getTeamleadData(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/teamlead-notifications`, config);
  }

  static postReject(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.post(`${apiUrl}/admin/reject-timesheet`, params.data, config);
  }

  static postRevoke(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.post(`${apiUrl}/admin/revoke-timesheet`, params.data, config);
  }

  static getCronsSettings(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/cron-status`, config);
  }

  static postCronSettings(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.post(`${apiUrl}/admin/cron-status`, params.data, config);
  }

  static getBankHolidays(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/bank-holidays`, config);
  }

  static postBankHolidays(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.post(`${apiUrl}/admin/update-bank-holidays`, params.data, config);
  }

  static getWorkHours(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/monthly-work-hours`, config);
  }

  static postWorkHoursForYear(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.post(`${apiUrl}/admin/monthly-work-hours`, params.data, config);
  }

  static deleteWorkHoursForYear(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.delete(`${apiUrl}/admin/monthly-work-hours/${params.data.year}`, config);
  }

  static handleTimesheet(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };

    return axios.post(`${apiUrl}/admin/handle-timesheet`, params.data, config);
  }

  static checkTimesheetStatuses(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.get(`${apiUrl}/admin/check-status`, config);
  }

  static handleCleanup(params) {
    const config = {
      ...requestConfig,
      headers: withAuthHeader(params),
    };
    return axios.post(`${apiUrl}/admin/cleanup`, null, config);
  }
}

export default AdminApi;
