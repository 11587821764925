import {
    SHOW_MESSAGE,
    CLOSE_MESSAGE
  } from '../store/constants';
 
const doShowMessage = payload => ({
    type: SHOW_MESSAGE,
    payload
});

const doCloseMessage = () => ({
    type: CLOSE_MESSAGE
});

export {
    doShowMessage,
    doCloseMessage
}