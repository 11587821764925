import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { 
    FETCH_DEFAULT_DAYS, 
    FETCH_DEFAULT_DAYS_SUCCESS, 
    FETCH_DEFAULT_DAYS_ERROR,
    POST_DEFAULT_DAY, 
    POST_DEFAULT_DAY_SUCCESS,
    POST_DEFAULT_DAY_ERROR,
    SHOW_MESSAGE,
    FETCH_TEAMLEAD_DATA
 } from '../../store/constants';
import { 
    loadDefaultDaysConfig as getDefaultDaysConfig,
    loadPostDefaultDay as postDefaultDay
} from '../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* fetchDefaultDays(action) { 
    try {
        const token = yield select(getLoginState);

        const defaultNotificationDays = yield call(getDefaultDaysConfig, {...action.payload, token});
        yield put({
            type: FETCH_DEFAULT_DAYS_SUCCESS,
            payload: defaultNotificationDays.data
        });
    }
    catch (error) {
        yield put({
            type: FETCH_DEFAULT_DAYS_ERROR,
            payload: error
        })
    }
}

export function* postDefaultDaySaga(action) {
    try {
        const token = yield select(getLoginState);

        const defaultNotificationDays = yield call(postDefaultDay, { data: action.payload, token});
        yield all([
            put({
                type: POST_DEFAULT_DAY_SUCCESS,
                payload: defaultNotificationDays.data
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'success',
                    content: 'Default day setting successfully changed.'
                }
            }),
            put({ type: FETCH_TEAMLEAD_DATA })
        ]);
    }
    catch (error) {
        yield all([
            put({
                type: POST_DEFAULT_DAY_ERROR,
                payload: error
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'error',
                    content: 'Error during default day update.'
                }
            })
        ])
    }
}

export function* watchFetchDefaultDays() {
    yield takeLatest(FETCH_DEFAULT_DAYS, fetchDefaultDays); 
}

export function* watchPostDefaultDay() {
    yield takeEvery(POST_DEFAULT_DAY, postDefaultDaySaga);
}