import React from 'react';
import PropTypes from 'prop-types';

import SelectContainer from './../skill-multi-select/select-container/SelectContainer';

const MultiSelect = ({
  field,
  form: { touched, errors, /* handleChange */ setFieldValue, setTouched },
  list,
  ...props
}) => {
  const handleTouch = (name) => {
    setTouched({ ...touched, [field.name]: true });
  };

  const handleValue = (value) => {
    setFieldValue(field.name, value);
  };

  return (
    <SelectContainer
      value={field.value}
      setValue={handleValue}
      setTouched={handleTouch}
      placeholder={props.placeholder}
      multi={true}
    />
  );
};

MultiSelect.propTypes = {
  list: PropTypes.array.isRequired,
};

export default MultiSelect;
