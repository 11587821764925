import * as R from 'ramda';
import createReducer from './../reducer-helpers/createReducer';
import { success, error } from './../../common/helpers/constanHelpers';
import { setLoading, setError } from './../reducer-helpers/reducerHelpers';

import {
  // CLEAR_EXPENSE_SHEETS,
  FETCH_EXPENSE_SHEETS_ADMIN,
  FETCH_EXPENSE_SHEETS,
  SET_EXPENSE_SHEETS,
} from '../../store/constants';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  items: [],
  offset: 0,
  count: 0,
  totalItemCount: 0,
};

const setItems = action =>
  R.evolve({
    loading: R.always(false),
    success: R.always(true),
    items: () => [...action.payload],
  });

const setAdminSuccess = action =>
  R.evolve({
    loading: R.always(false),
    success: R.always(true),
    items: () => [...action.payload.items],
    offset: () => R.path(['payload', 'offset'], action),
    count: () => R.path(['payload', 'count'], action),
    totalItemCount: () => R.path(['payload', 'totalItemCount'], action),
  });

const expenseReducer = createReducer(INITIAL_STATE, {
  [FETCH_EXPENSE_SHEETS]: setLoading,
  [FETCH_EXPENSE_SHEETS_ADMIN]: setLoading,
  [success(FETCH_EXPENSE_SHEETS)]: setItems,
  [SET_EXPENSE_SHEETS]: setItems,
  [error(FETCH_EXPENSE_SHEETS)]: setError,
  [error(FETCH_EXPENSE_SHEETS_ADMIN)]: setError,
  [success(FETCH_EXPENSE_SHEETS_ADMIN)]: setAdminSuccess,
});

export default expenseReducer;
