import axios from 'axios';

const interceptor =  {
  setupInterceptors: store => {
    axios.interceptors.request.use(
      function(config) {
        const state = store.getState();
        if (state.login.user && state.login.token) {
          config.headers['Access_token'] = state.login.token;
        }
        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  },
};

export default interceptor;