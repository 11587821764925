import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ListContainer from './../list-container/ListContainer';
import InputContainer from './../input-container/InputContainer';
import MultiInput from './../multi-input/MultiInput';

import styles from './SelectContainer.module.sass';

import { getParsedSkills } from './../../../selectors/cv/skills.selectors';

const SelectContainer = ({ value, setValue, setTouched, placeholder, multi, loadedSkill }) => {
  const [showList, setShowList] = useState(() => false);
  const [selectedSkills, setSelectedSkills] = useState(() => []);
  const [skillList, setSkillList] = useState(() => []);

  const skillset = useSelector(getParsedSkills);

  useEffect(() => {
    setSkillList(() => skillset);
  }, [skillset]);

  useEffect(() => {
    if (value && value === loadedSkill?.id) {
      const skill = {
        id: loadedSkill.id,
        label: loadedSkill.name,
      };
      setSelectedSkills(() => [skill]);
    }
    if (!value) {
      setSelectedSkills(() => []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectSkill = (id, label) => {
    const skill = {
      id,
      label,
    };
    setSelectedSkills(() => [skill, ...selectedSkills]);
    setShowList(() => false);
    setSkillList(() => skillset);
    if (multi) {
      setValue([...value, id]);
    } else {
      setValue(id);
    }
    setTouched();
  };

  const displayList = (bool) => setShowList(() => bool);

  const search = (term) => {
    const searchTerm = term.toLowerCase();

    const filtered = skillset.reduce((acc, cat) => {
      const reducedSubCat = cat.subCategories.reduce((acc, curr) => {
        const filteredSkills = curr.skills.filter((skill) =>
          skill.label.toLowerCase().includes(searchTerm),
        );
        return filteredSkills.length > 0
          ? [
              ...acc,
              {
                ...curr,
                skills: filteredSkills,
              },
            ]
          : acc;
      }, []);

      return reducedSubCat.length > 0
        ? [
            ...acc,
            {
              ...cat,
              subCategories: reducedSubCat,
            },
          ]
        : acc;
    }, []);
    setSkillList(() => filtered);
  };

  const clear = () => {
    if (multi) {
      setValue([]);
    }
    setSelectedSkills(() => []);
    setTouched();
  };

  const removeSelectedItem = (id) => {
    const filtered = selectedSkills.filter((item) => item.id !== id);
    setSelectedSkills(() => filtered);
    setValue(filtered);
    setTouched();
  };

  return (
    <div className={styles.selectContainer}>
      {multi ? (
        <MultiInput
          onToggleList={displayList}
          onSearch={search}
          onClear={clear}
          onRemoveItem={removeSelectedItem}
          placeholder={placeholder}
          selected={selectedSkills}
          listShow={showList}
        />
      ) : (
        <InputContainer
          onToggleList={displayList}
          onSearch={search}
          onClear={clear}
          placeholder={placeholder}
          selected={selectedSkills}
          listShow={showList}
        />
      )}
      {showList && (
        <ListContainer
          skillList={skillList}
          listShow={showList}
          onSelectSkill={selectSkill}
          onCloseList={displayList}
        />
      )}
    </div>
  );
};

SelectContainer.propTypes = {};

export default SelectContainer;
