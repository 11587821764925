import {
    FETCH_SKILLS,
    FETCH_SKILLS_SUCCESS,
    FETCH_SKILLS_ERROR,
    CREATE_SKILL_CATEGORY,
    CREATE_SKILL_CATEGORY_SUCCESS,
    CREATE_SKILL_CATEGORY_ERROR,
    CREATE_SKILL_SUBCATEGORY,
    CREATE_SKILL_SUBCATEGORY_SUCCESS,
    CREATE_SKILL_SUBCATEGORY_ERROR,
    CREATE_SKILLSET,
    CREATE_SKILLSET_SUCCESS,
    CREATE_SKILLSET_ERROR,
    UPDATE_SKILL_CATEGORY,
    UPDATE_SKILL_CATEGORY_SUCCESS,
    UPDATE_SKILL_CATEGORY_ERROR,
    UPDATE_SKILL_SUBCATEGORY,
    UPDATE_SKILL_SUBCATEGORY_SUCCESS,
    UPDATE_SKILL_SUBCATEGORY_ERROR,
    UPDATE_SKILLSET,
    UPDATE_SKILLSET_SUCCESS,
    UPDATE_SKILLSET_ERROR,
    DELETE_SKILL_CATEGORY,
    DELETE_SKILL_CATEGORY_SUCCESS,
    DELETE_SKILL_CATEGORY_ERROR,
    DELETE_SKILL_SUBCATEGORY,
    DELETE_SKILL_SUBCATEGORY_SUCCESS,
    DELETE_SKILL_SUBCATEGORY_ERROR,
    DELETE_SKILLSET,
    DELETE_SKILLSET_SUCCESS,
    DELETE_SKILLSET_ERROR,
    DELETE_START,
    DELETE_START_SUCCESS,
    DELETE_START_ERROR,
    CONFIRM_DELETE,
    CONFIRM_DELETE_SUCCESS,
    CONFIRM_DELETE_ERROR
 } from './../store/constants';

const doFetchSkills = () => ({
    type: FETCH_SKILLS
});

const doFetchSkillsSuccess = payload => ({
    type: FETCH_SKILLS_SUCCESS,
    payload
});

const doFetchSkillsError = error => ({
    type: FETCH_SKILLS_ERROR,
    payload: error
});

const doCreateSkillCategory = payload => ({
    type: CREATE_SKILL_CATEGORY,
    payload
});

const doCreateSkillCategorySuccess = payload => ({
    type: CREATE_SKILL_CATEGORY_SUCCESS,
    payload
});

const doCreateSkillCategoryError = payload => ({
    type: CREATE_SKILL_CATEGORY_ERROR,
    payload
});

const doCreateSkillSubCategory = payload => ({
    type: CREATE_SKILL_SUBCATEGORY,
    payload
});

const doCreateSkillSubCategorySuccess = payload => ({
    type: CREATE_SKILL_SUBCATEGORY_SUCCESS,
    payload
});

const doCreateSkillSubCategoryError = payload => ({
    type: CREATE_SKILL_SUBCATEGORY_ERROR,
    payload
});


const doCreateSkillset = payload => ({
    type: CREATE_SKILLSET,
    payload
});

const doCreateSkillsetSuccess = payload => ({
    type: CREATE_SKILLSET_SUCCESS,
    payload
});

const doCreateSkillsetError = payload => ({
    type: CREATE_SKILLSET_ERROR,
    payload
});

const doUpdateSkillCategory = payload => ({
    type: UPDATE_SKILL_CATEGORY,
    payload
});

const doUpdateSkillCategorySuccess = payload => ({
    type: UPDATE_SKILL_CATEGORY_SUCCESS,
    payload
});

const doUpdateSkillCategoryError = payload => ({
    type: UPDATE_SKILL_CATEGORY_ERROR,
    payload
});

const doUpdateSkillSubCategory = payload => ({
    type: UPDATE_SKILL_SUBCATEGORY,
    payload
});

const doUpdateSkillSubCategorySuccess = payload => ({
    type: UPDATE_SKILL_SUBCATEGORY_SUCCESS,
    payload
});

const doUpdateSkillSubCategoryError = payload => ({
    type: UPDATE_SKILL_SUBCATEGORY_ERROR,
    payload
});

const doUpdateSkillset = payload => ({
    type: UPDATE_SKILLSET,
    payload
});

const doUpdateSkillsetSuccess = payload => ({
    type: UPDATE_SKILLSET_SUCCESS,
    payload
});

const doUpdateSkillsetError = payload => ({
    type: UPDATE_SKILLSET_ERROR,
    payload
});

const doDeleteSkillCategory = payload => ({
    type: DELETE_SKILL_CATEGORY,
    payload
});

const doDeleteSkillCategorySuccess = payload => ({
    type: DELETE_SKILL_CATEGORY_SUCCESS,
    payload
});

const doDeleteSkillCategoryError = payload => ({
    type: DELETE_SKILL_CATEGORY_ERROR,
    payload
});

const doDeleteSkillSubCategory = payload => ({
    type: DELETE_SKILL_SUBCATEGORY,
    payload
});

const doDeleteSkillSubCategorySuccess = payload => ({
    type: DELETE_SKILL_SUBCATEGORY_SUCCESS,
    payload
});

const doDeleteSkillSubCategoryError = payload => ({
    type: DELETE_SKILL_SUBCATEGORY_ERROR,
    payload
});

const doDeleteSkillset = payload => ({
    type: DELETE_SKILLSET,
    payload
});

const doDeleteSkillsetSuccess = payload => ({
    type: DELETE_SKILLSET_SUCCESS,
    payload
});

const doDeleteSkillsetError = payload => ({
    type: DELETE_SKILLSET_ERROR,
    payload
});

const doStartDelete = payload => ({
    type: DELETE_START,
    payload
});

const doStartDeleteSuccess = payload => ({
    type: DELETE_START_SUCCESS,
    payload
});

const doStartDeleteError = payload => ({
    type: DELETE_START_ERROR,
    payload
});

const doConfirmDelete = () => ({
    type: CONFIRM_DELETE
});

const doConfirmDeleteSuccess = payload => ({
    type: CONFIRM_DELETE_SUCCESS,
    payload
});

const doConfirmDeleteError = payload => ({
    type: CONFIRM_DELETE_ERROR,
    payload
});

export {
    doFetchSkills,
    doFetchSkillsSuccess,
    doFetchSkillsError,
    doCreateSkillCategory,
    doCreateSkillCategorySuccess,
    doCreateSkillCategoryError,
    doCreateSkillSubCategory,
    doCreateSkillSubCategorySuccess,
    doCreateSkillSubCategoryError,
    doCreateSkillset,
    doCreateSkillsetSuccess,
    doCreateSkillsetError,
    doUpdateSkillCategory,
    doUpdateSkillCategorySuccess,
    doUpdateSkillCategoryError,
    doUpdateSkillSubCategory,
    doUpdateSkillSubCategorySuccess,
    doUpdateSkillSubCategoryError,
    doUpdateSkillset,
    doUpdateSkillsetSuccess,
    doUpdateSkillsetError,
    doDeleteSkillCategory,
    doDeleteSkillCategorySuccess,
    doDeleteSkillCategoryError,
    doDeleteSkillSubCategory,
    doDeleteSkillSubCategorySuccess,
    doDeleteSkillSubCategoryError,
    doDeleteSkillset,
    doDeleteSkillsetSuccess,
    doDeleteSkillsetError,
    doStartDelete,
    doStartDeleteSuccess,
    doStartDeleteError,
    doConfirmDelete,
    doConfirmDeleteSuccess,
    doConfirmDeleteError
}