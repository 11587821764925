import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ToTheTopButton.module.scss';

const ToTheTopButton = props => {
  const [show, setShow] = useState(false);

  const showRef = React.useRef(show);

  const handleScroll = () => {
    if (window.scrollY > 100 && !showRef.current) {
      handleSetShow(true);
    }

    if (window.scrollY < 100 && showRef.current) {
      handleSetShow(false);
    }
  };

  const handleSetShow = status => {
    setShow(status);
    showRef.current = status;
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className={`${styles.scrolButton} ${
          show ? styles.show : styles.noShow
        }`}
        onClick={() => handleScrollTop()}
      >
        <FontAwesomeIcon icon="chevron-up" size="lg" />
      </div>
    </React.Fragment>
  );
};

export default ToTheTopButton;
