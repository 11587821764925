import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bulma-components';

import styles from './CvDeleteConfirmationModal.module.sass';

const CvDeleteConfirmModal = ({ onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal.Card onClick={onClose}>
      <Modal.Card.Head>
        <Modal.Card.Title>Confirm delete</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        Are you sure?
        <div className={styles.buttonContainer}>
          <button type="button" className="button" onClick={handleConfirm}>
            Confirm
          </button>
          <button
            type="button"
            onClick={onClose}
            className={'button is-danger ' + styles.buttonSpace}
          >
            Cancel
          </button>
        </div>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

CvDeleteConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CvDeleteConfirmModal;
