import React from 'react';
import PropTypes from 'prop-types';

import StarCard from './../star-card/StarCard';

import styles from './CardList.module.sass';

const CardList = ({ users, loggedInUser }) => {
  const cards = users.map((user) => (
    <StarCard key={user.id} user={user} loggedInUser={loggedInUser} />
  ));

  return <div className={styles.gridMain}>{cards}</div>;
};

CardList.propTypes = {
  users: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object.isRequired,
};

export default React.memo(CardList);
