import { 
    FETCH_TEAMLEAD_DATA,
    FETCH_TEAMLEAD_DATA_SUCCESS,
    FETCH_TEAMLEAD_DATA_ERROR
} from '../store/constants';

const initalState = {
    error: false,
    loading: false,
    employeeNotification: 0,
    teamleadApprove: 0,
    teamleadNotification: 0
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case FETCH_TEAMLEAD_DATA:
            return { ...state, error: false, loading: true }
        case FETCH_TEAMLEAD_DATA_SUCCESS: 
            return { ...state, loading: false, ...action.payload }
        case FETCH_TEAMLEAD_DATA_ERROR:
            return { ...state, loading: false, error: true }
        default: 
            return state;
    }
}

export default reducer;