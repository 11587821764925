import { createSelector } from 'reselect';

const starshopState = (state) => state.starshop;

// export const getSearchParams = createSelector([starshopState], starbook => starbook.search);

export const getMerchItems = createSelector([starshopState], (starshop) =>
  starshop.merchItems.map((e) => ({ ...e })),
);

export const getOrderHistory = createSelector([starshopState], (starshop) =>
  starshop.orderHistory.map((e) => ({ ...e })),
);

export const getImageData = createSelector([starshopState], (starshop) => starshop.newImageData);

export const getLoadingState = createSelector([starshopState], (starshop) => starshop.loadingData);
export const getLoadingOrderHistoryState = createSelector(
  [starshopState],
  (starshop) => starshop.loadingOrderHistory,
);
export const getErrorState = createSelector([starshopState], (starshop) => starshop.error);
