import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { equals, ifElse, map } from 'ramda';
import { UPDATE_EXPENSE_SHEET_META } from '../../store/constants';

import { doShowMessage } from '../../actions/message';

import { doSetExpenseSheets } from '../../actions/expenseSheet';

import {
  doUpdateExpenseSheetMetaSuccess,
  doUpdateExpenseSheetMetaError,
} from '../../actions/expense';

import { updateExpenseSheetMetaData } from '../../api/expense.api';

const getExpenseSheets = state => state.expenseSheet.items;

const sheetMap = id => result =>
  ifElse(
    data => equals(id, data.id),
    data => ({
      ...data,
      ...result,
    }),
    data => data,
  );

export function* updateExpenseSeheetMetadata(action) {
  try {
    const result = yield call(updateExpenseSheetMetaData, action.payload);

    const sheets = yield select(getExpenseSheets);

    const sheetMapper = sheetMap(action.payload.id)(result.data);

    const newSheets = map(sheetMapper, sheets);

    yield all([put(doSetExpenseSheets(newSheets)), put(doUpdateExpenseSheetMetaSuccess())]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doUpdateExpenseSheetMetaError()),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchUpdateExpenseSeheetMetadata() {
  yield takeLatest(UPDATE_EXPENSE_SHEET_META, updateExpenseSeheetMetadata);
}
