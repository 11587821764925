import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { CLOSE_MESSAGE } from '../../store/constants';

import { getShow, getContent, getType } from '../../selectors/message.selectors';

import styles from './Message.module.scss';

const Message = ({ show, content, type, onCloseMessage }) => {
  const [closeTimeout, setCloseTimeout] = useState(null);

  const closeMessage = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout, setCloseTimeout(null));
    }

    onCloseMessage();
  };

  useEffect(() => {
    if (show) {
      setCloseTimeout(setTimeout(() => closeMessage(), 3000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <article
      className={`message ${styles.floatingMessage} ${type} ${
        !show || !content ? styles.notVisible : ''
      }`}
    >
      <div className="message-body">{content}</div>
      <button className={`delete ${styles.closeButton}`} onClick={() => closeMessage()}></button>
    </article>
  );
};

const mapStateToProps = (state) => ({
  show: getShow(state),
  content: getContent(state),
  type: getType(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseMessage: (payload) => dispatch({ type: CLOSE_MESSAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
