import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bulma-components';
import { useState } from 'react';
import { connect } from 'react-redux';
import styles from './StarshopPurchaseModal.module.sass';

const StarshopPurchaseModal = ({ onClose, onConfirm }) => {
  const [message, setMessage] = useState('');

  const handleConfirm = () => {
    onConfirm(message);
  };

  return (
    <Modal.Card>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Confirm purchase</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Form.Field>
          <Form.Label>Comment (Shirt size, etc.)</Form.Label>
          <Form.Textarea
            value={message}
            onChange={(e) => {
              return setMessage(e.target.value);
            }}
          />
        </Form.Field>
        <p className={`${styles.confirmText}`}>Are you sure?</p>
        <Form.Field kind="group">
          <Form.Control>
            <Button color="success" onClick={handleConfirm}>
              Confirm
            </Button>
          </Form.Control>
          <Form.Control>
            <Button color="" colorVariant="light" onClick={onClose}>
              Cancel
            </Button>
          </Form.Control>
        </Form.Field>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

StarshopPurchaseModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StarshopPurchaseModal);
