import {
  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from '../store/constants';
import { clone } from 'ramda';

const initalState = [];

const fileUploadReducer = (state = initalState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        [`${action.payload.data.id}-${action.payload.data.fileName}`]: {
          loading: true,
          error: false,
          success: false,
          progress: 0,
        },
      };
    case UPLOAD_FILE_PROGRESS: {
      const oldItem = state[action.payload.id];
      const newState = clone(state);

      newState[action.payload.id] = {
        ...oldItem,
        progress: action.payload.percentage,
      };
      return newState;
    }
    case UPLOAD_FILE_SUCCESS: {
      const oldItem = state[action.payload.id];
      const newState = clone(state);

      newState[action.payload.id] = {
        ...oldItem,
        loading: false,
        success: true,
        progress: 0,
      };
      return newState;
    }
    case UPLOAD_FILE_ERROR: {
      const oldItem = state[action.payload.id];
      const newState = clone(state);

      newState[action.payload.id] = {
        ...oldItem,
        loading: false,
        error: true,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default fileUploadReducer;
