export const IS_AUTHENTICATED = 'auth/IS_AUTHENTICATED';
export const AUTHENTICATED = 'auth/AUTHENTICATED';
export const NOT_AUTHENTICATED = 'auth/NOT_AUTHENTICATED';
export const IS_AUTHENTICATED_SUCCESS = 'auth/IS_AUTHENTICATED_SUCCESS';
export const IS_AUTHENTICATED_ERROR = 'auth/IS_AUTHENTICATED_ERROR';
export const DECREASE_PESOS = 'auth/DECREASE_PESOS';

export const DEAUTHENTICATE = 'auth/DEAUTHENTICATE';
export const DEAUTHENTICATE_SUCCESS = 'auth/DEAUTHENTICATE_SUCCESS';

export const TIMESHEET_FETCH = 'timesheet/TIMESHEET_FETCH';
export const TIMESHEET_FETCH_SUCCESS = 'timesheet/TIMESHEET_FETCH_SUCCESS';
export const TIMESHEET_FETCH_ERROR = 'timesheet/TIMESHEET_FETCH_ERROR';
export const SET_DATE = 'timesheet/SET_DATE';

export const PROFILE_FETCH = 'profile/PROFILE_FETCH';
export const PROFILE_FETCH_SUCCESS = 'profile/PROFILE_FETCH_SUCCESS';
export const PROFILE_FETCH_ERROR = 'profile/PROFILE_FETCH_ERROR';

export const APPROVE_TIMESHEET = 'teamlead/APPROVE_TIMESHEET';
export const APPROVE_TIMESHEET_SUCCESS = 'teamlead/APPROVE_TIMESHEET_SUCCESS';
export const APPROVE_TIMESHEET_ERROR = 'teamlead/APPROVE_TIMESHEET_ERROR';

export const SET_PROFILE_QUERY = 'profile/SET_PROFILE_QUERY';
export const CLEAR_PROFILE_QUERY = 'profile/CLEAR_PROFILE_QUERY';

export const FETCH_DEFAULT_DAYS = 'admin/FETCH_DEFAULT_DAYS';
export const FETCH_DEFAULT_DAYS_SUCCESS = 'admin/FETCH_DEFAULT_DAYS_SUCCESS';
export const FETCH_DEFAULT_DAYS_ERROR = 'admin/FETCH_DEFAULT_DAYS_ERROR';

export const POST_DEFAULT_DAY = 'admin/POST_DEFAULT_DAY';
export const POST_DEFAULT_DAY_SUCCESS = 'admin/POST_DEFAULT_DAY_SUCCESS';
export const POST_DEFAULT_DAY_ERROR = 'admin/POST_DEFAULT_DAY_ERROR';

export const FETCH_SPECIAL_MONTHS = 'admin/FETCH_SPECIAL_MONTHS';
export const FETCH_SPECIAL_MONTHS_SUCCESS = 'admin/FETCH_SPECIAL_MONTHS_SUCCESS';
export const FETCH_SPECIAL_MONTHS_ERROR = 'admin/FETCH_SPECIAL_MONTHS_ERROR';

export const POST_SPECIAL_MONTH = 'admin/POST_SPECIAL_MONTH';
export const POST_SPECIAL_MONTH_SUCCESS = 'admin/POST_SPECIAL_MONTH_SUCCESS';
export const POST_SPECIAL_MONTH_ERROR = 'admin/POST_SPECIAL_MONTH_ERROR';

export const DELETE_SPECIAL_MONTH = 'admin/DELETE_SPECIAL_MONTH';
export const DELETE_SPECIAL_MONTH_SUCCESS = 'admin/DELETE_SPECIAL_MONTH_SUCCESS';
export const DELETE_SPECIAL_MONTH_ERROR = 'admin/DELETE_SPECIAL_MONTH_ERROR';

export const FETCH_TEAMLEAD_DATA = 'teamlead/FETCH_TEAMLEAD_DATA';
export const FETCH_TEAMLEAD_DATA_SUCCESS = 'teamlead/FETCH_TEAMLEAD_DATA_SUCCESS';
export const FETCH_TEAMLEAD_DATA_ERROR = 'teamlead/FETCH_TEAMLEAD_DATA_ERROR';

export const SHOW_MESSAGE = 'message/SHOW_MESSAGE';
export const CLOSE_MESSAGE = 'message/CLOSE_MESSAGE';

export const REJECT_TIMESHEET = 'reject/REJECT_TIMESHEET';
export const REJECT_TIMESHEET_SUCCESS = 'reject/REJECT_TIMESHEET_SUCCESS';
export const REJECT_TIMESHEET_ERROR = 'reject/REJECT_TIMESHEET_SUCCESS';

export const GET_CRON = 'cron/GET_CRON';
export const GET_CRON_SUCCESS = 'cron/GET_CRON_SUCCESS';
export const GET_CRON_ERROR = 'cron/GET_CRON_ERROR';

export const SET_CRON = 'cron/SET_CRON';
export const SET_CRON_SUCCESS = 'cron/SET_CRON_SUCCESS';
export const SET_CRON_ERROR = 'cron/SET_CRON_ERROR';

export const REVOKE_TIMESHEET = 'revoke/REVOKE_TIMESHEET';
export const REVOKE_TIMESHEET_SUCCESS = 'revoke/REVOKE_TIMESHEET_SUCCESS';
export const REVOKE_TIMESHEET_ERROR = 'revoke/REVOKE_TIMESHEET_ERROR';

export const GET_BANK_HOLIDAYS = 'bankHolidays/GET_BANK_HOLIDAYS';
export const GET_BANK_HOLIDAYS_SUCCESS = 'bankHolidays/GET_BANK_HOLIDAYS_SUCCESS';
export const GET_BANK_HOLIDAYS_ERROR = 'bankHolidays/GET_BANK_HOLIDAYS_ERROR';

export const REMOVE_BANK_HOLIDAY = 'bankHolidays/REMOVE_BANK_HOLIDAY';
export const ADD_BANK_HOLIDAY = 'bankHolidays/ADD_BANK_HOLIDAY';
export const SET_BANK_HOLIDAY_LIST = 'bankHolidays/SET_BANK_HOLIDAY_LIST';

export const POST_BANK_HOLIDAYS = 'bankHolidays/SET_BANK_HOLIDAYS';
export const POST_BANK_HOLIDAYS_SUCCESS = 'bankHolidays/SET_BANK_HOLIDAYS_SUCCESS';
export const POST_BANK_HOLIDAYS_ERROR = 'bankHolidays/SET_BANK_HOLIDAYS_ERROR';

export const GET_WORK_HOURS = 'workHours/GET_WORK_HOURS';
export const GET_WORK_HOURS_SUCCESS = 'workHours/GET_WORK_HOURS_SUCCESS';
export const GET_WORK_HOURS_ERROR = 'workHours/GET_WORK_HOURS_ERROR';

export const POST_WORK_HOURS = 'workHours/POST_WORK_HOURS';
export const POST_WORK_HOURS_SUCCESS = 'workHours/POST_WORK_HOURS_SUCCESS';
export const POST_WORK_HOURS_ERROR = 'workHours/POST_WORK_HOURS_ERROR';

export const DELETE_WORK_HOURS = 'workHours/DELETE_WORK_HOURS';
export const DELETE_WORK_HOURS_SUCCESS = 'workHours/DELETE_WORK_HOURS_SUCCESS';
export const DELETE_WORK_HOURS_ERROR = 'workHours/DELETE_WORK_HOURS_ERROR';

// starbook

export const FETCH_STARBOOK_DATA = 'starbook/FETCH_STARBOOK_DATA';
export const FETCH_STARBOOK_DATA_SUCCESS = 'starbook/FETCH_STARBOOK_DATA_SUCCESS';
export const FETCH_STARBOOK_DATA_ERROR = 'starbook/FETCH_STARBOOK_DATA_ERROR';
export const SET_SEARCH_PARAMS = 'starbook/SET_SEARCH_PARAMS';

export const FETCH_QUERY_DATA = 'starbook/FETCH_QUERY_DATA';
export const FETCH_QUERY_DATA_SUCCESS = 'starbook/FETCH_QUERY_DATA_SUCCESS';
export const FETCH_QUERY_DATA_ERROR = 'starbook/FETCH_QUERY_DATA_ERROR';

export const ADVANCED_SEARCH_SHOW = 'starbook/ADVANCED_SEARCH_SHOW';
export const ADVANCED_SEARCH_HIDE = 'starbook/ADVANCED_SEARCH_HIDE';

// starshop

export const FETCH_STARSHOP_DATA = 'starshop/FETCH_STARSHOP_DATA';
export const FETCH_STARSHOP_DATA_SUCCESS = 'starshop/FETCH_STARSHOP_DATA_SUCCESS';
export const FETCH_STARSHOP_DATA_ERROR = 'starshop/FETCH_STARSHOP_DATA_ERROR';

export const FETCH_STARSHOP_ORDER_HISTORY = 'starshop/FETCH_STARSHOP_ORDER_HISTORY';
export const FETCH_STARSHOP_ORDER_HISTORY_SUCCESS = 'starshop/FETCH_STARSHOP_ORDER_HISTORY_SUCCESS';
export const FETCH_STARSHOP_ORDER_HISTORY_ERROR = 'starshop/FETCH_STARSHOP_ORDER_HISTORY_ERROR';

export const POST_STARSHOP_PURCHASE = 'starshop/POST_STARSHOP_PURCHASE';
export const POST_STARSHOP_PURCHASE_SUCCESS = 'starshop/POST_STARSHOP_PURCHASE_SUCCESS';
export const POST_STARSHOP_PURCHASE_ERROR = 'starshop/POST_STARSHOP_PURCHASE_ERROR';

// starshop-admin

export const FETCH_STARSHOP_ADMIN_ORDER_HISTORY = 'starshop/FETCH_STARSHOP_ADMIN_ORDER_HISTORY';
export const FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS =
  'starshop/FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS';
export const FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR =
  'starshop/FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR';

export const POST_STARSHOP_ADMIN_UPDATE_ORDER = 'starshop/POST_STARSHOP_ADMIN_UPDATE_ORDER';
export const POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS =
  'starshop/POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS';
export const POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR =
  'starshop/POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR';

export const CREATE_STARSHOP_ADMIN_MERCH = 'starshop/CREATE_STARSHOP_ADMIN_MERCH';
export const CREATE_STARSHOP_ADMIN_MERCH_SUCCESS = 'starshop/CREATE_STARSHOP_ADMIN_MERCH_SUCCESS';
export const CREATE_STARSHOP_ADMIN_MERCH_ERROR = 'starshop/CREATE_STARSHOP_ADMIN_MERCH_ERROR';

export const DELETE_STARSHOP_ADMIN_MERCH = 'starshop/DELETE_STARSHOP_ADMIN_MERCH';
export const DELETE_STARSHOP_ADMIN_MERCH_SUCCESS = 'starshop/DELETE_STARSHOP_ADMIN_MERCH_SUCCESS';
export const DELETE_STARSHOP_ADMIN_MERCH_ERROR = 'starshop/DELETE_STARSHOP_ADMIN_MERCH_ERROR';

export const UPDATE_STARSHOP_ADMIN_MERCH = 'starshop/UPDATE_STARSHOP_ADMIN_MERCH';
export const UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS = 'starshop/UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS';
export const UPDATE_STARSHOP_ADMIN_MERCH_ERROR = 'starshop/UPDATE_STARSHOP_ADMIN_MERCH_ERROR';

export const SET_NEW_IMAGE_DATA = 'starshop/SET_NEW_IMAGE_DATA';

// skills

export const FETCH_SKILLS = 'skills/FETCH_SKILLS';
export const FETCH_SKILLS_SUCCESS = 'skills/FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_ERROR = 'skills/FETCH_SKILLS_ERROR';

export const CREATE_SKILL_CATEGORY = 'skills/CREATE_SKILL_CATEGORY';
export const CREATE_SKILL_CATEGORY_SUCCESS = 'skills/CREATE_SKILL_CATEGORY_SUCCESS';
export const CREATE_SKILL_CATEGORY_ERROR = 'skills/CREATE_SKILL_CATEGORY_ERROR';
export const CREATE_SKILL_SUBCATEGORY = 'skills/CREATE_SKILL_SUBCATEGORY';
export const CREATE_SKILL_SUBCATEGORY_SUCCESS = 'skills/CREATE_SKILL_SUBCATEGORY_SUCCESS';
export const CREATE_SKILL_SUBCATEGORY_ERROR = 'skills/CREATE_SKILL_SUBCATEGORY_ERROR';
export const CREATE_SKILLSET = 'skills/CREATE_SKILLSET';
export const CREATE_SKILLSET_SUCCESS = 'skills/CREATE_SKILLSET_SUCCESS';
export const CREATE_SKILLSET_ERROR = 'skills/CREATE_SKILLSET_ERROR';

export const UPDATE_SKILL_CATEGORY = 'skills/UPDATE_SKILL_CATEGORY';
export const UPDATE_SKILL_CATEGORY_SUCCESS = 'skills/UPDATE_SKILL_CATEGORY_SUCCESS';
export const UPDATE_SKILL_CATEGORY_ERROR = 'skills/UPDATE_SKILL_CATEGORY_ERROR';
export const UPDATE_SKILL_SUBCATEGORY = 'skills/UPDATE_SKILL_SUBCATEGORY';
export const UPDATE_SKILL_SUBCATEGORY_SUCCESS = 'skills/UPDATE_SKILL_SUBCATEGORY_SUCCESS';
export const UPDATE_SKILL_SUBCATEGORY_ERROR = 'skills/UPDATE_SKILL_SUBCATEGORY_ERROR';
export const UPDATE_SKILLSET = 'skills/UPDATE_SKILLSET';
export const UPDATE_SKILLSET_SUCCESS = 'skills/UPDATE_SKILLSET_SUCCESS';
export const UPDATE_SKILLSET_ERROR = 'skills/UPDATE_SKILLSET_ERROR';

export const DELETE_SKILL_CATEGORY = 'skills/DELETE_SKILL_CATEGORY';
export const DELETE_SKILL_CATEGORY_SUCCESS = 'skills/DELETE_SKILL_CATEGORY_SUCCESS';
export const DELETE_SKILL_CATEGORY_ERROR = 'skills/DELETE_SKILL_CATEGORY_ERROR';
export const DELETE_SKILL_SUBCATEGORY = 'skills/DELETE_SKILL_SUBCATEGORY';
export const DELETE_SKILL_SUBCATEGORY_SUCCESS = 'skills/DELETE_SKILL_SUBCATEGORY_SUCCESS';
export const DELETE_SKILL_SUBCATEGORY_ERROR = 'skills/DELETE_SKILL_SUBCATEGORY_ERROR';
export const DELETE_SKILLSET = 'skills/DELETE_SKILLSET';
export const DELETE_SKILLSET_SUCCESS = 'skills/DELETE_SKILLSET_SUCCESS';
export const DELETE_SKILLSET_ERROR = 'skills/DELETE_SKILLSET_ERROR';
export const DELETE_START = 'skills/DELETE_START';
export const DELETE_START_SUCCESS = 'skills/DELETE_START_SUCCESS';
export const DELETE_START_ERROR = 'skills/DELETE_START_ERROR';
export const DELETE_CONFIRM = 'skills/DELETE_CONFIRM';
export const DELETE_CONFIRM_SUCCESS = 'skills/DELETE_CONFIRM_SUCCESS';
export const DELETE_CONFIRM_ERROR = 'skills/DELETE_CONFIRM_ERROR';
export const CONFIRM_DELETE = 'skills/CONFIRM_DELETE';
export const CONFIRM_DELETE_SUCCESS = 'skills/CONFIRM_DELETE_SUCCESS';
export const CONFIRM_DELETE_ERROR = 'skills/CONFIRM_DELETE_ERROR';

// departments

export const FETCH_DEPARTMENTS = 'departments/FETCH_DEPARTMENTS';
export const FETCH_DEPARTMENTS_SUCCESS = 'departments/FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_ERROR = 'departments/FETCH_DEPARTMENTS_ERROR';

export const SET_DEPARTMENTS = 'departments/SET_DEPARTMENTS';
export const SET_DEPARTMENTS_SUCCESS = 'departments/SET_DEPARTMENTS_SUCCESS';
export const SET_DEPARTMENTS_ERROR = 'departments/SET_DEPARTMENTS_ERROR';

// modal

export const MODAL_OPEN = 'modal/MODAL_OPEN';
export const MODAL_CLOSE = 'modal/MODAL_CLOSE';

// users

export const GET_USER_DETAILS = 'user/GET_USER_DETAILS';
export const UPDATE_USER_BASIC_META = 'user/UPDATE_USER_BASIC_META';
export const UPLOAD_PROFILE_PICTURE = 'user/UPLOAD_PROFILE_PICTURE';
export const CLEAR_USER = 'user/CLEAR_USER';

// cv
export const UPDATE_CV_LANGUAGES = 'cv/UPDATE_CV_LANGUAGES';

export const UPDATE_CV_EDUCATIONS = 'cv/UPDATE_CV_EDUCATIONS';
export const UPDATE_CV_EDUCATIONS_SUCCESS = 'cv/UPDATE_CV_EDUCATIONS_SUCCESS';
export const UPDATE_CV_EDUCATIONS_ERROR = 'cv/UPDATE_CV_EDUCATIONS_ERROR';

export const UPDATE_CV_CERTIFICATES = 'cv/UPDATE_CV_CERTIFICATES';
export const UPDATE_CV_CERTIFICATES_SUCCESS = 'cv/UPDATE_CV_CERTIFICATES_SUCCESS';
export const UPDATE_CV_CERTIFICATES_ERROR = 'cv/UPDATE_CV_CERTIFICATES_ERROR';

export const UPDATE_CV_EXPERIENCES = 'cv/UPDATE_CV_EXPERIENCES';
export const UPDATE_CV_EXPERIENCES_SUCCESS = 'cv/UPDATE_CV_EXPERIENCES_SUCCESS';
export const UPDATE_CV_EXPERIENCES_ERROR = 'cv/UPDATE_CV_EXPERIENCES_ERROR';

export const UPDATE_CV_SKILLS = 'cv/UPDATE_CV_SKILLS';
export const UPDATE_CV_SKILLS_SUCCESS = 'cv/UPDATE_CV_SKILLS_SUCCESS';
export const UPDATE_CV_SKILLS_ERROR = 'cv/UPDATE_CV_SKILLS_ERROR';

export const UPDATE_CV_META = 'cv/UPDATE_CV_META';

// redux saga requests

export const FETCH_CVS = 'cv/FETCH_CVS';
export const CLEAR_CVS = 'cv/CLEAR_CVS';
export const DELETE_CV = 'cv/DELETE_CV';

export const FETCH_CV = 'cv/FETCH_CV';
export const CLEAR_CV = 'cv/CLEAR_CVS';
export const INIT_CV = 'cv/INIT_CVS';
export const CREATE_CV = 'cv/CREATE_CV';
export const UPDATE_CV = 'cv/UPDATE_CV';

export const FETCH_CV_PREVIEW = 'cv/FETCH_CV_PREVIEW';
export const CLEAR_CV_PREVIEW = 'cv/CLEAR_CV_PREVIEW';

export const FETCH_EXPENSE_TASKS = 'expense/FETCH_EXPENSE_TASKS';
export const FETCH_EXPENSE_PROJECTS = 'expense/FETCH_EXPENSE_PROJECTS';
export const CLEAR_EXPENSE_TASKS = 'expense/CLEAR_EXPENSE_TASKS';
export const CLEAR_EXPENSE_PROJECTS = 'expense/CLEAR_EXPENSE_PROJECTS';

export const CREATE_EXPENSE_SHEET = 'expense/CREATE_EXPENSE_SHEET';
export const UPDATE_EXPENSE_SHEET = 'expense/UPDATE_EXPENSE_SHEET';

export const FETCH_EXPENSE_SHEETS = 'expense/FETCH_EXPENSE_SHEETS';
export const FETCH_EXPENSE_SHEETS_SUCCESS = 'expense/FETCH_EXPENSE_SHEETS_SUCCESS';
export const FETCH_EXPENSE_SHEETS_ERROR = 'expense/FETCH_EXPENSE_SHEETS_ERROR';

export const UPDATE_EXPENSE_SHEET_META = 'expense/UPDATE_EXPENSE_SHEET_META';
export const UPDATE_EXPENSE_SHEET_META_SUCCESS = 'expense/UPDATE_EXPENSE_SHEET_META_SUCCESS';
export const UPDATE_EXPENSE_SHEET_META_ERROR = 'expense/UPDATE_EXPENSE_SHEET_META_ERROR';

export const FETCH_EXPENSE_SHEETS_ADMIN = 'expense/FETCH_EXPENSE_SHEETS_ADMIN';
export const FETCH_EXPENSE_SHEETS_ADMIN_SUCCESS = 'expense/FETCH_EXPENSE_SHEETS_ADMIN_SUCCESS';
export const FETCH_EXPENSE_SHEETS_ADMIN_ERROR = 'expense/FETCH_EXPENSE_SHEETS_ADMIN_ERROR';

export const SET_EXPENSE_SHEETS = 'expense/SET_EXPENSE_SHEETS';
export const CLEAR_EXPENSE_SHEETS = 'expense/CLEAR_EXPENSE_SHEETS';

export const CREATE_EXPENSE = 'expense/CREATE_EXPENSE';
export const CREATE_EXPENSE_INIT = 'expense/CREATE_EXPENSE_INIT';

export const UPDATE_EXPENSE = 'expense/UPDATE_EXPENSE';
export const FETCH_EXPENSE_ROWS = 'expense/FETCH_EXPENSE_ROWS';
export const DELETE_EXPENSE_ROW = 'expense/DELELETE_EXPENSE_ROW';
export const UPLOAD_EXPENSE_ROW_ATTACHMENT = 'expense/UPLOAD_EXPENSE_ROW_ATTACHMENT';
export const DELETE_EXPENSE_ROW_ATTACHMENT = 'expense/DELETE_EXPENSE_ROW_ATTACHMENT';
// file

export const UPLOAD_FILE = 'user/UPLOAD_FILE';
export const UPLOAD_FILE_PROGRESS = 'user/UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_SUCCESS = 'user/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'user/UPLOAD_FILE_ERROR';

// use constant generator helpers error(), success()

// expense sheet invoices
export const FETCH_INVOICES = 'invoice/FETCH_INVOICES';
export const CREATE_INVOICE = 'invoice/CREATE_INVOICE';
export const UPDATE_INVOICE = 'invoice/UPDATE_INVOICE';
export const DELETE_INVOICE = 'invoice/DELETE_INVOICE';
export const UPLOAD_INVOICE_ATTACHMENT = 'invoice/UPLOAD_INVOICE_ATTACHMENT';
export const DELETE_ATTACHMENT = 'invoice/DELETE_ATTACHMENT';

export const FETCH_NEWS = 'news/FETCH_NEWS';

export const SET_PERMISSIONS = 'profile/SET_PERMISSIONS';

// polling
export const START_POLLING = 'timesheet-handler/START_POLLING';
export const STOP_POLLING = 'timesheet-handler/STOP_POLLING';
export const STATUS_UPDATE = 'timesheet-handler/STATUS_UPDATE';
export const HANDLE_TIMESHEET = 'timesheet-handler/HANDLE_TIMESHEET';
export const JOB_CLEANUP = 'timesheet-handler/JOB_CLEANUP';

// location manager
export const FETCH_LOCATIONS = 'location/FETCH_LOCATIONS';
export const POST_LOCATION = 'location/POST_LOCATION';
export const UPDATE_LOCATION = 'location/UPDATE_LOCATION';
export const DELETE_LOCATION = 'location/DELETE_LOCATION';
export const SET_LOCATION = 'location/SET_LOCATION';

// peso
export const FETCH_PESO_HISTORY = 'peso/FETCH_PESO_HISTORY';
export const FETCH_PESO_HISTORY_SUCCESS = 'peso/FETCH_PESO_HISTORY_SUCCESS';
export const FETCH_PESO_HISTORY_ERROR = 'peso/FETCH_PESO_HISTORY_ERROR';
export const SET_PESO_SEARCH_PARAMS = 'peso/SET_PESO_SEARCH_PARAMS';

export const FETCH_PESO_USER_HISTORY = 'peso/FETCH_PESO_USER_HISTORY';
export const FETCH_PESO_USER_HISTORY_SUCCESS = 'peso/FETCH_PESO_USER_HISTORY_SUCCESS';
export const FETCH_PESO_USER_HISTORY_ERROR = 'peso/FETCH_PESO_USER_HISTORY_ERROR';

export const POST_PESO_TRANSFER = 'peso/POST_PESO_TRANSFER';
export const POST_PESO_TRANSFER_SUCCESS = 'peso/POST_PESO_TRANSFER_SUCCESS';
export const POST_PESO_TRANSFER_ERROR = 'peso/POST_PESO_TRANSFER_ERROR';

export const POST_PESO_GIVE = 'peso/POST_PESO_GIVE';
export const POST_PESO_GIVE_SUCCESS = 'peso/POST_PESO_GIVE_SUCCESS';
export const POST_PESO_GIVE_ERROR = 'peso/POST_PESO_GIVE_ERROR';

export const POST_PESO_TAKE = 'peso/POST_PESO_TAKE';
export const POST_PESO_TAKE_SUCCESS = 'peso/POST_PESO_TAKE_SUCCESS';
export const POST_PESO_TAKE_ERROR = 'peso/POST_PESO_TAKE_ERROR';

// massage
export const MASSAGE_GET_WSS_URL = 'massage/MASSAGE_GET_WSS_URL';
export const MASSAGE_SET_WSS_URL = 'massage/MASSAGE_SET_WSS_URL';
