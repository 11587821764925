import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faKey,
  faSignOutAlt,
  faCog,
  faCogs,
  faToolbox,
  faCalendar,
  faAngleDown,
  faPlus,
  faMinus,
  faClock,
  faTasks,
  faProjectDiagram,
  faUserClock,
  faMoneyBill,
  faSpinner,
  faCircleNotch,
  faIndustry,
  faStopwatch,
  faMapMarker,
  faPhone,
  faCouch,
  faSearch,
  faTimes,
  faChevronDown,
  faChevronUp,
  faDna,
  faCheck,
  faMap,
  faIdBadge,
  faUpload,
  faCircle,
  faFileAlt,
  faTrashAlt,
  faEdit,
  faPrint,
  faUniversity,
  faFileInvoiceDollar,
  faEllipsisH,
  faFilter,
  faNewspaper,
  faMapMarkedAlt,
  faCoins,
  faAngleRight,
  faAngleLeft,
  faPaperPlane,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faExchangeAlt,
  faHome,
  faUser,
  faSave,
  faEye,
  faDownload,
  faSquareFull,
  faEllipsisV,
  faCommentAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import { faGoogle, faSlack } from '@fortawesome/fontawesome-free-brands';

export default function registerIcons() {
  library.add(
    faEnvelope,
    faUser,
    faKey,
    faSignOutAlt,
    faCog,
    faCogs,
    faToolbox,
    faCalendar,
    faAngleDown,
    faPlus,
    faMinus,
    faMapMarker,
    faClock,
    faMoneyBill,
    faTasks,
    faProjectDiagram,
    faUserClock,
    faGoogle,
    faSpinner,
    faMap,
    faCircleNotch,
    faIndustry,
    faStopwatch,
    faSlack,
    faPhone,
    faCouch,
    faSearch,
    faTimes,
    faChevronDown,
    faChevronUp,
    faDna,
    faCheck,
    faIdBadge,
    faUpload,
    faCircle,
    faFileAlt,
    faTrashAlt,
    faEdit,
    faPrint,
    faUniversity,
    faFileInvoiceDollar,
    faEllipsisH,
    faFilter,
    faNewspaper,
    faMapMarkedAlt,
    faCoins,
    faAngleRight,
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPaperPlane,
    faExchangeAlt,
    faHome,
    faSave,
    faEye,
    faDownload,
    faSquareFull,
    faEllipsisV,
    faCommentAlt,
    faInfoCircle,
  );
}
