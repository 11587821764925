import { 
    FETCH_SKILLS,
    FETCH_SKILLS_SUCCESS,
    FETCH_SKILLS_ERROR,
    CREATE_SKILL_CATEGORY,
    CREATE_SKILL_CATEGORY_SUCCESS,
    CREATE_SKILL_CATEGORY_ERROR,
    CREATE_SKILLSET,
    CREATE_SKILLSET_SUCCESS,
    CREATE_SKILLSET_ERROR,
    DELETE_START,
    DELETE_START_SUCCESS,
    DELETE_START_ERROR,
    CONFIRM_DELETE,
    CONFIRM_DELETE_SUCCESS,
    CONFIRM_DELETE_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    list: [],
    createCategoryLoading: false,
    createCategorySuccess: false,
    createCategoryError: false,
    createSkillsetLoading: false,
    createSkillsetSuccess: false,
    createSkillsetError: false,
    deleteStarted: false,
    countLoading: false,
    count: 0,
    countError: false,
    deleteType: '',
    deleteId: 0,
    deleteLoading: false,
    deleteError: false
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case FETCH_SKILLS:
            return {...state, loading: true, error: false };
        case FETCH_SKILLS_SUCCESS:
            return {...state, loading: false, list: action.payload};
        case FETCH_SKILLS_ERROR: 
            return {...state, loading: false, error: true};
        case CREATE_SKILL_CATEGORY:
            return {...state, createCategoryLoading: true, createCategorySuccess: false };
        case CREATE_SKILL_CATEGORY_SUCCESS:
            return {...state, createCategoryLoading: false, createCategorySuccess: true };
        case CREATE_SKILL_CATEGORY_ERROR:
            return {...state, createCategoryLoading: false, createCategoryError: true };
        case CREATE_SKILLSET:
            return {...state, createSkillsetLoading: true, createSkillsetSuccess: false };
        case CREATE_SKILLSET_SUCCESS:
            return {...state, createSkillsetLoading: false, createSkillsetSuccess: true, list: action.payload };
        case CREATE_SKILLSET_ERROR:
            return {...state, createSkillsetLoading: false, createSkillsetError: true };
        case DELETE_START:
            return {...state, countLoading: true, deleteStarted: true, deleteType: action.payload.idType, deleteId: action.payload.id };
        case DELETE_START_SUCCESS:
            return {...state, countLoading: false, count: action.payload.used};
        case DELETE_START_ERROR:
            return {...state, countLoading: false, countError: true};
        case CONFIRM_DELETE:
            return {...state, deleteLoading: true };
        case CONFIRM_DELETE_SUCCESS:
            return {...state, deleteLoading: false, list: action.payload};
        case CONFIRM_DELETE_ERROR:
            return {...state, deleteLoading: false, deleteError: true };
        default: 
            return state;
    }
} 

export default reducer;