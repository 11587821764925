import { call, put, takeLatest, all } from 'redux-saga/effects';

import { FETCH_NEWS } from './../../store/constants';
import { fetchNews } from './../../api/news.api';

import { doShowMessage } from './../../actions/message';
import { doFetchNewsSuccess, doFetchNewsError } from './../../actions/news';

export function* dataFetchSaga(action) {
  const successAction = doFetchNewsSuccess;
  const errorAction = doFetchNewsError;
  const endPoint = fetchNews;

  try {
    const { data } = yield call(endPoint, action.payload);

    yield all([put(successAction(data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(errorAction({})),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchFetchNews() {
  yield takeLatest(FETCH_NEWS, dataFetchSaga);
}
