import {
    FETCH_DEPARTMENTS,
    FETCH_DEPARTMENTS_SUCCESS,
    FETCH_DEPARTMENTS_ERROR,
    SET_DEPARTMENTS,
    SET_DEPARTMENTS_SUCCESS,
    SET_DEPARTMENTS_ERROR
 } from './../store/constants';

const doFetchDepartments = () => ({
    type: FETCH_DEPARTMENTS
});

const doFetchDepartmentsSuccess = payload => ({
    type: FETCH_DEPARTMENTS_SUCCESS,
    payload
});

const doFetchDepartmentsError = error => ({
    type: FETCH_DEPARTMENTS_ERROR,
    payload: error
});

const doSetDepartments = payload => ({
    type: SET_DEPARTMENTS,
    payload
});

const doSetDepartmentsSuccess = payload => ({
    type: SET_DEPARTMENTS_SUCCESS,
    payload
});

const doSetDepartmentsError = error => ({
    type: SET_DEPARTMENTS_ERROR,
    payload: error
});

export {
    doFetchDepartments,
    doFetchDepartmentsSuccess,
    doFetchDepartmentsError,
    doSetDepartments,
    doSetDepartmentsSuccess,
    doSetDepartmentsError
};