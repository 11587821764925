import { 
    UPDATE_CV_CERTIFICATES,
    UPDATE_CV_CERTIFICATES_SUCCESS,
    UPDATE_CV_CERTIFICATES_ERROR
} from '../../store/constants';

const initalState = {
    error: false,
    loading: false,
    success: false,
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case UPDATE_CV_CERTIFICATES:
            return {...state, loading: true, error: false, success: false };
        case UPDATE_CV_CERTIFICATES_SUCCESS:
            return {...state, loading: false, success: true };
        case UPDATE_CV_CERTIFICATES_ERROR: 
            return {...state, loading: false, error: true };
        default: 
            return state;
    }
}

export default reducer;