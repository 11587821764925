import { call, put, all } from 'redux-saga/effects';

import { doShowMessage } from '../../actions/message';

export function* fetchWorker(action) {
  const {
    payload,
    meta: { successAction, errorAction, endPoint /* successMessage */ },
  } = action;

  try {
    const { data } = yield call(endPoint, payload);

    yield all([put(successAction(data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}
