import React from 'react';
import { Loader } from 'react-bulma-components';

import styles from './LoaderContainer.module.scss';

const LoaderContainer = ({ size }) => {
  const loaderClass = size ? size : 'large';

  return (
    <div className={styles.loaderContainer} data-testid="loader">
      <Loader className={styles[loaderClass]} />
    </div>
  );
};

export default LoaderContainer;
