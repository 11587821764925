import StarbookApi from './../api/starbook.api';

// app

export const checkAuthStatus = () => StarbookApi.checkAuth().then(response => response);

export const logout = () => StarbookApi.logout().then(response => response);

// starbook data

export const getStarbookData = params =>
  StarbookApi.fetchStarbookData(params).then(response => response);

export const getQueryData = () => StarbookApi.fetchQueryData().then(response => response);

// skills

export const getSkills = () => StarbookApi.getSkills().then(response => response);

export const createSkillCategory = params =>
  StarbookApi.createSkillCategory(params).then(response => response);

export const createSkillSubCategory = params =>
  StarbookApi.createSkillSubCategory(params).then(response => response);

export const createSkillset = params =>
  StarbookApi.createSkillset(params).then(response => response);

export const updateSkillCategory = params =>
  StarbookApi.updateSkillCategory(params).then(response => response);

export const updateSkillSubCategory = params =>
  StarbookApi.updateSkillSubCategory(params).then(response => response);

export const updateSkillset = params =>
  StarbookApi.updateSkillset(params).then(response => response);

export const deleteSkillCategory = params =>
  StarbookApi.deleteSkillCategory(params).then(response => response);

export const deleteSkillSubCategory = params =>
  StarbookApi.deleteSkillSubCategory(params).then(response => response);

export const deleteSkillset = params =>
  StarbookApi.deleteSkillset(params).then(response => response);

export const fetchDepartments = () => StarbookApi.fetchDepartments().then(response => response);

export const setDepartments = params =>
  StarbookApi.setDepartments(params).then(response => response);

export const countForDelete = params =>
  StarbookApi.countForDelete(params).then(response => response);

export const deleteItem = params => StarbookApi.delete(params).then(response => response);

// users

export const getUserDetails = params =>
  StarbookApi.getUserDetails(params).then(response => response);

export const updateUserMeta = params => StarbookApi.postUserMeta(params).then(response => response);

export const uploadPicture = params =>
  StarbookApi.uploadProfilePicture(params).then(response => response);

export const updateCvLanguage = params =>
  StarbookApi.updateCvLanguage(params).then(response => response);

export const updateCvEducations = params =>
  StarbookApi.updateCvEducations(params).then(response => response);

export const updateCvCertificates = params =>
  StarbookApi.updateCvCertificates(params).then(response => response);

export const updateCvExperiences = params =>
  StarbookApi.updateCvExperiences(params).then(response => response);

export const updateCvSkills = params => StarbookApi.updateSkills(params).then(response => response);

export const postPermissions = params =>
  StarbookApi.setPermissions(params).then(response => response);

  // peso
export const getPESOHistoryData = (params) =>
  StarbookApi.fetchPESOHistoryData(params).then((response) => response);

export const getPESOUserHistoryData = (params) =>
  StarbookApi.fetchPESOUserHistoryData(params).then((response) => response);

export const postPesoTransfer = (params) =>
  StarbookApi.postPesoTransfer(params).then((response) => response);

export const postPesoGive = (params) =>
  StarbookApi.postPesoGive(params).then((response) => response);

export const postPesoTake = (params) =>
  StarbookApi.postPesoTake(params).then((response) => response);
