import { call, put, takeLatest, all } from 'redux-saga/effects';

import { FETCH_INVOICES } from '../../store/constants';

import { doShowMessage } from '../../actions/message';

import { doFetchInovicesSuccess, doFetchInovicesError } from '../../actions/expenseSheetInvoices';

import { getExpenseSheetInvoices } from '../../api/expense.api';

export function* fetchExpenseSheetsInvoices(action) {
  try {
    const result = yield call(getExpenseSheetInvoices, action.payload);

    yield all([put(doFetchInovicesSuccess(result.data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchInovicesError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchFetchExpenseSheetInvoices() {
  yield takeLatest(FETCH_INVOICES, fetchExpenseSheetsInvoices);
}
