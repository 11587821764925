import { 
    POST_SPECIAL_MONTH,
    POST_SPECIAL_MONTH_SUCCESS,
    FETCH_SPECIAL_MONTHS,
    FETCH_SPECIAL_MONTHS_SUCCESS,
    FETCH_SPECIAL_MONTHS_ERROR,
    DELETE_SPECIAL_MONTH,
    DELETE_SPECIAL_MONTH_SUCCESS,
    DELETE_SPECIAL_MONTH_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    list: [],
    deleteLoading: false,
    deleteError: false,
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case POST_SPECIAL_MONTH:
            return {...state, loading: true }
        case POST_SPECIAL_MONTH_SUCCESS:
            return {...state, loading: false }
        case FETCH_SPECIAL_MONTHS:
            return {...state, loading: true }
        case FETCH_SPECIAL_MONTHS_SUCCESS:
            return {...state, loading: false, list: action.payload }
        case FETCH_SPECIAL_MONTHS_ERROR: 
            return {...state, loading: false, error: true}
        case DELETE_SPECIAL_MONTH: 
            return {...state, deleteLoading: true }
        case DELETE_SPECIAL_MONTH_SUCCESS: 
            return {...state, deleteLoading: false}
        case DELETE_SPECIAL_MONTH_ERROR: 
            return {...state, deleteLoading: false, deleteError: true }
        default: 
            return state;
    }
}

export default reducer;