import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDebounce from './../../../common/hooks/useDebounce';

const SearchBoxForm = (props) => {
  const { handleSubmit, loading, onSearch, params, show, onShow, values } = props;

  const loadingClass = loading ? 'is-loading' : '';

  const toggleFilter = () => onShow();

  const debouncedValue = useDebounce(values, 500);

  useEffect(() => {
    if (!loading) {
      onSearch(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="field has-addons">
        <div className="control">
          <button type="button" className="button is-medium" onClick={toggleFilter}>
            {show ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
          </button>
        </div>
        {!loading && params?.search && (
          <div className="control">
            <button
              type="button"
              className="button is-medium"
              onClick={() =>
                onSearch({
                  search: '',
                })
              }
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </div>
        )}
        <div className="control is-expanded">
          <Field name="search">
            {({ field, form }) => (
              <div className={`control is-medium ${loadingClass}`}>
                <input
                  className="input is-medium"
                  type="text"
                  {...field}
                  placeholder="Search..."
                  autoFocus={!loading}
                />
              </div>
            )}
          </Field>
        </div>
        <div className="control">
          <button type="submit" className="button is-medium">
            <FontAwesomeIcon icon="search" />
          </button>
        </div>
      </div>
    </form>
  );
};

const SearchBox = withFormik({
  displayName: 'SearchForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    search: props.params?.search ? props.params.search : '',
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.onSearch(values);
    setSubmitting(false);
  },
})(SearchBoxForm);

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  params: PropTypes.object,
  show: PropTypes.bool,
  onShow: PropTypes.func,
};

export default React.memo(SearchBox);

export function isArray(any) {
  return Array.isArray(any);
}
