import React from 'react';
import PropTypes from 'prop-types';

import styles from './SubCategoryHeader.module.sass';

const SubCategoryHeader = ({ label, id, doFilterSubCategory, children }) => {
  return (
    <div>
      <div className={styles.header} onClick={() => doFilterSubCategory(id)}>
        {label}
      </div>
      <div>{children}</div>
    </div>
  );
};

SubCategoryHeader.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  doFilterSubCategory: PropTypes.func.isRequired,
  children: PropTypes.array,
};

export default SubCategoryHeader;
