import React from 'react';

import LoaderContainer from './../loader/LoaderContainer';

import styles from './AppLoader.module.scss';

const AppLoader = () => {
  return (
    <div className={styles.appLoader}>
      <div>
        <LoaderContainer />
        <p>Initializing application...</p>
      </div>
    </div>
  );
};

export default AppLoader;
