import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
  doCreateExpenseSuccess,
  doCreateExpenseError,
  doUpdateExpenseSuccess,
  doUpdateExpenseError,
} from '../../actions/expense';
import { doShowMessage } from './../../actions/message';
import { doUploadFile } from './../../actions/file-upload';

import {
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  // UPLOAD_EXPENSE_ROW_ATTACHMENT,
} from './../../store/constants';

import { postExpense, uploadExpenseAttachment, updateExpense } from '../../api/expense.api';

// const getExpenseRows = state => state.expenseRow.rows;

export function* createExpense(action) {
  try {
    console.log('payload', action.payload);
    const result = yield call(postExpense, action.payload);

    if (action.payload.data.attachment) {
      const payload = {
        data: action.payload,
        rowId: result.data.rowId,
        actions: {
          successAction: doCreateExpenseSuccess,
          errorAction: doCreateExpenseError,
        },
      };

      yield call(uploadExpenseRowAttachment, payload);
    } else {
      yield all([
        put(doCreateExpenseSuccess(result.data)),
        put(
          doShowMessage({
            type: 'success',
            content: 'Expense successfully saved',
          }),
        ),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(doCreateExpenseError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* updateExpenseSaga(action) {
  try {
    console.log(action.payload);
    const result = yield call(updateExpense, action.payload);
    console.log(result.data);
    if (action.payload.data.attachment && !action.payload.data.attachment.documentName) {
      const payload = {
        data: action.payload,
        rowId: result.data.rowId,
        actions: {
          successAction: doUpdateExpenseSuccess,
          errorAction: doUpdateExpenseError,
        },
      };

      yield call(uploadExpenseRowAttachment, payload);
    } else {
      console.log('no attachment');
      yield all([
        put(doUpdateExpenseSuccess(result.data)),
        put(
          doShowMessage({
            type: 'success',
            content: 'Expense successfully saved',
          }),
        ),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(doUpdateExpenseError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* uploadExpenseRowAttachment(payload) {
  const {
    data,
    rowId,
    actions: { successAction, errorAction },
  } = payload;

  try {
    yield put(
      doUploadFile({
        actions: {
          successAction,
          errorAction,
        },
        data: {
          id: data.id,
          rowId: rowId,
          data: data.data.attachment.data,
          fileName: data.data.attachment.fileName,
        },
        endPoint: uploadExpenseAttachment,
      }),
    );
  } catch (error) {}
}

export function* watchCreateExpense() {
  yield takeLatest(CREATE_EXPENSE, createExpense);
}

export function* watchUpdateExpense() {
  yield takeLatest(UPDATE_EXPENSE, updateExpenseSaga);
}
