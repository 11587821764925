import React, { useRef, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';

import styles from './TopLoader.module.sass';

const TopLoader = () => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.continuousStart(20, 150);
  }, []);

  return (
    <LoadingBar
      color="#2ab4f4"
      ref={ref}
      height={3}
      className={styles.starschemaLoader}
      shadow={false}
    />
  );
};

export default TopLoader;
