import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as R from 'ramda';

import {
  GET_USER_DETAILS,
  UPDATE_USER_BASIC_META,
  SET_PERMISSIONS,
  SET_LOCATION,
} from '../../store/constants';

import {
  doGetUserDetailsSuccess,
  doGetUserDetailsError,
  doUpdateBasicUserMetaError,
  doUpdateBasicUserMetaSuccess,
  doSetPermissionsSuccess,
  doSetPermissionsError,
} from '../../actions/user';

import { doShowMessage } from '../../actions/message';

import { getUserDetails, updateUserMeta, postPermissions } from '../starbookApiCalls';

import { postWorker } from './../helpers/post-worker';

const { ifElse } = R;

const apiCallObject = ifElse(
  (admin) => admin.adminViewAvailable,
  (data) => ({
    id: data.id,
    type: 'admin',
  }),
  (data) => ({
    id: data.id,
    type: 'details',
  }),
);

export function* getUserDetailsSaga(action) {
  try {
    const apiCallData = apiCallObject(action.payload);

    const result = yield call(getUserDetails, apiCallData);

    yield all([put(doGetUserDetailsSuccess(result.data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doGetUserDetailsError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* updateUserMetaSaga(action) {
  try {
    const result = yield call(updateUserMeta, action.payload);

    yield all([
      put(doUpdateBasicUserMetaSuccess(result.data)),
      put(
        doShowMessage({
          type: 'success',
          content: 'Successfully updated',
        }),
      ),
    ]);
  } catch (error) {
    yield all([
      put(doUpdateBasicUserMetaError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* setPermissions(action) {
  try {
    const { data } = yield call(postPermissions, action.payload);
    yield all([
      put(doSetPermissionsSuccess(data)),
      put(
        doShowMessage({
          type: 'success',
          content: 'Successfully updated',
        }),
      ),
    ]);
  } catch (error) {
    yield all([
      put(doSetPermissionsError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchGetUserDetails() {
  yield takeLatest(GET_USER_DETAILS, getUserDetailsSaga);
}

export function* watchUpdateBasicUserMeta() {
  yield takeLatest(UPDATE_USER_BASIC_META, updateUserMetaSaga);
}

export function* watchSetPermissions() {
  yield takeLatest(SET_PERMISSIONS, setPermissions);
}

export function* watchSetLocation() {
  yield takeLatest(SET_LOCATION, postWorker);
}
