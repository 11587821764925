import {
  call,
  put,
  takeEvery,
  takeLatest,
  all,
  select,
} from 'redux-saga/effects';
import {
  POST_SPECIAL_MONTH,
  POST_SPECIAL_MONTH_SUCCESS,
  FETCH_SPECIAL_MONTHS,
  FETCH_SPECIAL_MONTHS_SUCCESS,
  FETCH_SPECIAL_MONTHS_ERROR,
  DELETE_SPECIAL_MONTH,
  DELETE_SPECIAL_MONTH_SUCCESS,
  DELETE_SPECIAL_MONTH_ERROR,
  SHOW_MESSAGE,
  POST_SPECIAL_MONTH_ERROR,
} from '../../store/constants';
import {
  loadPostSpecialMonth as postSpecialMonth,
  loadFetchSpecialMonths as getSpecialMonths,
  deleteSpecialMonth,
} from '../apiCalls';

const getLoginState = state => state.login.jwtToken;

export function* fetchSpecialMonthsSaga(action) {
  try {
    const token = yield select(getLoginState);

    const specialMonths = yield call(getSpecialMonths, {
      ...action.payload,
      token,
    });
    yield put({
      type: FETCH_SPECIAL_MONTHS_SUCCESS,
      payload: specialMonths.data,
    });
  } catch (error) {
    yield put({
      type: FETCH_SPECIAL_MONTHS_ERROR,
      payload: error,
    });
  }
}

export function* postSpecialMonthSaga(action) {
  try {
    const token = yield select(getLoginState);

    const specialMonths = yield call(postSpecialMonth, {
      data: action.payload,
      token,
    });

    const messagePayload = {
      type: 'success',
      content: 'Saved successfully.',
    };
    yield all([
      put({
        type: POST_SPECIAL_MONTH_SUCCESS,
        payload: specialMonths.data,
      }),
      put({ type: FETCH_SPECIAL_MONTHS }),
      put({ type: SHOW_MESSAGE, payload: messagePayload }),
    ]);
  } catch (error) {
    const messagePayload = {
      type: 'error',
      content: 'Error while posting.',
    };
    yield all([
      put({ type: POST_SPECIAL_MONTH_ERROR, payload: error }),
      put({ type: SHOW_MESSAGE, payload: messagePayload }),
    ]);
  }
}

export function* deleteSpecialMonthSaga(action) {
  try {
    const token = yield select(getLoginState);

    const response = yield call(deleteSpecialMonth, {
      data: action.payload,
      token,
    });

    const messagePayload = {
      type: 'success',
      content: 'Succesfully deleted.',
    };
    yield all([
      put({
        type: DELETE_SPECIAL_MONTH_SUCCESS,
        payload: response.data,
      }),
      put({ type: FETCH_SPECIAL_MONTHS }),
      put({ type: SHOW_MESSAGE, payload: messagePayload }),
    ]);
  } catch (error) {
    const messagePayload = {
      type: 'error',
      content: 'Error while deleting.',
    };

    yield all([
      put({ type: DELETE_SPECIAL_MONTH_ERROR, payload: error }),
      put({ type: SHOW_MESSAGE, payload: messagePayload }),
    ]);
  }
}

export function* watchFetchSpecialMonths() {
  yield takeLatest(FETCH_SPECIAL_MONTHS, fetchSpecialMonthsSaga);
}

export function* watchPostSpecialMonth() {
  yield takeEvery(POST_SPECIAL_MONTH, postSpecialMonthSaga);
}

export function* watchDeleteSpecialMonth() {
  yield takeEvery(DELETE_SPECIAL_MONTH, deleteSpecialMonthSaga);
}
