import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { requestsReducer } from './../store/store';

import login from './login.reducers';
import timesheets from './timesheet.reducer';
import profile from './work-details.reducer';
//import approve from './approve.reducer';
import notificationSettings from './notification-days.reducer';
import specialMonths from './special-moths.reducer';
import message from './message.reducer';
import teamleadData from './teamlead-data.reducer';
// import reject from './reject.reducer';
import cron from './cron.reducer';
// import revoke from './revoke.reducer';
import bankHolidays from './bank-holidays.reducer';
import workHours from './work-hours.reducer';
import starbook from './starbook.reducer';
import peso from './peso.reducer';
import starshop from './starshop.reducer';
import queryData from './query-data.reducer';
import skills from './skills.reducer';
import departments from './departments.reducer';
import modal from './modal.reducer';
import user from './user.reducer';
import cv from './cv/cv.reducer';
import cvEducation from './cv/cv-education.reducer';
import cvCertificates from './cv/cv-certificates.reducer';
import cvExperiences from './cv/cv-experiences.reducer';
import cvSkills from './cv/cv-skills.reducer';
import expenseSheet from './expense/expenseSheets.reducer';
import expenseRow from './expense/expenseRows.reducer';
import fileUpload from './file-upload.reducer';
import expenseSheetMetadata from './expense/expenseSheetMetadata.reducer';
import expenseSheetInvoices from './expense/expenseSheetInvoices.reducer';
import news from './news/news.reducer';
import massage from './massage.reducer';
import timesheetHandler from './timesheet/timesheet-handler.reducer';
import locationManager from './location-manager/location-manager.reducer';

// ramda reducers

import createExpenseSheet from './expense/expenseSheet.reducer';

import { starbookPersistConfig } from './../store/store';

const rootReducer = (history) =>
  combineReducers({
    requests: requestsReducer,
    login,
    timesheets,
    profile,
    // approve,
    notificationSettings,
    specialMonths,
    message,
    teamleadData,
    // reject,
    cron,
    // revoke,
    massage,
    bankHolidays,
    workHours,
    starbook: persistReducer(starbookPersistConfig, starbook),
    starshop,
    queryData,
    skills,
    departments,
    modal,
    user,
    cv,
    cvEducation,
    cvCertificates,
    cvExperiences,
    cvSkills,
    expenseSheet,
    expenseRow,
    fileUpload,
    expenseSheetMetadata,
    expenseSheetInvoices,
    createExpenseSheet,
    news,
    timesheetHandler,
    locationManager,
    router: connectRouter(history),
    peso,
  });

export default rootReducer;
