import {
  FETCH_STARSHOP_DATA,
  FETCH_STARSHOP_DATA_SUCCESS,
  FETCH_STARSHOP_DATA_ERROR,
  FETCH_STARSHOP_ORDER_HISTORY,
  FETCH_STARSHOP_ORDER_HISTORY_SUCCESS,
  FETCH_STARSHOP_ORDER_HISTORY_ERROR,
  POST_STARSHOP_PURCHASE,
  POST_STARSHOP_PURCHASE_SUCCESS,
  POST_STARSHOP_PURCHASE_ERROR,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR,
  POST_STARSHOP_ADMIN_UPDATE_ORDER,
  POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS,
  POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR,
  CREATE_STARSHOP_ADMIN_MERCH,
  CREATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  CREATE_STARSHOP_ADMIN_MERCH_ERROR,
  DELETE_STARSHOP_ADMIN_MERCH,
  DELETE_STARSHOP_ADMIN_MERCH_SUCCESS,
  DELETE_STARSHOP_ADMIN_MERCH_ERROR,
  UPDATE_STARSHOP_ADMIN_MERCH,
  UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  UPDATE_STARSHOP_ADMIN_MERCH_ERROR,
  SET_NEW_IMAGE_DATA,
} from '../store/constants';

const doFetchStarshopData = () => ({
  type: FETCH_STARSHOP_DATA,
});

const doFetchStarshopDataSuccess = (payload) => ({
  type: FETCH_STARSHOP_DATA_SUCCESS,
  payload,
});

const doFetchStarshopDataError = (error) => ({
  type: FETCH_STARSHOP_DATA_ERROR,
  payload: error,
});

// Order History
const doFetchStarshopOrderHistory = () => ({
  type: FETCH_STARSHOP_ORDER_HISTORY,
});

const doFetchStarshopOrderHistorySuccess = (payload) => ({
  type: FETCH_STARSHOP_ORDER_HISTORY_SUCCESS,
  payload,
});

const doFetchStarshopOrderHistoryError = (error) => ({
  type: FETCH_STARSHOP_ORDER_HISTORY_ERROR,
  payload: error,
});

// Purchase
const doPostStarshopPurchase = (payload) => ({
  type: POST_STARSHOP_PURCHASE,
  payload,
});

const doPostStarshopPurchaseSuccess = (payload) => ({
  type: POST_STARSHOP_PURCHASE_SUCCESS,
  payload,
});

const doPostStarshopPurchaseError = (error) => ({
  type: POST_STARSHOP_PURCHASE_ERROR,
  payload: error,
});

// Admin
// Admin Order History
const doFetchStarshopAdminOrderHistory = (payload) => ({
  type: FETCH_STARSHOP_ADMIN_ORDER_HISTORY,
  payload,
});

const doFetchStarshopAdminOrderHistorySuccess = (payload) => ({
  type: FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS,
  payload,
});

const doFetchStarshopAdminOrderHistoryError = (error) => ({
  type: FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR,
  payload: error,
});

// Admin Update Order
const doPostStarshopAdminUpdateOrder = (payload) => ({
  type: POST_STARSHOP_ADMIN_UPDATE_ORDER,
  payload,
});

const doPostStarshopAdminUpdateOrderSuccess = (payload) => ({
  type: POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS,
  payload,
});

const doPostStarshopAdminUpdateOrderError = (error) => ({
  type: POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR,
  payload: error,
});

// Admin Create Merch
const doCreateStarshopAdminMerch = (payload) => ({
  type: CREATE_STARSHOP_ADMIN_MERCH,
  payload,
});

const doCreateStarshopAdminMerchSuccess = (payload) => ({
  type: CREATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  payload,
});

const doCreateStarshopAdminMerchError = (error) => ({
  type: CREATE_STARSHOP_ADMIN_MERCH_ERROR,
  payload: error,
});

// Admin Delete Merch
const doDeleteStarshopAdminMerch = (payload) => ({
  type: DELETE_STARSHOP_ADMIN_MERCH,
  payload,
});

const doDeleteStarshopAdminMerchSuccess = (payload) => ({
  type: DELETE_STARSHOP_ADMIN_MERCH_SUCCESS,
  payload,
});

const doDeleteStarshopAdminMerchError = (error) => ({
  type: DELETE_STARSHOP_ADMIN_MERCH_ERROR,
  payload: error,
});

// Admin Update Merch
const doUpdateStarshopAdminMerch = (payload) => ({
  type: UPDATE_STARSHOP_ADMIN_MERCH,
  payload,
});

const doUpdateStarshopAdminMerchSuccess = (payload) => ({
  type: UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  payload,
});

const doUpdateStarshopAdminMerchError = (error) => ({
  type: UPDATE_STARSHOP_ADMIN_MERCH_ERROR,
  payload: error,
});

const doSetNewImageData = (payload) => ({
  type: SET_NEW_IMAGE_DATA,
  payload,
});

export {
  doFetchStarshopData,
  doFetchStarshopDataSuccess,
  doFetchStarshopDataError,
  doFetchStarshopOrderHistory,
  doFetchStarshopOrderHistorySuccess,
  doFetchStarshopOrderHistoryError,
  doPostStarshopPurchase,
  doPostStarshopPurchaseSuccess,
  doPostStarshopPurchaseError,
  doFetchStarshopAdminOrderHistory,
  doFetchStarshopAdminOrderHistorySuccess,
  doFetchStarshopAdminOrderHistoryError,
  doPostStarshopAdminUpdateOrder,
  doPostStarshopAdminUpdateOrderSuccess,
  doPostStarshopAdminUpdateOrderError,
  doCreateStarshopAdminMerch,
  doCreateStarshopAdminMerchSuccess,
  doCreateStarshopAdminMerchError,
  doDeleteStarshopAdminMerch,
  doDeleteStarshopAdminMerchSuccess,
  doDeleteStarshopAdminMerchError,
  doUpdateStarshopAdminMerch,
  doUpdateStarshopAdminMerchSuccess,
  doUpdateStarshopAdminMerchError,
  doSetNewImageData,
};
