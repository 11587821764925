import {
    MODAL_CLOSE,
    MODAL_OPEN
}  from './../store/constants';

const doOpenModal = payload => ({
    type: MODAL_OPEN,
    payload
})

const doCloseModal = () => ({
    type: MODAL_CLOSE
})

export {
    doCloseModal,
    doOpenModal
}