import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import interceptor from './api/interceptor';
import { PersistGate } from 'redux-persist/integration/react';

import registerIcons from './registerIcons';

import configureStore, { history } from './store/store';

import AppLoader from './common/app-loader/AppLoader';

const { store, persistor } = configureStore();

interceptor.setupInterceptors(store);

registerIcons();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<AppLoader />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
