import { 
    FETCH_DEFAULT_DAYS, 
    FETCH_DEFAULT_DAYS_SUCCESS,
    FETCH_DEFAULT_DAYS_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    teamleadNotification: 0,
    employeeNotification: 0,
    teamleadApprove: 0
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case FETCH_DEFAULT_DAYS:
            return {...state, loading: true, error: false };
        case FETCH_DEFAULT_DAYS_SUCCESS:
            return {...state, loading: false, ...action.payload};
        case FETCH_DEFAULT_DAYS_ERROR: 
            return {...state, loading: false, error: false }
        default: 
            return state;
    }
}

export default reducer;