import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  FETCH_QUERY_DATA,
  SHOW_MESSAGE,
} from '../../store/constants';

import {
  doFetchQueryDataSuccess,
  doFetchQueryDataError,
} from '../../actions/starbook';

import { getQueryData } from '../starbookApiCalls';

const maconomyIdtoId = list =>
  list.map(item => ({
    id: item.maconomyId,
    name: item.name,
  }));

const parseLists = list =>
  list.map(item => ({
    value: item.id,
    label: item.name,
  }));

const parsePositions = list =>
  list.map(item => ({
    value: item,
    label: item,
  }));

export function* getQueryDataSaga(action) {
  try {
    const result = yield call(getQueryData, action.payload);

    const data = {
      positions: parsePositions(result.data.positions),
      managers: parseLists(result.data.managers),
      dums: parseLists(result.data.dums),
      departments: parseLists(
        maconomyIdtoId(result.data.departments),
      ),
    };

    yield all([put(doFetchQueryDataSuccess(data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchQueryDataError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* watchgetQueryDataSaga() {
  yield takeLatest(FETCH_QUERY_DATA, getQueryDataSaga);
}
