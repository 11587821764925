import { call, put, takeLatest, all } from 'redux-saga/effects';

import { 
    UPDATE_CV_SKILLS
} from '../../store/constants';

import {
    doUpdateCvSkillsSuccess,
    doUpdateCvSkillsError
} from '../../actions/cv';

import {
    doGetUserDetailsSuccess
} from '../../actions/user';

import {
    doShowMessage
} from '../../actions/message'

import { 
    updateCvSkills
 } from '../starbookApiCalls'; 

 export function* cvSkillsUpdateWorker(action) {
    try {
        
        const result = yield call(updateCvSkills, action.payload);
       
        yield all([
            put(doGetUserDetailsSuccess(result.data)),
            put(doUpdateCvSkillsSuccess({})),
            put(doShowMessage({
                    type: 'success',
                    content: 'Successfully updated skills'
                })
            )
        ]);

    }
    catch(error) {
        console.log(error);
        yield all([
            put(doUpdateCvSkillsError(error)),
            put(doShowMessage({
                    type: 'error',
                    content: error.message
                })
            )
        ])
    }
}



export function* watchCvSkillsUpdate() {
    yield takeLatest(UPDATE_CV_SKILLS, cvSkillsUpdateWorker); 
}