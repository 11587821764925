import { success, error } from './../common/helpers/constanHelpers';
import {
  CREATE_EXPENSE,
  CREATE_EXPENSE_INIT,
  FETCH_EXPENSE_ROWS,
  UPDATE_EXPENSE,
  DELETE_EXPENSE_ROW,
  UPDATE_EXPENSE_SHEET_META,
  UPLOAD_EXPENSE_ROW_ATTACHMENT,
  DELETE_EXPENSE_ROW_ATTACHMENT,
} from './../store/constants';

const doInitExpense = () => ({
  type: CREATE_EXPENSE_INIT,
});

const doCreateExpense = payload => ({
  type: CREATE_EXPENSE,
  payload,
});

const doCreateExpenseSuccess = payload => ({
  type: success(CREATE_EXPENSE),
  payload,
});

const doCreateExpenseError = payload => ({
  type: error(CREATE_EXPENSE),
  payload,
});

const doFetchExpenseRows = payload => ({
  type: FETCH_EXPENSE_ROWS,
  payload,
});

const doFetchExpenseRowsSuccess = payload => ({
  type: success(FETCH_EXPENSE_ROWS),
  payload,
});

const doFetchExpenseRowsError = payload => ({
  type: error(FETCH_EXPENSE_ROWS),
  payload,
});

const doUpdateExpense = payload => ({
  type: UPDATE_EXPENSE,
  payload,
});

const doUpdateExpenseSuccess = payload => ({
  type: success(UPDATE_EXPENSE),
  payload,
});

const doUpdateExpenseError = payload => ({
  type: error(UPDATE_EXPENSE),
  payload,
});

const doDeleteExpenseRow = payload => ({
  type: DELETE_EXPENSE_ROW,
  payload,
});

const doDeleteExpenseRowSuccess = payload => ({
  type: success(DELETE_EXPENSE_ROW),
  payload,
});

const doDeleteExpenseRowError = payload => ({
  type: error(DELETE_EXPENSE_ROW),
  payload,
});

const doUpdateExpenseSheetMeta = payload => ({
  type: UPDATE_EXPENSE_SHEET_META,
  payload,
});

const doUpdateExpenseSheetMetaSuccess = () => ({
  type: success(UPDATE_EXPENSE_SHEET_META),
});

const doUpdateExpenseSheetMetaError = () => ({
  type: error(UPDATE_EXPENSE_SHEET_META),
});

const doUploadExpenseRowAttachment = payload => ({
  type: UPLOAD_EXPENSE_ROW_ATTACHMENT,
  payload,
});

const doUploadExpenseRowAttachmentSuccess = payload => ({
  type: success(UPLOAD_EXPENSE_ROW_ATTACHMENT),
  payload,
});

const doUploadExpenseRowAttachmentError = payload => ({
  type: error(UPLOAD_EXPENSE_ROW_ATTACHMENT),
  payload,
});

const doDeleteExpenseRowAttachment = payload => ({
  type: DELETE_EXPENSE_ROW_ATTACHMENT,
  payload,
});

const doDeleteExpenseRowAttachmentSuccess = payload => ({
  type: success(DELETE_EXPENSE_ROW_ATTACHMENT),
  payload,
});

const doDeleteExpenseRowAttachmentError = payload => ({
  type: error(DELETE_EXPENSE_ROW_ATTACHMENT),
  payload,
});

export {
  doInitExpense,
  doCreateExpense,
  doCreateExpenseSuccess,
  doCreateExpenseError,
  doFetchExpenseRows,
  doFetchExpenseRowsSuccess,
  doFetchExpenseRowsError,
  doUpdateExpense,
  doUpdateExpenseSuccess,
  doUpdateExpenseError,
  doDeleteExpenseRow,
  doDeleteExpenseRowSuccess,
  doDeleteExpenseRowError,
  doUpdateExpenseSheetMeta,
  doUpdateExpenseSheetMetaSuccess,
  doUpdateExpenseSheetMetaError,
  doDeleteExpenseRowAttachment,
  doDeleteExpenseRowAttachmentSuccess,
  doDeleteExpenseRowAttachmentError,
  doUploadExpenseRowAttachment,
  doUploadExpenseRowAttachmentSuccess,
  doUploadExpenseRowAttachmentError,
};
