import axios from 'axios';

import { starbookApi } from './api-config';

const requestConfig = {
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

export default class StarbookApi {
  static checkAuth() {
    return axios.get(`${starbookApi}/app/info`, requestConfig);
  }

  static logout() {
    return axios.get(`${starbookApi}/account/logout`);
  }

  static fetchStarbookData(params) {
    return axios.post(`${starbookApi}/users/query`, params, requestConfig);
  }

  static fetchQueryData(params) {
    return axios.get(`${starbookApi}/users/query-meta`, requestConfig);
  }

  static getSkills() {
    return axios.get(`${starbookApi}/skills/query`, requestConfig);
  }

  static createSkillCategory(params) {
    return axios.post(`${starbookApi}/skills/create/category`, params, requestConfig);
  }

  static createSkillSubCategory(params) {
    return axios.post(`${starbookApi}/skills/create/subcategory`, params, requestConfig);
  }

  static createSkillset(params) {
    return axios.post(`${starbookApi}/skills/create/skillset`, params, requestConfig);
  }

  static updateSkillCategory(params) {
    return axios.post(`${starbookApi}/skills/update/category`, params, requestConfig);
  }

  static updateSkillSubCategory(params) {
    return axios.post(`${starbookApi}/skills/update/subcategory`, params, requestConfig);
  }

  static updateSkillset(params) {
    return axios.post(`${starbookApi}/skills/update/skillset`, params, requestConfig);
  }

  static deleteSkillCategory(params) {
    return axios.post(`${starbookApi}/skills/delete/category`, params, requestConfig);
  }

  static deleteSkillSubCategory(params) {
    return axios.post(`${starbookApi}/skills/delete/subcategory`, params, requestConfig);
  }

  static deleteSkillset(params) {
    return axios.post(`${starbookApi}/skills/delete/skillset`, params, requestConfig);
  }

  static countForDelete(params) {
    return axios.get(`${starbookApi}/skills/count/${params.idType}/${params.id}`, requestConfig);
  }

  static delete(params) {
    return axios.post(
      `${starbookApi}/skills/delete/${params.type}`,
      { id: params.id },
      requestConfig,
    );
  }

  static fetchDepartments() {
    return axios.get(`${starbookApi}/departments`, requestConfig);
  }

  static setDepartments(params) {
    return axios.post(`${starbookApi}/departments/set`, params, requestConfig);
  }

  static getUserDetails(params) {
    return axios.get(`${starbookApi}/users/${params.type}/${params.id}`, requestConfig);
  }

  static postUserMeta(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.userId}/meta`,
      params.data,
      requestConfig,
    );
  }

  static uploadProfilePicture(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/profile-pic?type=${params.type}`,
      params.data,
      {
        headers: {
          'Content-Type': `application/octet-stream`,
        },
        withCredentials: true,
        credentials: 'same-origin',
        onUploadProgress: (ProgressEvent) => params.progressFunc(ProgressEvent),
      },
    );
  }

  static updateCvMeta(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/cv-defaults/meta`,
      params.data,
      requestConfig,
    );
  }

  static updateCvLanguage(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/cv-defaults/languages`,
      params.data,
      requestConfig,
    );
  }

  static updateCvEducations(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/cv-defaults/education`,
      params.data,
      requestConfig,
    );
  }

  static updateCvCertificates(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/cv-defaults/certificates`,
      params.data,
      requestConfig,
    );
  }

  static updateCvExperiences(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/cv-defaults/experiences`,
      params.data,
      requestConfig,
    );
  }

  static updateSkills(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/skills`,
      params.data,
      requestConfig,
    );
  }

  static setPermissions(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/permissions`,
      params.data,
      requestConfig,
    );
  }

  static setLocation(params) {
    return axios.post(
      `${starbookApi}/users/update/${params.id}/location?locationId=${params.data.id}`,
      null,
      requestConfig,
    );
  }

  static fetchPESOHistoryData(params) {
    return axios.post(`${starbookApi}/pesos/admin/history`, params, requestConfig);
  }

  static fetchPESOUserHistoryData(params) {
    return axios.post(`${starbookApi}/pesos/history`, params, requestConfig);
  }

  static postPesoTransfer(params) {
    return axios.post(`${starbookApi}/pesos/transfer`, params, requestConfig);
  }

  static postPesoGive(params) {
    return axios.post(`${starbookApi}/pesos/admin/give`, params, requestConfig);
  }

  static postPesoTake(params) {
    return axios.post(`${starbookApi}/pesos/admin/take`, params, requestConfig);
  }
}
