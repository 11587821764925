import { takeLatest } from 'redux-saga/effects';
import { postWorker } from './../helpers/post-worker';
import { fetchWorker } from './../helpers/fetch-worker';

import {
  POST_LOCATION,
  FETCH_LOCATIONS,
  DELETE_LOCATION,
  UPDATE_LOCATION,
} from './../../store/constants';

export function* watchFetchLocation() {
  yield takeLatest(FETCH_LOCATIONS, fetchWorker);
}

export function* watchPostLocation() {
  yield takeLatest(POST_LOCATION, postWorker);
}

export function* watchDeleteLocation() {
  yield takeLatest(DELETE_LOCATION, postWorker);
}

export function* watchUpdateLocation() {
  yield takeLatest(UPDATE_LOCATION, postWorker);
}
