import { eventChannel, END, buffers } from 'redux-saga';

export default function createUploadFileChannel(endPoint, data, id) {
  let emit;
  return eventChannel(emitter => {
    emit = emitter;
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emit({ id, progress });
      }
    };

    endPoint({
      ...data,
      progressFunc: onProgress,
    })
      .then(response => {
        emit({ id, success: true, response });
        emit(END);
      })
      .catch(error => {
        emit({ id, error: new Error(error) });
        emit(END);
      });
    return () => {};
  }, buffers.sliding(5));
}
