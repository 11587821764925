import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bulma-components';
import { connect } from 'react-redux';

import LoaderContainer from './../../../common/loader/LoaderContainer';
import { getOrderHistory, getLoadingState } from './../../../selectors/starshop.selectors';
import { doFetchStarshopOrderHistory } from '../../../actions/starshop';
import { orderStatuses } from '../starshop.model';
import styles from './StarshopOrderHistoryModal.module.sass';

const OrderItem = React.memo(({ order }) => {
  let status = order.status.charAt(0).toUpperCase() + order.status.slice(1);
  let date =
    new Date(order.purchased).toLocaleDateString() +
    ' ' +
    new Date(order.purchased).toLocaleTimeString();
  let tagStyle =
    (order.status === orderStatuses.ordered && 'is-info') ||
    (order.status === orderStatuses.delivered && 'is-success') ||
    (order.status === orderStatuses.declined && 'is-danger');
  return (
    <div key={order.id} className="list-item">
      <li className="columns" style={{ marginBottom: '0' }}>
        <div className="column  mr-6">
          <span className="has-text-weight-bold is-pulled-left">{order.merch.name}</span>
        </div>
        <div className="column has-text-weight-light has-text-right">
          <span className="has-text-weight-light">{date}</span>
        </div>
        <div className="column equal-width  is-one-fifth">
          <span className={`tag  ${tagStyle} is-vcentered`} style={{ width: '100%' }}>
            {status}
          </span>
        </div>
      </li>
      {order.status === orderStatuses.declined && (
        <div>
          <span className="has-text-weight-semibold">Decline comment:</span> {order.declineComment}
        </div>
      )}
    </div>
  );
}, []);

const StarshopOrderHistoryModal = ({ onFetchOrderHistory, onClose, orders, loading }) => {
  useEffect(() => {
    onFetchOrderHistory();
  }, [onFetchOrderHistory]);
  return (
    <Modal.Card className={styles.modalCard}>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Order history</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <div className="columns is-centered">
          <div className="column">
            {loading || !orders ? (
              <LoaderContainer />
            ) : orders.length !== 0 ? (
              <div className="list">
                <ul>
                  {orders.map((order) => (
                    <OrderItem key={order.id} order={order} />
                  ))}
                </ul>
              </div>
            ) : (
              <span>
                Looks like you haven't made any orders.
                <br />
                Go ahead and pick something!
              </span>
            )}
          </div>
        </div>
        <Form.Field>
          <Form.Control>
            <Button color="" colorVariant="light" onClick={onClose}>
              Cancel
            </Button>
          </Form.Control>
        </Form.Field>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

StarshopOrderHistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return { orders: getOrderHistory(state), loading: getLoadingState(state) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchOrderHistory: () => dispatch(doFetchStarshopOrderHistory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarshopOrderHistoryModal);
