import { call, put, all, take, takeEvery } from 'redux-saga/effects';

import { UPLOAD_FILE } from './../store/constants';

import createFileUploadChannel from './file-upload/createFileUploadChannel';

import { doShowMessage } from './../actions/message';
import {
  doUploadFileSuccess,
  doUploadFileError,
  doUploadFileProgress,
} from './../actions/file-upload';

function* uploadFileWorker(action) {
  const {
    actions: { successAction, errorAction },
    data,
    endPoint,
  } = action.payload;

  const imageItemId = `${action.payload.data.id}-${action.payload.data.fileName}`;

  const channel = yield call(createFileUploadChannel, endPoint, data, imageItemId);

  while (true) {
    const { progress, error, success, response, id } = yield take(channel);

    if (error) {
      yield all([
        put(errorAction(error)),
        put(doUploadFileError({ id })),
        put(
          doShowMessage({
            type: 'error',
            content: error.message,
          }),
        ),
      ]);
      return;
    }
    if (success) {
      yield all([put(successAction(response.data)), put(doUploadFileSuccess({ id }))]);
      return;
    }
    yield put(doUploadFileProgress({ id, percentage: progress }));
  }
}

export function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE, uploadFileWorker);
}
