import { MODAL_OPEN, MODAL_CLOSE } from '../store/constants';

const initalState = {
  open: false,
  type: '',
  successFunction: null,
  data: null,
};

const modalReducer = (state = initalState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        open: true,
        type: action.payload.type,
        successFunction: action.payload.successFunction,
        data: action.payload.data,
      };
    case MODAL_CLOSE:
      return { ...initalState };
    default:
      return state;
  }
};

export default modalReducer;
