import AuthApi from './../api/auth.api';
import MaconomyApi from './../api/maconomy.api';
import AdminApi from './../api/admin.api';
import StarbookApi from './../api/starbook.api';
import StarshopApi from './../api/starshop.api';

// auth

export const loadAuth = (params) => {
  return AuthApi.verifyToken(params).then((response) => response);
};

// maconomy

export const loadUser = (params) => MaconomyApi.getUser(params).then((response) => response);

export const loadTimesheetForMonth = (params) =>
  MaconomyApi.getTimeSheetForUser(params).then((response) => response);

// profile

export const loadProfile = (params) => MaconomyApi.getProfile(params).then((response) => response);

// starshop
export const loadMerchItems = (params) =>
  StarshopApi.getMerchList(params).then((response) => response);

export const loadOrderHistory = (params) =>
  StarshopApi.getOrderHistory(params).then((response) => response);

export const postPurchase = (params) =>
  StarshopApi.postPurchase(params).then((response) => response);

// starshop-admin

export const loadAdminOrderHistory = (params) =>
  StarshopApi.getAdminOrderHistory(params).then((response) => response);

export const updateAdminOrder = (params) =>
  StarshopApi.postAdminUpdateOrderHistory(params).then((response) => response);

export const uploadStarshopImage = (params) =>
  StarshopApi.postAdminUploadImage(params).then((response) => response);

export const createAdminMerch = (params) =>
  StarshopApi.postAdminCreateMerch(params).then((response) => response);
export const deleteAdminMerch = (params) =>
  StarshopApi.postAdminDeleteMerch(params).then((response) => response);
export const updateAdminMerch = (params) =>
  StarshopApi.postAdminUpdateMerch(params).then((response) => response);

// admin

export const postApprove = (params) => AdminApi.postApproval(params).then((response) => response);

export const loadDefaultDaysConfig = (params) =>
  AdminApi.fetchDefaultDays(params).then((response) => response);

export const loadPostDefaultDay = (params) =>
  AdminApi.postDefaultDay(params).then((response) => response);

export const loadPostSpecialMonth = (params) =>
  AdminApi.postSpecialMonth(params).then((response) => response);

export const loadFetchSpecialMonths = (params) =>
  AdminApi.fetchSpecialMonths(params).then((response) => response);

export const deleteSpecialMonth = (params) =>
  AdminApi.deleteSpecialMonth(params).then((response) => response);

export const loadTeamleadData = (params) =>
  AdminApi.getTeamleadData(params).then((response) => response);

export const postReject = (params) => AdminApi.postReject(params).then((response) => response);

export const postRevoke = (params) => AdminApi.postRevoke(params).then((response) => response);

export const getCronSettings = (params) =>
  AdminApi.getCronsSettings(params).then((response) => response);

export const postCronSettings = (params) =>
  AdminApi.postCronSettings(params).then((response) => response);

export const getBankHolidays = (params) =>
  AdminApi.getBankHolidays(params).then((response) => response);

export const updateBankHolidays = (params) =>
  AdminApi.postBankHolidays(params).then((response) => response);

export const getWorkHours = (params) => AdminApi.getWorkHours(params).then((response) => response);

export const postWorkHours = (params) =>
  AdminApi.postWorkHoursForYear(params).then((response) => response);

export const deleteWorkHours = (params) =>
  AdminApi.deleteWorkHoursForYear(params).then((response) => response);

export const getStarbookData = (params) =>
  StarbookApi.fetchStarbookData(params).then((response) => response);

export const handleTimesheet = (params) =>
  AdminApi.handleTimesheet(params).then((response) => response);

export const checkTimesheetStatuses = (params) =>
  AdminApi.checkTimesheetStatuses(params).then((response) => response);

export const handleCleanup = (params) =>
  AdminApi.handleCleanup(params).then((response) => response);
