import React, { Suspense, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import LoaderContainer from './../../common/loader/LoaderContainer';
import ProtectedRoute from './../protected-route/ProtectedRoute';

import {
  HOME,
  TIMESHEET_REVIEW,
  TIMESHEET_SETTINGS,
  WORKDETAILS_PARAMS,
  WORKDETAILS,
  PROFILE,
  PROFILE_PARAMS,
  SKILLS,
  STARSHOP,
  STARSHOP_ADMIN,
  DELIVERY_UNITS,
  NEW_CV_PARAMS,
  CV,
  CV_PARAMS,
  CV_EDIT_PARAMS,
  STARBOOK,
  // TRAINING,
  // EXPENSE,
  // NEWS,
  LOCATION_MANAGER,
  PESO_ADMIN,
  MASSAGE_ADMIN,
  MASSAGE,
  MASSAGE_ADMIN_CONFIG_EDITOR,
} from './../../common/paths';

import Starbook from './../starbook/Starbook';
import StarshopAdmin from '../starshop-admin/StarshopAdmin';

// import Training from './../training/training/Training';

// dynamic imports
/* const ExpenseSheetCreator = lazy(() =>
  import('./../expense/expense-sheet/expense-sheet-creator/ExpenseSheetCreator'),
);
const ExpenseCreator = lazy(() => import('./../expense/expense/expense-creator/ExpenseCreator'));
const ExpenseEditor = lazy(() => import('./../expense/expense/expense-editor/ExpenseEditor'));
const ExpenseSheetEditor = lazy(() =>
  import('./../expense/expense-sheet/expense-sheet-editor/ExpenseSheetEditor'),
);
const ExpenseSheetDetails = lazy(() =>
  import('./../expense/expense-sheet/expense-sheet-details/ExpenseSheetDetails'),
);

const ExpenseSheetAdmin = lazy(() =>
  import('./../expense/expense-sheet/expense-sheet-admin/ExpenseSheetAdmin'),
);
const ExpenseSheetUser = lazy(() =>
  import('./../expense/expense-sheet/expense-sheet-user/ExpenseSheetUser'),
); */

const TimesheetReview = lazy(() =>
  import('./../timesheet-review/timesheet-review/TimesheetReview'),
);
const Settings = lazy(() => import('./../timesheet-review/settings/settings/Settings'));
const Profile = lazy(() => import('./../profile/Profile'));
const WorkDetails = lazy(() =>
  import('./../../components/timesheet-review/work-details/work-details/WorkDetails'),
);
const Skills = lazy(() => import('../skills/Skills'));
const Starshop = lazy(() => import('../starshop/Starshop'));
const DeliveryUnits = lazy(() => import('./../delivery-units/DeliveryUnits'));
const Cvs = lazy(() => import('../cv/cvs/Cvs'));
const Cv = lazy(() => import('./../cv/cv/Cv'));
const CvEdit = lazy(() => import('./../cv/cv-edit/CvEdit'));

const LocationManager = lazy(() =>
  import('./../location-manager/location-manager/LocationManager'),
);

const PESOAdmin = lazy(() => import('../peso-admin/PESOAdmin'));
const Massage = lazy(() => import('../massage/Massage'));
const MassageAdmin = lazy(() => import('../massage/MassageAdmin/MassageAdmin'));
const MassageAdminConfigEditor = lazy(() =>
  import('../massage/AdminConfigEditor/AdminConfigEditor'),
);

/* const News = lazy(() => import('./../news/news/News'));
const NewsPage = lazy(() => import('./../news/news-page/NewsPage'));
const NewsAdmin = lazy(() => import('./../news/news-admin/NewsAdmin')); */

const Main = (props) => {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Switch>
        <Redirect exact from={HOME} to={STARBOOK} />
        <ProtectedRoute exact path={TIMESHEET_REVIEW} component={TimesheetReview} />
        <ProtectedRoute path={TIMESHEET_SETTINGS} component={Settings} />
        <ProtectedRoute path={WORKDETAILS_PARAMS} component={WorkDetails} />
        <ProtectedRoute path={WORKDETAILS} component={WorkDetails} />
        <ProtectedRoute exact path={PROFILE} component={Profile} />
        <ProtectedRoute exact path={PROFILE_PARAMS} component={Profile} />
        <ProtectedRoute exact path={SKILLS} component={Skills} />
        <ProtectedRoute path={STARSHOP} component={Starshop} />
        <ProtectedRoute exact path={STARSHOP_ADMIN} component={StarshopAdmin} />
        <ProtectedRoute exact path={DELIVERY_UNITS} component={DeliveryUnits} />
        <ProtectedRoute exact path={NEW_CV_PARAMS} component={Cv} />
        <ProtectedRoute exact path={CV} component={Cvs} />
        <ProtectedRoute exact path={CV_PARAMS} component={Cvs} />
        <ProtectedRoute exact path={CV_EDIT_PARAMS} component={CvEdit} />
        <ProtectedRoute path={STARBOOK} component={Starbook} />
        <ProtectedRoute path={PESO_ADMIN} component={PESOAdmin} />
        <ProtectedRoute
          exact
          path={MASSAGE_ADMIN_CONFIG_EDITOR}
          component={MassageAdminConfigEditor}
        />
        <ProtectedRoute exact path={MASSAGE} component={Massage} />
        <ProtectedRoute exact path={MASSAGE_ADMIN} component={MassageAdmin} />
        {/* <ProtectedRoute path={TRAINING} component={Training} /> */}
        {/* <ProtectedRoute exact path={`${EXPENSE}/sheet/new`} component={ExpenseSheetCreator} />
        <ProtectedRoute exact path={`${EXPENSE}/sheet/:id/edit`} component={ExpenseSheetEditor} />
        <ProtectedRoute
          exact
          path={`${EXPENSE}/sheet/:id/details`}
          component={ExpenseSheetDetails}
        /> */}
        {/* <ProtectedRoute exact path={`${EXPENSE}/:id/new`} component={ExpenseCreator} />
        <ProtectedRoute exact path={`${EXPENSE}/:id/edit/:rowId`} component={ExpenseEditor} />
        <ProtectedRoute exact path={EXPENSE} component={ExpenseSheetUser} />
        <ProtectedRoute exact path={`${EXPENSE}/admin`} component={ExpenseSheetAdmin} />
        <ProtectedRoute exact path={NEWS} component={News} />
        <ProtectedRoute exact path={`${NEWS}/admin`} component={NewsAdmin} />
        <ProtectedRoute exact path={`${NEWS}/:slug`} component={NewsPage} /> */}
        <ProtectedRoute exact path={LOCATION_MANAGER} component={LocationManager} />
      </Switch>
    </Suspense>
  );
};

export default Main;
