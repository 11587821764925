import { call, put, takeLatest, all } from 'redux-saga/effects';

import { 
    UPDATE_CV_EDUCATIONS
} from '../../store/constants';

import {
    doUpdateCvEducationsSuccess,
    doUpdateCvEducationsError
} from '../../actions/cv';

import {
    doGetUserDetailsSuccess
} from '../../actions/user';

import {
    doShowMessage
} from '../../actions/message'

import { 
    updateCvEducations
 } from '../starbookApiCalls'; 

/* import * as R from 'ramda';
const { ifElse } = R; */



export function* cvEducationsUpdateWorker(action) {
    try {
        
        const result = yield call(updateCvEducations, action.payload);
       
        yield all([
            put(doGetUserDetailsSuccess(result.data)),
            put(doUpdateCvEducationsSuccess({})),
            put(doShowMessage({
                type: 'success',
                content: 'Successfully updated educations'
            })
        )
        ])

    }
    catch(error) {
        console.log(error);
        yield all([
            put(doUpdateCvEducationsError(error)),
            put(doShowMessage({
                    type: 'error',
                    content: error.message
                })
            )
        ])
    }
}



export function* watchCvEducationsUpdate() {
    yield takeLatest(UPDATE_CV_EDUCATIONS, cvEducationsUpdateWorker); 
}