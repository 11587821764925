import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bulma-components';
import { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { doUploadFile } from './../../../actions/file-upload';

import { head } from 'ramda';
import FileUploader from '../../../common/file-uploader/FileUploader';
import StarshopApi from '../../../api/starshop.api';
import { doSetNewImageData } from '../../../actions/starshop';

const StarshopCreateMerchModal = ({ onClose, onConfirm, data }) => {
  const [name, setName] = useState('');

  const [description, setDescription] = useState('');
  const [stockAvailable, setStockAvailable] = useState();
  const [cost, setCost] = useState();
  const [isHidden, setIsHidden] = useState(false);

  const dispatch = useDispatch();
  const imageDetails = useSelector((state) => state.starshop.newImageData);

  const handleCreate = () => {
    onConfirm({
      name,
      description,
      stockAvailable: parseInt(stockAvailable),
      cost: parseInt(cost),
      hidden: isHidden,
      imageFileNames: [{ id: imageDetails.id, type: imageDetails.type }],
    });
  };

  const uploadCallback = useCallback(
    (upLoadData) => {
      const data = head(upLoadData);

      // setFileId(() => `${userId}-${data.fileName}`);

      dispatch(
        doUploadFile({
          actions: {
            successAction: doSetNewImageData,
            // errorAction: doUploadProfilePictureError,
          },
          data: {
            // id: userId,
            data: data.data,
            type: data.type,
            fileName: data.fileName,
          },
          endPoint: StarshopApi.postAdminUploadImage,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Modal.Card>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Create Merch</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Form.Field>
          <Form.Label>Name:</Form.Label>
          <Form.Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Cool mug"
          />
        </Form.Field>
        <Form.Field>
          <Form.Label>Description:</Form.Label>
          <Form.Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="You might want to give these to your friends..."
          />
        </Form.Field>
        <Form.Field>
          <Form.Label>Image:</Form.Label>
          {imageDetails && (
            <img
              className="is-centered"
              src={imageDetails.imageLinks.large}
              alt={imageDetails.id}
              style={{}}
            />
          )}
          <FileUploader onUpload={uploadCallback} />
        </Form.Field>
        <Form.Field>
          <Form.Label>Stock available:</Form.Label>
          <Form.Input
            type="number"
            value={stockAvailable}
            onChange={(e) => setStockAvailable(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <Form.Label>Cost:</Form.Label>
          <Form.Input type="number" value={cost} onChange={(e) => setCost(e.target.value)} />
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <Form.Checkbox
              checked={isHidden} onChange={(e) => setIsHidden(e.target.checked)}> <strong>Hidden</strong>
            </Form.Checkbox>
          </Form.Control>
        </Form.Field>

        <Form.Label>Are you sure?</Form.Label>
        <Form.Field kind="group">
          <Form.Control>
            <Button color="success" onClick={handleCreate}>
              Create
            </Button>
          </Form.Control>
          <Form.Control>
            <Button color="" colorVariant="light" onClick={onClose}>
              Cancel
            </Button>
          </Form.Control>
        </Form.Field>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

StarshopCreateMerchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StarshopCreateMerchModal);
