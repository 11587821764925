import { call, put, takeLatest, all } from 'redux-saga/effects';
import { doCloseModal } from '../../actions/modal';
import { doFetchPesoUserHistory } from '../../actions/peso';

import {
  FETCH_PESO_HISTORY,
  FETCH_PESO_HISTORY_SUCCESS,
  FETCH_PESO_HISTORY_ERROR,
  FETCH_PESO_USER_HISTORY,
  FETCH_PESO_USER_HISTORY_SUCCESS,
  FETCH_PESO_USER_HISTORY_ERROR,
  POST_PESO_TRANSFER_SUCCESS,
  POST_PESO_TRANSFER_ERROR,
  POST_PESO_TRANSFER,
  POST_PESO_GIVE,
  POST_PESO_GIVE_SUCCESS,
  POST_PESO_GIVE_ERROR,
  POST_PESO_TAKE,
  POST_PESO_TAKE_SUCCESS,
  POST_PESO_TAKE_ERROR,
} from './../../store/constants';
import { getPESOHistoryData, getPESOUserHistoryData, postPesoGive, postPesoTake, postPesoTransfer } from './../starbookApiCalls';

export function* getPESOHistoryDataSaga(action) {
  try {
    const result = yield call(getPESOHistoryData, action.payload);

    yield all([
      put({
        type: FETCH_PESO_HISTORY_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: FETCH_PESO_HISTORY_ERROR,
        payload: error,
      }),
    ]);
  }
}

export function* getPESOUserHistoryDataSaga(action) {
  try {
    const result = yield call(getPESOUserHistoryData, action.payload);

    yield all([
      put({
        type: FETCH_PESO_USER_HISTORY_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: FETCH_PESO_USER_HISTORY_ERROR,
        payload: error,
      }),
    ]);
  }
}

export function* postPesoTransferSaga(action) {
  try {
    const result = yield call(postPesoTransfer, action.payload);

    yield all([
      put(doFetchPesoUserHistory),
      put(doCloseModal()),
      put({
        type: POST_PESO_TRANSFER_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: POST_PESO_TRANSFER_ERROR,
        payload: error.response,
      }),
    ]);
  }
}

export function* postPesoGiveSaga(action) {
  try {
    const result = yield call(postPesoGive, action.payload);

    yield all([
      put(doCloseModal()),
      put({
        type: POST_PESO_GIVE_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: POST_PESO_GIVE_ERROR,
        payload: error.response,
      }),
    ]);
  }
}

export function* postPesoTakeSaga(action) {
  try {
    const result = yield call(postPesoTake, action.payload);

    yield all([
      put(doCloseModal()),
      put({
        type: POST_PESO_TAKE_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: POST_PESO_TAKE_ERROR,
        payload: error.response,
      }),
    ]);
  }
}

export function* watchGetPESOHistorySaga() {
  yield takeLatest(FETCH_PESO_HISTORY, getPESOHistoryDataSaga);
}

export function* watchGetPESOUserHistorySaga() {
  yield takeLatest(FETCH_PESO_USER_HISTORY, getPESOUserHistoryDataSaga);
}

export function* watchPostPesoTransferSaga() {
  yield takeLatest(POST_PESO_TRANSFER, postPesoTransferSaga);
}

export function* watchPostPesoGiveSaga() {
  yield takeLatest(POST_PESO_GIVE, postPesoGiveSaga);
}

export function* watchPostPesoTakeSaga() {
  yield takeLatest(POST_PESO_TAKE, postPesoTakeSaga);
}
