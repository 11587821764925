import { success, error } from './../common/helpers/constanHelpers';
import { createConstantAction, createSimpleAction } from './actionCreator';
import {
  SET_EXPENSE_SHEETS,
  CLEAR_EXPENSE_SHEETS,
  FETCH_EXPENSE_SHEETS,
  FETCH_EXPENSE_SHEETS_ADMIN,
} from '../store/constants';

const doSetExpenseSheets = createSimpleAction(SET_EXPENSE_SHEETS);

const doClearExpenseSheets = createConstantAction(CLEAR_EXPENSE_SHEETS);

const doFetchActiveExpenses = createConstantAction(FETCH_EXPENSE_SHEETS);

const doFetchActiveExpensesSuccess = createSimpleAction(success(FETCH_EXPENSE_SHEETS));

const doFetchActiveExpensesError = createSimpleAction(error(FETCH_EXPENSE_SHEETS));

/* const doQueryExpenses = payload => ({
  type: FETCH_EXPENSE_SHEETS_ADMIN,
  payload: {
    ...payload,
  },
}); */
const doQueryExpenses = createSimpleAction(FETCH_EXPENSE_SHEETS_ADMIN);

const doQueryExpenseSuccess = createSimpleAction(success(FETCH_EXPENSE_SHEETS_ADMIN));

const doQueryExpenseError = createSimpleAction(error(FETCH_EXPENSE_SHEETS_ADMIN));

export {
  doClearExpenseSheets,
  doFetchActiveExpenses,
  doFetchActiveExpensesSuccess,
  doFetchActiveExpensesError,
  doQueryExpenses,
  doQueryExpenseSuccess,
  doQueryExpenseError,
  doSetExpenseSheets,
};
