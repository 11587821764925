import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bulma-components';

import {
  DELETE_MODAL,
  DELETE_CV_MODAL,
  PESO_TRANSFER_MODAL,
  STARSHOP_PURCHASE_MODAL,
  STARSHOP_ORDER_HISTORY_MODAL,
  STARSHOP_UPDATE_ORDER_MODAL,
  STARSHOP_CREATE_MERCH_MODAL,
  STARSHOP_DELETE_MERCH_MODAL,
  STARSHOP_UPDATE_MERCH_MODAL,
  MASSAGE_NOTE_MODAL,
} from './../../common/modalTypes';

import DeleteConfirmModal from './../skills/delete-confirm-modal/DeleteConfirmModal';
import CvDeleteConfirmationModal from './../cv/cv-delete-confirmation-modal/CvDeleteConfirmationModal';
import StarshopPurchaseModal from './../starshop/starshop-purchase-modal/StarshopPurchaseModal';
import StarshopOrderHistoryModal from '../starshop/starshop-order-history-modal/StarshopOrderHistoryModal';
import StarshopUpdateOrderModal from '../starshop-admin/starshop-update-order-modal/StarshopUpdateOrderModal';

import { doCloseModal } from './../../actions/modal';
import PesoModal from '../profile/peso-modal/PesoModal';
import StarshopCreateMerchModal from '../starshop-admin/starshop-create-merch-modal/StarshopCreateMerchModal';
import DeleteMerchConfirmModal from '../starshop-admin/delete-merch-confirm-modal/DeleteMerchConfirmModal';
import StarshopUpdateMerchModal from '../starshop-admin/starshop-update-merch-modal/StarshopUpdateMerchModal';
import CommentModal from '../massage/CommentModal/CommentModal';

const ModalContainer = ({ type, onClose, modalOpen, successFunction, data }) => {
  return (
    <Modal show={modalOpen} onClose={onClose} closeOnEsc={true} closeOnBlur={true}>
      {type === DELETE_MODAL && <DeleteConfirmModal />}
      {type === DELETE_CV_MODAL && (
        <CvDeleteConfirmationModal onClose={onClose} onConfirm={successFunction} />
      )}
      {type === PESO_TRANSFER_MODAL && <PesoModal />}
      {type === MASSAGE_NOTE_MODAL && <CommentModal onConfirm={successFunction} data={data} />}
      {type === STARSHOP_PURCHASE_MODAL && (
        <StarshopPurchaseModal onClose={onClose} onConfirm={successFunction} />
      )}
      {type === STARSHOP_ORDER_HISTORY_MODAL && (
        <StarshopOrderHistoryModal onClose={onClose} data={data} />
      )}
      {type === STARSHOP_UPDATE_ORDER_MODAL && (
        <StarshopUpdateOrderModal onClose={onClose} onConfirm={successFunction} data={data} />
      )}
      {type === STARSHOP_CREATE_MERCH_MODAL && (
        <StarshopCreateMerchModal onClose={onClose} onConfirm={successFunction} data={data} />
      )}
      {type === STARSHOP_UPDATE_MERCH_MODAL && (
        <StarshopUpdateMerchModal onClose={onClose} onConfirm={successFunction} data={data} />
      )}
      {type === STARSHOP_DELETE_MERCH_MODAL && (
        <DeleteMerchConfirmModal onClose={onClose} onConfirm={successFunction} />
      )}
    </Modal>
  );
};

ModalContainer.propTypes = {
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.modal.open,
    type: state.modal.type,
    successFunction: state.modal.successFunction,
    data: state.modal.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(doCloseModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
