import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bulma-components';
import { useState } from 'react';
import { connect } from 'react-redux';
import { orderStatuses } from '../../starshop/starshop.model';

const StarhopUpdateOrderModal = ({ onClose, onConfirm, data }) => {
  const [status, setStatus] = useState(data.status);
  const [declineComment, setDeclineComment] = useState(data.declineComment);

  const handleUpdate = () => {
    onConfirm(status, declineComment);
  };

  return (
    <Modal.Card>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Confirm purchase</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Form.Field>
          <Form.Label>Status</Form.Label>
          <div className="select">
            <select
              defaultValue={status}
              onChange={(e) => {
                if (e.target.value !== orderStatuses.declined) setDeclineComment('');
                setStatus(e.target.value);
              }}
            >
              <option value={orderStatuses.ordered}>Ordered</option>
              <option value={orderStatuses.delivered}>Delivered</option>
              <option value={orderStatuses.declined}>Declined</option>
            </select>
          </div>
        </Form.Field>
        {status === orderStatuses.declined && (
          <Form.Field>
            <Form.Label>Decline comment:</Form.Label>
            <Form.Textarea
              value={declineComment}
              onChange={(e) => setDeclineComment(e.target.value)}
            />
          </Form.Field>
        )}
        <Form.Label>Are you sure?</Form.Label>
        <Form.Field kind="group">
          <Form.Control>
            <Button color="success" onClick={handleUpdate}>
              Update
            </Button>
          </Form.Control>
          <Form.Control>
            <Button color="" colorVariant="light" onClick={onClose}>
              Cancel
            </Button>
          </Form.Control>
        </Form.Field>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

StarhopUpdateOrderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StarhopUpdateOrderModal);
