import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'react-bulma-components';

import styles from './DeleteMerchConfirmModal.module.scss';

const DeleteMerchConfirmModal = ({ onClose, onConfirm }) => {
  return (
    <Modal.Card onClick={onClose}>
      <Modal.Card.Head>
        <Modal.Card.Title>Confirm delete</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Form.Label>Are you sure you want to delete this merch?</Form.Label>
        <div className={styles.buttonContainer}>
          <button type="button" className="button  is-danger" onClick={onConfirm}>
            Delete
          </button>
          <button type="button" onClick={onClose} className={'button ' + styles.buttonSpace}>
            Cancel
          </button>
        </div>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

DeleteMerchConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMerchConfirmModal);
