import axios from 'axios';

import { starbookApi } from './api-config';

const requestConfig = {
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

export default class StarshopApi {
  static getMerchList(params) {
    return axios.post(`${starbookApi}/merch/query`, { ...params }, requestConfig);
  }

  static getOrderHistory(params) {
    return axios.post(`${starbookApi}/merch/query-purchases`, { ...params }, requestConfig);
  }

  static postPurchase(params) {
    return axios.post(`${starbookApi}/merch/purchase`, { ...params }, requestConfig);
  }

  // Admin
  // static postAdminUploadImage(params) {
  //   // let formData = new FormData();
  //   // formData.append('image', params.file);
  //   const config = {
  //     withCredentials: true,
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': `application/octet-stream`,
  //     },
  //   };
  //   return axios.post(
  //     `${starbookApi}/admin/merch/upload-image?type=${params.type.split('/')[1]}`,
  //     params.file,
  //     config,
  //   );
  // }
  static postAdminUploadImage(params) {
    return axios.post(`${starbookApi}/admin/merch/upload-image?type=${params.type}`, params.data, {
      headers: {
        'Content-Type': `application/octet-stream`,
      },
      withCredentials: true,
      credentials: 'same-origin',
      onUploadProgress: (ProgressEvent) => params.progressFunc(ProgressEvent),
    });
  }
  static postAdminCreateMerch(params) {
    return axios.post(`${starbookApi}/admin/merch/create`, { ...params }, requestConfig);
  }
  static postAdminUpdateMerch(params) {
    return axios.post(
      `${starbookApi}/admin/merch/${params.id}/query`,
      { ...params },
      requestConfig,
    );
  }
  static postAdminDeleteMerch(params) {
    return axios.post(
      `${starbookApi}/admin/merch/${params.id}/delete`,
      { ...params },
      requestConfig,
    );
  }
  static getAdminOrderHistory(params) {
    return axios.post(`${starbookApi}/admin/merch/purchases/query`, { ...params }, requestConfig);
  }
  static postAdminUpdateOrderHistory(params) {
    return axios.post(`${starbookApi}/admin/merch/purchases/update`, { ...params }, requestConfig);
  }
}
