import { /* takeLatest, */ all } from 'redux-saga/effects';
import { loginWatcher /* deAuthenticate */ } from './login.sagas';
import { watchTimesheetFetch } from './timesheet.saga';
import { watchProfileRequest, watchSetQueryRequest } from './work-details.saga';
// import { watchApprovePost } from './timesheet-admin/approve.saga';
import {
  watchFetchDefaultDays,
  watchPostDefaultDay,
} from './timesheet-admin/notification-days.saga';
import {
  watchPostSpecialMonth,
  watchFetchSpecialMonths,
  watchDeleteSpecialMonth,
} from './timesheet-admin/special-months.saga';
import { watchFetchTeamleadData } from './timesheet-admin/teamlead-data.saga';
// import { watchRejectSaga } from './timesheet-admin/reject.saga';
import { watchGetCron } from './cron/get-cron.sagas';
import { watchSetCron } from './cron/set-cron.sagas';
// import { watchRevokeSaga } from './timesheet-admin/revoke.saga';
import {
  watchGetBankHolidaysSaga,
  watchRemoveBankHoliday,
} from './timesheet-admin/bank-holidays.saga';
import {
  watchGetWorkHoursSaga,
  watchPostWorkHoursSaga,
  watchDeleteWorkHoursSaga,
} from './timesheet-admin/work-hours.saga';
import { watchGetStarbookDataSaga } from './starbook/starbook.saga';
import { watchgetQueryDataSaga } from './starbook/query-data.saga';
import {
  watchFetchSkills,
  watchCreateSkillCategory,
  watchCreateSkillSet,
  watchCountDelete,
  watchDeleteConfirm,
} from './starbook/skills.saga';
import { watchGetDepartmentsData, watchSetDepartmentsData } from './starbook/departments.saga';
import {
  watchGetUserDetails,
  watchUpdateBasicUserMeta,
  watchSetPermissions,
  watchSetLocation,
} from './starbook/user.saga';
import {
  watchFetchStarshopData,
  watchFetchStarshopOrderHistory,
  watchPostStarshopPurchase,
  watchFetchStarshopAdminOrderHistory,
  watchPostStarshopAdminUpdateOrder,
  watchCreateStarshopAdminMerch,
  watchDeleteStarshopAdminMerch,
  watchUpdateStarshopAdminMerch,
} from './starshop.saga';

import { watchCvLanguageUpdate, watchCvMetaUpdate } from './cv/cv.sagas';
import { watchCvEducationsUpdate } from './cv/cv-education.sagas';
import { watchCvCertificatesUpdate } from './cv/cv-certificates.sagas';
import { watchCvExperiencesUpdate } from './cv/cv-experiences.sagas';
import { watchCvSkillsUpdate } from './cv/cv-skills.sagas';
// import { DEAUTHENTICATE } from './../store/constants';

import { watchSetSearchParams } from './starbook/search.saga';
// rsr

import { requestsSagas } from './../store/store';

// end rsr

// import { watchFetchExpenseSheetAdmin } from './expense/expenseAdmin.sagas';
import {
  watchFetchActiveExpenseSheets,
  watchFetchExpenseSheetAdmin,
  watchCreateExpenseSheet,
  watchUpdateExpenseSheet,
} from './expense/expenseSheets.sagas';
import { watchCreateExpense, watchUpdateExpense } from './expense/expenseCreate.saga';
import {
  watchFetchExpenseRows,
  watchDeleteExpenseRow,
  watchDeleteExpenseRowAttachment,
} from './expense/expenseRows.saga';
import { watchUpdateExpenseSeheetMetadata } from './expense/expenseSheetMetadata.saga';
import { watchUploadFile } from './file-upload.saga';
import { watchFetchExpenseSheetInvoices } from './expense/expenseSheetInvoices.saga';
import {
  watchCreateExpenseSheetInvoice,
  watchDeleteExpenseSheetInvoice,
  watchUpdateExpenseSheetInvoice,
  watchDeletetInvoiceAttachment,
} from './expense/expenseSheetInvoice.saga';

import { watchFetchNews } from './news/news.sagas';
import {
  watchFetchLocation,
  watchPostLocation,
  watchDeleteLocation,
  watchUpdateLocation,
} from './location-manager/location-manager.sagas';

import {
  watchTimesheetHandle,
  watchPollTask,
  watchTimesheetCleanup,
} from './timesheet-admin/timesheet-handler.saga';
import {
  watchGetPESOHistorySaga,
  watchGetPESOUserHistorySaga,
  watchPostPesoGiveSaga,
  watchPostPesoTakeSaga,
  watchPostPesoTransferSaga,
} from './starbook/peso.saga';

function* rootSaga() {
  yield all([
    loginWatcher(),
    watchTimesheetFetch(),
    watchProfileRequest(),
    //watchApprovePost(),
    watchFetchDefaultDays(),
    watchPostDefaultDay(),
    watchPostSpecialMonth(),
    watchFetchSpecialMonths(),
    watchDeleteSpecialMonth(),
    watchFetchTeamleadData(),
    // watchRejectSaga(),
    watchGetCron(),
    watchSetCron(),
    // watchRevokeSaga(),
    watchGetBankHolidaysSaga(),
    watchRemoveBankHoliday(),
    watchGetWorkHoursSaga(),
    watchPostWorkHoursSaga(),
    watchDeleteWorkHoursSaga(),
    watchGetStarbookDataSaga(),
    watchGetPESOHistorySaga(),
    watchGetPESOUserHistorySaga(),
    watchPostPesoTransferSaga(),
    watchPostPesoGiveSaga(),
    watchPostPesoTakeSaga(),

    watchFetchStarshopData(),
    watchFetchStarshopOrderHistory(),
    watchPostStarshopPurchase(),
    watchFetchStarshopAdminOrderHistory(),
    watchPostStarshopAdminUpdateOrder(),
    watchCreateStarshopAdminMerch(),
    watchDeleteStarshopAdminMerch(),
    watchUpdateStarshopAdminMerch(),

    watchgetQueryDataSaga(),
    watchFetchSkills(),
    watchGetDepartmentsData(),
    watchSetDepartmentsData(),
    watchCreateSkillCategory(),
    watchCreateSkillSet(),
    watchCountDelete(),
    watchDeleteConfirm(),
    watchGetUserDetails(),
    watchUpdateBasicUserMeta(),
    // watchUploadPicture(),
    watchCvMetaUpdate(),
    watchCvLanguageUpdate(),
    watchCvEducationsUpdate(),
    watchCvCertificatesUpdate(),
    watchCvExperiencesUpdate(),
    watchCvSkillsUpdate(),
    watchSetQueryRequest(),
    watchSetSearchParams(),
    watchFetchExpenseSheetAdmin(),
    watchFetchActiveExpenseSheets(),
    watchCreateExpense(),
    watchFetchExpenseRows(),
    watchUploadFile(),
    watchUpdateExpense(),
    watchDeleteExpenseRow(),
    watchUpdateExpenseSeheetMetadata(),
    watchFetchExpenseSheetInvoices(),
    watchCreateExpenseSheetInvoice(),
    watchDeleteExpenseSheetInvoice(),
    watchUpdateExpenseSheetInvoice(),
    watchDeletetInvoiceAttachment(),
    watchDeleteExpenseRowAttachment(),
    // ramda and more general
    watchCreateExpenseSheet(),
    watchFetchNews(),
    watchSetPermissions(),
    watchUpdateExpenseSheet(),
    watchTimesheetHandle(),
    watchPollTask(),
    watchFetchLocation(),
    watchPostLocation(),
    watchDeleteLocation(),
    watchUpdateLocation(),
    watchSetLocation(),
    watchTimesheetCleanup(),
    // rsr
    requestsSagas,
    // takeLatest(DEAUTHENTICATE, deAuthenticate)
  ]);
}

export default rootSaga;
