import {
  PROFILE_FETCH,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_ERROR,
  SET_PROFILE_QUERY,
  CLEAR_PROFILE_QUERY,
} from './../store/constants';

const doFetchProfile = payload => ({
  type: PROFILE_FETCH,
  payload,
});

const doFetchProfileSuccess = payload => ({
  type: PROFILE_FETCH_SUCCESS,
  payload,
});

const doFetchProfileError = payload => ({
  type: PROFILE_FETCH_ERROR,
  payload,
});

const doSetProfileQuery = payload => ({
  type: SET_PROFILE_QUERY,
  payload,
});

const doClearProfileQuery = () => ({
  type: CLEAR_PROFILE_QUERY,
});

export {
  doFetchProfile,
  doSetProfileQuery,
  doClearProfileQuery,
  doFetchProfileSuccess,
  doFetchProfileError,
};
