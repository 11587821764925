import { success, error } from './../common/helpers/constanHelpers';
import { createSimpleAction } from './actionCreator';
import {
  GET_USER_DETAILS,
  UPDATE_USER_BASIC_META,
  CLEAR_USER,
  UPLOAD_PROFILE_PICTURE,
  SET_PERMISSIONS,
} from '../store/constants';

const doGetUserDetails = payload => ({
  type: GET_USER_DETAILS,
  payload,
});

const doGetUserDetailsSuccess = payload => ({
  type: success(GET_USER_DETAILS),
  payload,
});

const doGetUserDetailsError = payload => ({
  type: error(GET_USER_DETAILS),
  payload,
});

const doUpdateBasicUserMeta = payload => ({
  type: UPDATE_USER_BASIC_META,
  payload,
});

const doUpdateBasicUserMetaSuccess = payload => ({
  type: success(UPDATE_USER_BASIC_META),
  payload,
});

const doUpdateBasicUserMetaError = payload => ({
  type: error(UPDATE_USER_BASIC_META),
  payload,
});

const doUploadProfilePictureSuccess = payload => ({
  type: success(UPLOAD_PROFILE_PICTURE),
  payload,
});

const doUploadProfilePictureError = payload => ({
  type: error(UPLOAD_PROFILE_PICTURE),
  payload,
});

const doClearUser = () => ({
  type: CLEAR_USER,
});

const doSetPermissions = createSimpleAction(SET_PERMISSIONS);
const doSetPermissionsSuccess = createSimpleAction(success(SET_PERMISSIONS));
const doSetPermissionsError = createSimpleAction(error(SET_PERMISSIONS));

export {
  doGetUserDetails,
  doGetUserDetailsSuccess,
  doGetUserDetailsError,
  doUpdateBasicUserMeta,
  doUpdateBasicUserMetaSuccess,
  doUpdateBasicUserMetaError,
  doUploadProfilePictureSuccess,
  doUploadProfilePictureError,
  doClearUser,
  doSetPermissions,
  doSetPermissionsSuccess,
  doSetPermissionsError,
};
