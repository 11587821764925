import { call, put, all } from 'redux-saga/effects';

import { doShowMessage } from '../../actions/message';

export function* postWorker(action) {
  const {
    payload,
    meta: { successAction, errorAction, endPoint, successMessage },
  } = action;

  try {
    const { data } = yield call(endPoint, payload);

    yield all([
      put(successAction(data)),
      put(
        doShowMessage({
          type: 'success',
          content: successMessage,
        }),
      ),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}
