import * as R from 'ramda';
import { success, error } from './../common/helpers/constanHelpers';
import createReducer from './reducer-helpers/createReducer';
import { setLoading, setError, setSimpleError } from './reducer-helpers/reducerHelpers';

import {
  GET_USER_DETAILS,
  UPDATE_USER_BASIC_META,
  UPLOAD_PROFILE_PICTURE,
  CLEAR_USER,
  SET_PERMISSIONS,
  SET_LOCATION,
} from '../store/constants';

const INITIAL_STATE = {
  error: false,
  loading: false,
  success: false,
  data: {},
  updateError: false,
  updateLoading: false,
  updateSuccess: false,
};

const setUpdateLoading = (_) =>
  R.evolve({
    updateLoading: R.always(true),
    updateSuccess: R.always(false),
    updateError: R.always(false),
  });

const setUpdateError = (_) =>
  R.evolve({
    updateLoading: R.always(false),
    updateError: R.always(true),
  });

const setActionData = (action) =>
  R.evolve({
    loading: R.always(false),
    success: R.always(true),
    data: () => R.clone(action.payload),
  });

const setUpdateActionData = (action) =>
  R.evolve({
    updateLoading: R.always(false),
    updateSuccess: R.always(true),
    data: () => R.clone(action.payload),
  });

const setSimpleData = (action) =>
  R.evolve({
    data: () => R.clone(action.payload),
  });

const setInitalState = (_) => (_) => R.clone(INITIAL_STATE);

const userReducer = createReducer(INITIAL_STATE, {
  [GET_USER_DETAILS]: setLoading,
  [success(GET_USER_DETAILS)]: setActionData,
  [error(GET_USER_DETAILS)]: setError,
  [UPDATE_USER_BASIC_META]: setUpdateLoading,
  [success(UPDATE_USER_BASIC_META)]: setUpdateActionData,
  [error(UPDATE_USER_BASIC_META)]: setUpdateError,
  [success(UPLOAD_PROFILE_PICTURE)]: setSimpleData,
  [error(UPLOAD_PROFILE_PICTURE)]: setSimpleError,
  [CLEAR_USER]: setInitalState,
  [SET_PERMISSIONS]: setUpdateLoading,
  [success(SET_PERMISSIONS)]: setUpdateActionData,
  [error(SET_PERMISSIONS)]: setUpdateError,
  [SET_LOCATION]: setLoading,
  [success(SET_LOCATION)]: setActionData,
  [error(SET_LOCATION)]: setError,
});

export default userReducer;
