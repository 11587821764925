import { success, error } from './../common/helpers/constanHelpers';
import { createSimpleAction } from './actionCreator';

import { FETCH_NEWS } from './../store/constants';

const doFetchNews = createSimpleAction(FETCH_NEWS);

const doFetchNewsSuccess = createSimpleAction(success(FETCH_NEWS));

const doFetchNewsError = createSimpleAction(error(FETCH_NEWS));

export { doFetchNews, doFetchNewsSuccess, doFetchNewsError };
