import { success, error } from './../common/helpers/constanHelpers';
import { createSimpleAction, createComplexAction } from './actionCreator';

import {
  UPDATE_CV_LANGUAGES,
  UPDATE_CV_EDUCATIONS,
  UPDATE_CV_CERTIFICATES,
  UPDATE_CV_EXPERIENCES,
  UPDATE_CV_SKILLS,
  UPDATE_CV_META,
} from './../store/constants';

const doUpdateCvLanguages = (payload) => ({
  type: UPDATE_CV_LANGUAGES,
  payload,
});

const doUpdateCvLanguagesSuccess = (payload) => ({
  type: success(UPDATE_CV_LANGUAGES),
  payload,
});

const doUpdateCvLanguagesError = (payload) => ({
  type: error(UPDATE_CV_LANGUAGES),
  payload,
});

const doUpdateCvEducations = (payload) => ({
  type: UPDATE_CV_EDUCATIONS,
  payload,
});

const doUpdateCvEducationsSuccess = (payload) => ({
  type: success(UPDATE_CV_EDUCATIONS),
  payload,
});

const doUpdateCvEducationsError = (payload) => ({
  type: error(UPDATE_CV_EDUCATIONS),
  payload,
});

const doUpdateCvCertifications = (payload) => ({
  type: UPDATE_CV_CERTIFICATES,
  payload,
});

const doUpdateCvCertificationsSuccess = (payload) => ({
  type: success(UPDATE_CV_CERTIFICATES),
  payload,
});

const doUpdateCvCertificationsError = (payload) => ({
  type: error(UPDATE_CV_CERTIFICATES),
  payload,
});

const doUpdateCvExperiences = (payload) => ({
  type: UPDATE_CV_EXPERIENCES,
  payload,
});

const doUpdateCvExperiencesSuccess = (payload) => ({
  type: success(UPDATE_CV_EXPERIENCES),
  payload,
});

const doUpdateCvExperiencesError = (payload) => ({
  type: error(UPDATE_CV_EXPERIENCES),
  payload,
});

const doUpdateCvSkills = (payload) => ({
  type: UPDATE_CV_SKILLS,
  payload,
});

const doUpdateCvSkillsSuccess = (payload) => ({
  type: success(UPDATE_CV_SKILLS),
  payload,
});

const doUpdateCvSkillsError = (payload) => ({
  type: error(UPDATE_CV_SKILLS),
  payload,
});

const doUpdateCvMeta = createComplexAction(UPDATE_CV_META);
const doUpdateCvMetaSuccess = createSimpleAction(success(UPDATE_CV_META));
const doUpdateCvMetaError = createSimpleAction(error(UPDATE_CV_META));

export {
  doUpdateCvLanguages,
  doUpdateCvLanguagesSuccess,
  doUpdateCvLanguagesError,
  doUpdateCvEducations,
  doUpdateCvEducationsSuccess,
  doUpdateCvEducationsError,
  doUpdateCvCertifications,
  doUpdateCvCertificationsSuccess,
  doUpdateCvCertificationsError,
  doUpdateCvExperiences,
  doUpdateCvExperiencesSuccess,
  doUpdateCvExperiencesError,
  doUpdateCvSkills,
  doUpdateCvSkillsSuccess,
  doUpdateCvSkillsError,
  doUpdateCvMeta,
  doUpdateCvMetaSuccess,
  doUpdateCvMetaError,
};
