import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './TimesheetStatusItem.module.sass';

import { APPROVE, REJECT, REVOKE } from './../../../common/timesheet-jobs';

const verbs = {
  [APPROVE]: ['approval', 'approved'],
  [REJECT]: ['rejecting', 'rejected'],
  [REVOKE]: ['revoking', 'revoked'],
};

const TimesheetStatusItem = ({ item }) => {
  return (
    <div className={styles.statusItem}>
      {item.status === 'in progress' && (
        <>
          {item.name} {verbs[item.job][0]} in progress{' '}
          <FontAwesomeIcon icon="spinner" pulse={true} className="iconSpacer" />
        </>
      )}
      {item.status === 'error' && (
        <>
          {item.name} {verbs[item.job][0]} failed{' '}
          <FontAwesomeIcon icon="times" className="iconSpacer" />
          {item.message && <p className={styles.errorMessage}>{item.message}</p>}
        </>
      )}

      {item.status === 'finished' && (
        <>
          {item.name} {verbs[item.job][1]} <FontAwesomeIcon icon="check" className="iconSpacer" />
        </>
      )}
    </div>
  );
};

TimesheetStatusItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TimesheetStatusItem;
