import { call, put, takeEvery, select } from 'redux-saga/effects';
import { 
    FETCH_TEAMLEAD_DATA,
    FETCH_TEAMLEAD_DATA_SUCCESS,
    FETCH_TEAMLEAD_DATA_ERROR
 } from './../../store/constants';
import { loadTeamleadData as getTeamleadData } from './../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* fetchTeamleadData(action) {
    try {
        const token = yield select(getLoginState);

        const teamleadData = yield call(getTeamleadData, {...action.payload, token});

        yield put({
            type: FETCH_TEAMLEAD_DATA_SUCCESS,
            payload: teamleadData.data
        });
    }
    catch (error) {
        yield put({
            type: FETCH_TEAMLEAD_DATA_ERROR,
            payload: error
        })
    }
}


export function* watchFetchTeamleadData() {
    yield takeEvery(FETCH_TEAMLEAD_DATA, fetchTeamleadData); 
}