import { call, put, takeEvery, fork, select } from 'redux-saga/effects';
import { TIMESHEET_FETCH, TIMESHEET_FETCH_SUCCESS, TIMESHEET_FETCH_ERROR, SET_DATE } from './../store/constants';
import { loadTimesheetForMonth as getTimesheet } from './apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* fetchTimesheets(action) {
    try {
        yield fork(setDates, action);
        const token = yield select(getLoginState);

        const timesheets = yield call(getTimesheet, {...action.payload, token});

        yield put({
            type: TIMESHEET_FETCH_SUCCESS,
            payload: timesheets.data
        });
    }
    catch (error) {
        yield put({
            type: TIMESHEET_FETCH_ERROR,
            payload: error
        })
    }
}

export function* setDates(action) {
    yield put({
        type: SET_DATE,
        payload: action.payload
    });
}

export function* watchTimesheetFetch() {
    yield takeEvery(TIMESHEET_FETCH, fetchTimesheets); 
}