import * as R from 'ramda';

const createAction = R.curry((type, getPayload, getMeta) =>
  R.compose(
    R.reject(R.isNil),
    R.applySpec({
      type: R.always(type),
      payload: getPayload,
      meta: getMeta,
    }),
  ),
);
const createComplexAction = createAction(R.__, R.prop('payload'), R.prop('meta'));
const createSimpleAction = createAction(R.__, R.identity, R.always(null));
const createConstantAction = createAction(R.__, R.always(null), R.always(null));

export { createComplexAction, createSimpleAction, createConstantAction };
