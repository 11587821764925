import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './MultiInput.module.sass';

const MultiInput = ({
  onToggleList,
  onSearch,
  onClear,
  onRemoveItem,
  placeholder,
  selected,
  listShow,
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(() => '');

  const showList = () => onToggleList(true);
  const toggleList = () => {
    if (!listShow) {
      inputRef.current.focus();
    }
    onToggleList(!listShow);
  };

  const search = e => {
    const term = e.target.value;
    setValue(() => term);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(value);
    }, 300);

    return () => clearTimeout(timer);
  }, [value, onSearch]);

  const clearValue = () => {
    onClear();
  };

  const deleteSelectedItem = id => {
    onRemoveItem(id);
  };

  useEffect(() => {
    setValue(() => '');
  }, [selected]);

  return (
    <div className={styles.multiInput}>
      <div className={styles.inputHolder}>
        {selected.length > 0 && (
          <div className={`tags ${styles.tagContainer}`}>
            {selected.map((i, index) => (
              <div key={index} className="tag">
                {i.label}
                <button
                  type="button"
                  className={`delete is-small ${styles.customDelete}`}
                  onClick={() => deleteSelectedItem(i.id)}
                ></button>
              </div>
            ))}
          </div>
        )}

        <input
          className={styles.selectInput}
          type="text"
          onClick={showList}
          placeholder={placeholder}
          value={value}
          onChange={search}
          ref={inputRef}
        />
      </div>
      <div className={styles.iconHolder}>
        {selected.length > 0 && (
          <span className={`${styles.selectIcon}`} onClick={clearValue}>
            <FontAwesomeIcon icon="times" />
          </span>
        )}
        <div className={`${styles.selectIcon} ${styles.dropdownicon}`} onClick={toggleList}>
          {listShow ? (
            <FontAwesomeIcon icon="chevron-up" />
          ) : (
            <FontAwesomeIcon icon="chevron-down" />
          )}
        </div>
      </div>
    </div>
  );
};

MultiInput.propTypes = {
  onToggleList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
};

export default MultiInput;
