import { put, takeLatest, select } from 'redux-saga/effects';

import { SET_SEARCH_PARAMS } from './../../store/constants';

import { doFetchStarbookData } from './../../actions/starbook';

import { getSearchParams } from './../../selectors/starbook.seletors';

import { DESC } from './../../common/constant-values';

const orderByDesc = (direction) => {
  return direction === DESC ? true : false;
};

export function* setSearchParamsSaga(action) {
  const searchParams = yield select(getSearchParams);

  const orderAndDirection = searchParams.orderBy ? searchParams.orderBy.split('_') : [];

  const [orderBy, direction] = orderAndDirection;

  const searchPayload = {
    ...searchParams,
  };

  delete searchPayload.orderBy;

  if (orderBy && direction) {
    searchPayload.orderBy = orderBy;
    searchPayload.orderByDesc = orderByDesc(direction);
  }

  yield put(doFetchStarbookData(searchPayload));
}

export function* watchSetSearchParams() {
  yield takeLatest(SET_SEARCH_PARAMS, setSearchParamsSaga);
}
