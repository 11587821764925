// Application permission strings

const PUBLIC = 'public';
const ADMIN = 'admin';
const CV_ADMIN = 'cvAdmin';
const DEPARTMENT_ADMIN = 'departmentAdmin';
const SKILL_ADMIN = 'skillAdmin';
const MERCH_ADMIN = 'merchAdmin';
const MASSAGE_ADMIN = 'massageAdmin';
const PERMISSION_ADMIN = 'permissionAdmin';
const TRAINING_ADMIN = 'trainingAdmin';
const STARBOOK_ADMIN = 'starbookAdmin';
const TS_REVIEW_ADMIN = 'tsReviewAdmin';
const EXPENSE_SHEET_READER = 'expenseSheetsReader';
const EXPENSE_SHEET_ADMIN = 'expenseSheetsAdmin';

export {
  PUBLIC,
  ADMIN,
  DEPARTMENT_ADMIN,
  SKILL_ADMIN,
  MERCH_ADMIN,
  PERMISSION_ADMIN,
  TRAINING_ADMIN,
  STARBOOK_ADMIN,
  TS_REVIEW_ADMIN,
  EXPENSE_SHEET_ADMIN,
  EXPENSE_SHEET_READER,
  CV_ADMIN,
  MASSAGE_ADMIN,
};
