import { 
    GET_BANK_HOLIDAYS,
    GET_BANK_HOLIDAYS_ERROR,
    GET_BANK_HOLIDAYS_SUCCESS,
    POST_BANK_HOLIDAYS,
    POST_BANK_HOLIDAYS_SUCCESS,
    POST_BANK_HOLIDAYS_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    list: {},
    updated: false
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case POST_BANK_HOLIDAYS:
        case GET_BANK_HOLIDAYS:
            return {...state, loading: true, error: false, updated: false };
        case GET_BANK_HOLIDAYS_SUCCESS:
            return {...state, loading: false, list: { ...action.payload }};
        case POST_BANK_HOLIDAYS_ERROR:
        case GET_BANK_HOLIDAYS_ERROR: 
            return {...state, loading: false, error: action.payload}
        case POST_BANK_HOLIDAYS_SUCCESS: 
            return {...state, updated: true }
        default: 
            return state;
    }
}

export default reducer;