import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
    GET_WORK_HOURS,
    GET_WORK_HOURS_SUCCESS,
    GET_WORK_HOURS_ERROR,
    POST_WORK_HOURS,
    POST_WORK_HOURS_SUCCESS,
    POST_WORK_HOURS_ERROR,
    DELETE_WORK_HOURS,
    DELETE_WORK_HOURS_ERROR,
    DELETE_WORK_HOURS_SUCCESS,
    SHOW_MESSAGE
} from '../../store/constants';

import { getWorkHours, postWorkHours, deleteWorkHours } from '../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* getWorkHoursSaga(action) {
    try {
        const token = yield select(getLoginState);
        const bankHolidays = yield call(getWorkHours, {...action.payload, token});
        
        const workHoursData = Object.keys(bankHolidays.data).map(key => ({
                year: key,
                list: bankHolidays.data[key]
        }));
        yield all([
            put({
                type: GET_WORK_HOURS_SUCCESS,
                payload: workHoursData
            })       
        ]);
    }
    catch(error) {
        console.log(error);
        yield all([
            put({
                type: GET_WORK_HOURS_ERROR,
                payload: error
            }),
            
        ])
    }
}

export function* postWorkHoursSaga(action) {
    try {
        const token = yield select(getLoginState);

        const updateResult = yield call(postWorkHours, {data: action.payload, token});

        yield all([
            put({
                type: GET_WORK_HOURS
            }),
            put({
                type: POST_WORK_HOURS_SUCCESS,
                payload: updateResult.data
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'success',
                    content: 'Work hours updated.'
                }
            })
        ])

    }
    catch(error) {
        console.log(error);
        yield put({
            type: POST_WORK_HOURS_ERROR,
            payload: error
        });
    }
}

export function* deleteWorkHoursSaga(action) {
    try {
        const token = yield select(getLoginState);

        const deleteResult = yield call(deleteWorkHours, {data: action.payload, token});

        yield all([
            put({
                type: GET_WORK_HOURS
            }),
            put({
                type: DELETE_WORK_HOURS_SUCCESS,
                payload: deleteResult.data
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'success',
                    content: 'Year settings deleted.'
                }
            })
        ])
    }
    catch(error) {
        console.error(error);
        yield put({
            type: DELETE_WORK_HOURS_ERROR,
            payload: error
        });
    }
}

export function* watchGetWorkHoursSaga() {
    yield takeLatest(GET_WORK_HOURS, getWorkHoursSaga); 
}

export function* watchPostWorkHoursSaga() {
    yield takeLatest(POST_WORK_HOURS, postWorkHoursSaga);
}

export function* watchDeleteWorkHoursSaga() {
    yield takeLatest(DELETE_WORK_HOURS, deleteWorkHoursSaga);
}