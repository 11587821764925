import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bulma-components';
import { Field, Form, Formik } from 'formik';

import TextareaField from '../../../common/textarea-field/TextareaField';

import styles from './CommentModal.module.scss';
import { useDispatch } from 'react-redux';
import { doCloseModal } from '../../../actions/modal';
import { MASSAGE_NOTE_MODAL } from '../../../common/modalTypes';

const CommentModal = ({ data, onConfirm }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const initialValues = {
    comment: '',
  };

  useEffect(() => setComment(data.comment), [data.comment]);

  const onSubmit = () => {
    onConfirm(comment);
  };

  return (
    <Modal.Card className={styles.modal}>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Comment</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <div className="field">
              <Field
                name="comment"
                component={TextareaField}
                label="Add a comment to the masseur"
                placeholder="Add a comment here"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className="buttons is-right">
              <button
                className={`button`}
                disabled={data.loading}
                type="button"
                onClick={() => dispatch(doCloseModal({ type: MASSAGE_NOTE_MODAL }))}
              >
                Cancel
              </button>
              <button className={`button is-primary`} type="submit">
                Save
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

CommentModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default CommentModal;
