import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getEmployees } from './../starbook.seletors';

const statusMapper = (employees) => (item) => {
  const employee = R.find(R.propEq('value', R.path(['maconomyId'], item)))(employees);
  return {
    maconomyId: R.path(['maconomyId'], item),
    status: R.path(['status'], item),
    job: R.path(['job'], item),
    name: R.path(['label'], employee),
    message: R.path(['message'], item),
  };
};

const timesheetHandlerState = ({ timesheetHandler }) => timesheetHandler;

export const getTimesheetHandlerLoading = createSelector(
  [timesheetHandlerState],
  R.path(['loading']),
);

export const getTimesheetHandlerError = createSelector([timesheetHandlerState], R.path(['error']));

export const getRawStatusList = createSelector([timesheetHandlerState], R.path(['statusList']));

export const getStatusList = createSelector(
  [timesheetHandlerState, getEmployees],
  (state, employees) => {
    return R.map(statusMapper(employees), R.path(['statusList'], state));
  },
);

export const shouldCleanUp = createSelector([timesheetHandlerState], (timesheetHandlerState) => {
  const originalLength = R.length(R.path(['statusList'], timesheetHandlerState));
  const finishedtasks = R.compose(
    R.length,
    R.filter(R.propEq('status', 'finished')),
    R.path(['statusList']),
  )(timesheetHandlerState);
  return originalLength === finishedtasks && originalLength > 0;
});
