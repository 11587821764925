import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// remove Google Tag Manager as it conflicts with HCL CSP header requirements
// import TagManager from 'react-gtm-module';
import { DEAUTHENTICATE } from '../../store/constants';

import { starbookApi } from '../../api/api-config';

import { doIsAuthenticated } from '../../actions/app';

import './App.sass';

import Header from './../header/Header';
import RouteContainer from '../route-container/RouteContainer';
import Message from './../message/Message';
import ModalContainer from './..//modal-container/ModalContainer';
import AppLoader from './../../common/app-loader/AppLoader';
import TimesheetStatus from './../timesheet-review/timesheet-status/TimesheetStatus';

// remove Google Tag Manager as it conflicts with HCL CSP header requirements
/*if (process.env.NODE_ENV !== `development`) {
  const tagManagerArgs = {
    gtmId: 'G-15KWMZ0RNH',
  };

  TagManager.initialize(tagManagerArgs);
}*/

const App = (props) => {
  const dispatch = useDispatch();
  const onCheckAuth = () => dispatch(doIsAuthenticated());
  const onDeauth = () => dispatch({ type: DEAUTHENTICATE });

  const { authenticated, user, permissions, loading, fetched } = useSelector(
    (state) => state.login,
  );

  useEffect(() => {
    onCheckAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <AppLoader />;
  }

  if (!authenticated && fetched) {
    window.location.replace(
      `${starbookApi}/account/oauth?type=Microsoft&currentUrl=${window.location.pathname}`,
    );
  }

  const content = authenticated ? (
    <React.Fragment>
      <Header onLogout={onDeauth} user={user} permissions={permissions} history={props.history} />
      <Message />
      <RouteContainer />
      <ModalContainer />
      <TimesheetStatus />
    </React.Fragment>
  ) : (
    <div className="notauth">Logging you in</div>
  );

  return <div className="App">{content}</div>;
};

export default App;
