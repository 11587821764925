import axios from 'axios';

import { apiUrl } from './api-config';

const requestConfig = {
    withCredentials: true,
    credentials: 'same-origin'
};

class MaconomyApi {
    static getUser(params) {
        return axios.post(`${apiUrl}/maconomy/user`, JSON.stringify({}, null, 2), requestConfig);
    }

    static getTimeSheetForUser(params) {
        const config = {
            ...requestConfig,
            headers: { Authorization: `Bearer ${params.token}` },
            params: {
                year: params.year,
                month: params.month,
                all: params.all,
                allDum: params.allDum
            }
        }
        return axios.post(`${apiUrl}/maconomy/user/timesheet`, JSON.stringify({}, null, 2), config);
    }

    static getProfile(params) {
        const config = {
            ...requestConfig,
            headers: { Authorization: `Bearer ${params.token}` },
            params: {
                year: params.data.year,
                month: params.data.month,
                maconomy: params.data.maconomyId
            }
        }
        return axios.post(`${apiUrl}/maconomy/user/profile`, JSON.stringify({}, null, 2), config);
    }
}

export default MaconomyApi;