import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  FETCH_DEPARTMENTS,
  SET_DEPARTMENTS,
  SHOW_MESSAGE,
} from './../../store/constants';

import {
  doFetchDepartmentsSuccess,
  doFetchDepartmentsError,
  doSetDepartmentsSuccess,
  doSetDepartmentsError,
} from './../../actions/departments';

import {
  fetchDepartments,
  setDepartments,
} from './../starbookApiCalls';

export function* getDepartmentsData(action) {
  try {
    const result = yield call(fetchDepartments, action.payload);

    yield all([put(doFetchDepartmentsSuccess(result.data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchDepartmentsError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* setDepartmentsData(action) {
  try {
    const result = yield call(setDepartments, action.payload);
    console.log(result.data);
    yield all([
      put(doSetDepartmentsSuccess(result.data)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Unit edit successfull',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doSetDepartmentsError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* watchGetDepartmentsData() {
  yield takeLatest(FETCH_DEPARTMENTS, getDepartmentsData);
}

export function* watchSetDepartmentsData() {
  yield takeLatest(SET_DEPARTMENTS, setDepartmentsData);
}
