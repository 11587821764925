import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import {
  FETCH_SKILLS,
  CREATE_SKILL_CATEGORY,
  CREATE_SKILLSET,
  SHOW_MESSAGE,
  DELETE_START,
  CONFIRM_DELETE,
} from './../../store/constants';

import {
  doFetchSkills,
  doFetchSkillsSuccess,
  doFetchSkillsError,
  doCreateSkillCategorySuccess,
  doCreateSkillCategoryError,
  doCreateSkillsetSuccess,
  doCreateSkillsetError,
  doStartDeleteSuccess,
  doStartDeleteError,
  doConfirmDeleteSuccess,
  doConfirmDeleteError,
} from './../../actions/skills';

import { doOpenModal, doCloseModal } from './../../actions/modal';

import {
  getSkills,
  createSkillCategory,
  createSkillSubCategory,
  createSkillset,
  countForDelete,
  deleteItem,
} from './../starbookApiCalls';

import * as R from 'ramda';

const { ifElse, when, head } = R;

const hasParent = (array) => (head(array).skillsetCategoryId ? true : false);

const hasItems = (array) => array.length > 0;

const chooseApiCall = ifElse(
  hasParent,
  (data) => createSkillSubCategory(data),
  (data) => createSkillCategory(data),
);

const chooseApiCallWhenNotEmpty = when(hasItems, chooseApiCall);

export function* getFetchSkillsSaga(action) {
  try {
    const result = yield call(getSkills);

    yield all([put(doFetchSkillsSuccess(result.data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchSkillsError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* createSkillCategorySaga(action) {
  try {
    const parsedData = action.payload.categories.map((item) => ({
      name: item.name,
      description: item.description,
      skillsetCategoryId: parseInt(item.parent),
    }));

    const subCat = parsedData.filter(
      (item) => item.hasOwnProperty('skillsetCategoryId') && item.skillsetCategoryId,
    );
    const cat = parsedData.filter((item) => !item.skillsetCategoryId);

    const results = yield all([
      call(chooseApiCallWhenNotEmpty, subCat),
      call(chooseApiCallWhenNotEmpty, cat),
    ]);

    const data = results.map((result) => result.data).filter((item) => item && item.length > 0);

    yield all([
      put(doFetchSkills()),
      put(doCreateSkillCategorySuccess(data)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Skill category successfully created',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doCreateSkillCategoryError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* createSkillSetSaga(action) {
  try {
    const result = yield call(createSkillset, action.payload);

    yield all([
      // put(doFetchSkills()),
      put(doCreateSkillsetSuccess(result.data)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Skillset successfully created',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doCreateSkillsetError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* startDelete(action) {
  try {
    const count = yield call(countForDelete, action.payload);

    const modalContent = {
      type: 'delete',
    };

    yield all([put(doStartDeleteSuccess(count.data)), put(doOpenModal(modalContent))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doStartDeleteError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* confirmDeleteSaga(action) {
  try {
    const state = yield select();

    const dataToDelete = {
      id: state.skills.deleteId,
      type: state.skills.deleteType,
    };

    const response = yield call(deleteItem, dataToDelete);

    yield all([put(doConfirmDeleteSuccess(response.data)), put(doCloseModal())]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doConfirmDeleteError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* watchFetchSkills() {
  yield takeLatest(FETCH_SKILLS, getFetchSkillsSaga);
}

export function* watchCreateSkillCategory() {
  yield takeLatest(CREATE_SKILL_CATEGORY, createSkillCategorySaga);
}

export function* watchCreateSkillSet() {
  yield takeLatest(CREATE_SKILLSET, createSkillSetSaga);
}

export function* watchCountDelete() {
  yield takeLatest(DELETE_START, startDelete);
}

export function* watchDeleteConfirm() {
  yield takeLatest(CONFIRM_DELETE, confirmDeleteSaga);
}
