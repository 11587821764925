// Application route strings

const HOME = '/';
const TIMESHEET_REVIEW = '/timesheet-review';
const TIMESHEET_SETTINGS = '/settings';
const WORKDETAILS_PARAMS = '/timesheet-review/workdetails/:maconomyId/:year/:month';
const WORKDETAILS = '/timesheet-review/workdetails';
const PROFILE = '/profile';
const PROFILE_PARAMS = '/profile/:type/:userId';
const SKILLS = '/starbook/skills';
const STARSHOP = '/starshop';
const STARSHOP_ADMIN = '/starshop-admin';
const DELIVERY_UNITS = '/starbook/delivery-units';
const NEW_CV_PARAMS = '/starbook/cv/:userId/new';
const CV = '/starbook/cv';
const CV_PARAMS = '/starbook/cv/:userId';
const CV_EDIT_PARAMS = '/starbook/cv/:userId/:cvId/edit';
const STARBOOK = '/starbook';
const TRAINING = '/training';
const EXPENSE = '/expense';
const NEWS = '/news';
const LOCATION_MANAGER = '/location-manager';
const PESO_ADMIN = '/peso-admin';
const MASSAGE_ADMIN = '/massage-admin';
const MASSAGE_ADMIN_CONFIG_EDITOR = '/massage-admin/config-editor';
const MASSAGE = '/massage';

export {
  HOME,
  TIMESHEET_REVIEW,
  TIMESHEET_SETTINGS,
  WORKDETAILS_PARAMS,
  WORKDETAILS,
  PROFILE,
  PROFILE_PARAMS,
  SKILLS,
  STARSHOP,
  STARSHOP_ADMIN,
  DELIVERY_UNITS,
  NEW_CV_PARAMS,
  CV,
  CV_PARAMS,
  CV_EDIT_PARAMS,
  STARBOOK,
  TRAINING,
  EXPENSE,
  NEWS,
  LOCATION_MANAGER,
  PESO_ADMIN,
  MASSAGE_ADMIN,
  MASSAGE_ADMIN_CONFIG_EDITOR,
  MASSAGE,
};
