import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const generateButtons = array =>
  array.map((item, index) => (
    <button key={index} className="button is-medium" type="button" onClick={item.function}>
      {item.title}
    </button>
  ));

const PageContainer = ({ title, icon, buttonFunction, buttonText, buttons, center, children }) => {
  const fluidClass = center ? '' : 'is-fluid';
  return (
    <section className="section">
      <div className={`container ${fluidClass}`}>
        <div className="columns">
          <div className="column">
            <h1 className={'title has-text-left'}>
              <FontAwesomeIcon icon={icon} /> {title}
            </h1>
          </div>
          {buttonText && (
            <div className={'column is-one-third has-text-right'}>
              <button className="button is-medium" type="button" onClick={buttonFunction}>
                {buttonText}
              </button>
            </div>
          )}
          {buttons && (
            <div className={'column is-one-third has-text-right'}>
              <div className="buttons is-right">{generateButtons(buttons)}</div>
            </div>
          )}
        </div>

        <div className="columns">{children}</div>
      </div>
    </section>
  );
};

PageContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonFunction: PropTypes.func,
  buttonText: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export default PageContainer;
