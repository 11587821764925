import {
  FETCH_PESO_USER_HISTORY,
  FETCH_PESO_USER_HISTORY_SUCCESS,
  FETCH_PESO_USER_HISTORY_ERROR,
  POST_PESO_TRANSFER,
  POST_PESO_TRANSFER_ERROR,
  POST_PESO_TRANSFER_SUCCESS,
  POST_PESO_GIVE,
  POST_PESO_GIVE_ERROR,
  POST_PESO_GIVE_SUCCESS,
  POST_PESO_TAKE,
  POST_PESO_TAKE_ERROR,
  POST_PESO_TAKE_SUCCESS,
} from '../store/constants';

const doFetchPesoUserHistory = (payload) => ({
  type: FETCH_PESO_USER_HISTORY,
  payload,
});

const doFetchPesoUserHistorySuccess = (payload) => ({
  type: FETCH_PESO_USER_HISTORY_SUCCESS,
  payload,
});

const doFetchPesoUserHistoryError = (error) => ({
  type: FETCH_PESO_USER_HISTORY_ERROR,
  payload: error,
});

const doPostPesoTransfer = (payload) => ({
  type: POST_PESO_TRANSFER,
  payload,
});

const doPostPesoTransferSuccess = (payload) => ({
  type: POST_PESO_TRANSFER_SUCCESS,
  payload,
});

const doPostPesoTransferError = (error) => ({
  type: POST_PESO_TRANSFER_ERROR,
  payload: error,
});

const doPostPesoGive = (payload) => ({
  type: POST_PESO_GIVE,
  payload,
});

const doPostPesoGiveSuccess = (payload) => ({
  type: POST_PESO_GIVE_SUCCESS,
  payload,
});

const doPostPesoGiveError = (error) => ({
  type: POST_PESO_GIVE_ERROR,
  payload: error,
});

const doPostPesoTake = (payload) => ({
  type: POST_PESO_TAKE,
  payload,
});

const doPostPesoTakeSuccess = (payload) => ({
  type: POST_PESO_TAKE_SUCCESS,
  payload,
});

const doPostPesoTakeError = (error) => ({
  type: POST_PESO_TAKE_ERROR,
  payload: error,
});

export {
  doFetchPesoUserHistory,
  doFetchPesoUserHistorySuccess,
  doFetchPesoUserHistoryError,
  doPostPesoTransfer,
  doPostPesoTransferSuccess,
  doPostPesoTransferError,
  doPostPesoGive,
  doPostPesoGiveSuccess,
  doPostPesoGiveError,
  doPostPesoTake,
  doPostPesoTakeSuccess,
  doPostPesoTakeError,
};
