export const DELETE_MODAL = 'delete';
export const DELETE_CV_MODAL = 'delete-cv-modal';
export const PESO_TRANSFER_MODAL = 'peso-transfer-modal';
export const MASSAGE_NOTE_MODAL = 'massage-note-modal';
export const STARSHOP_PURCHASE_MODAL = 'starshop-purchase-modal';
export const STARSHOP_UPDATE_ORDER_MODAL = 'starshop-update-order-modal';
export const STARSHOP_ORDER_HISTORY_MODAL = 'starshop-order-history-modal';
export const STARSHOP_CREATE_MERCH_MODAL = 'starshop-create-merch-modal';
export const STARSHOP_DELETE_MERCH_MODAL = 'starshop-delete-merch-modal';
export const STARSHOP_UPDATE_MERCH_MODAL = 'starshop-update-merch-modal';
