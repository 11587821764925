import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bulma-components/';
import LoaderContainer from './../../../common/loader/LoaderContainer';

import { doPostStarshopAdminUpdateOrder } from './../../../actions/starshop';

import styles from './StarshopOrderHistoryRow.module.scss';

import ninja from './../../../common/user-ninja.svg';
import { doOpenModal } from '../../../actions/modal';
import { STARSHOP_UPDATE_ORDER_MODAL } from '../../../common/modalTypes';
import { orderStatuses } from '../../starshop/starshop.model';

const StarshopOrderHistoryRow = React.memo(
  ({ orderHistory, onStarshopUpdateOrder, onPostStarshopUpdateOrder }) => {
    const [imgLoading, setImgLoading] = useState(() => true);

    const merchItemImage = orderHistory.merch.allImages[0]?.imageLinks.small ? (
      <>
        {imgLoading && (
          <div className={styles.imageLoader}>
            <LoaderContainer size="small" />
          </div>
        )}
        <img
          src={orderHistory.merch.allImages[0].imageLinks.large}
          alt="Profile"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
          className={styles.squareImage}
          onLoad={() => setImgLoading(() => false)}
        />
      </>
    ) : (
      <img src={ninja} alt="Placeholder" className={styles.placeholderImage} />
    );

    const handleUpdateOrder = () => {
      onStarshopUpdateOrder({
        type: STARSHOP_UPDATE_ORDER_MODAL,
        successFunction: (status, declineComment) =>
          onPostStarshopUpdateOrder({ id: orderHistory.id, status, declineComment }),
        data: { status: orderHistory.status, declineComment: orderHistory.declineComment },
      });
    };

    let status = orderHistory.status.charAt(0).toUpperCase() + orderHistory.status.slice(1);
    let ordered_date =
      new Date(orderHistory.purchased).toLocaleDateString() +
      ' ' +
      new Date(orderHistory.purchased).toLocaleTimeString();
    let modified_date = orderHistory.responded
      ? new Date(orderHistory.responded).toLocaleDateString() +
        ' ' +
        new Date(orderHistory.responded).toLocaleTimeString()
      : orderHistory.responded;
    let tagStyle =
      (orderHistory.status === orderStatuses.ordered && 'is-info') ||
      (orderHistory.status === orderStatuses.delivered && 'is-success') ||
      (orderHistory.status === orderStatuses.declined && 'is-danger');
    return (
      <div key={orderHistory.id} className="list-item">
        <div className="columns is-vcentered">
          <div
            className="column is-1 card-image is-flex is-pulled-left"
            style={{ cursor: 'pointer' }}
          >
            <figure className={`image is-square ${styles.figureBorder}`}>{merchItemImage}</figure>
          </div>
          <div className="column has-text-left">
            <div className="has-text-weight-bold is-size-5">{orderHistory.merch.name}</div>
            <div className="has-text-weight-regular is-size-7">
              User comment: {orderHistory.userComment}
            </div>
          </div>
          <div className="column has-text-left is-size-6 is-2">
            <div className="has-text-weight-bold">User:</div>
            {orderHistory.user ? (
              <Link to={`/profile/normal/${orderHistory.user.id}`}>
                <div className="has-text-weight-semibold">{orderHistory.user.name}</div>
              </Link>
            ) : (
              <div className="has-text-weight-semibold">Törölt felhasználó</div>
            )}
          </div>
          <div className="column has-text-weight-light has-text-left">
            <div className="has-text-weight-semibold has-text-left ">Ordered:</div>
            <div className="has-text-weight-light ">{ordered_date}</div>
            {modified_date && (
              <>
                <div className="has-text-weight-semibold">Modified:</div>
                <div className="has-text-weight-light">{modified_date}</div>
              </>
            )}
          </div>
          <div
            className="column equal-width "
            style={{ justifyContent: 'center', display: 'flex', flexFlow: 'column' }}
          >
            <div
              className={`tag  ${tagStyle} has-text-weight-bold`}
              style={{ width: '100%', marginBottom: '0.4rem' }}
            >
              {status}
            </div>
            {orderHistory.status === orderStatuses.declined && (
              <div className="has-text-left">
                <span className="has-text-weight-semibold" style={{ marginRight: '0.4rem' }}>
                  Decline comment:
                </span>
                <span>{orderHistory.declineComment} </span>
              </div>
            )}
          </div>
          <div className={`column is-narrow  is-1 ${styles.edit_button_container}`}>
            <Button color="" onClick={handleUpdateOrder} style={{ width: '100%' }}>
              Edit
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

StarshopOrderHistoryRow.propTypes = {
  onStarshopUpdateOrder: PropTypes.func,
  onPostStarshopUpdateOrder: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStarshopUpdateOrder: (params) => dispatch(doOpenModal(params)),
    onPostStarshopUpdateOrder: (params) => dispatch(doPostStarshopAdminUpdateOrder(params)),
  };
};

export default connect(null, mapDispatchToProps)(StarshopOrderHistoryRow);
