import { TIMESHEET_FETCH, TIMESHEET_FETCH_SUCCESS, TIMESHEET_FETCH_ERROR, SET_DATE } from '../store/constants';
import { getYear, getMonth } from 'date-fns';

const initalState = {
    loading: false,
    error: false,
    timeSheets: [],
    weekHeaders: [],
    filter:  {
        year: getYear(new Date()),
        month: getMonth(new Date()),
        all: false,
        allDum: false
    }
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case TIMESHEET_FETCH:
            return {...state, loading: true, error: false };
        case TIMESHEET_FETCH_SUCCESS:
            return {...state, loading: false, ...action.payload};
        case TIMESHEET_FETCH_ERROR: 
            return {...state, loading: false, error: true }
        case SET_DATE: 
            return {...state, filter: action.payload}
        default: 
            return state;
    }
}

export default reducer;