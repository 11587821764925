import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  FETCH_EXPENSE_SHEETS,
  FETCH_EXPENSE_SHEETS_ADMIN,
  CREATE_EXPENSE_SHEET,
  UPDATE_EXPENSE_SHEET,
} from '../../store/constants';

import { doShowMessage } from '../../actions/message';

import {
  doFetchActiveExpensesSuccess,
  doFetchActiveExpensesError,
  doQueryExpenseSuccess,
  doQueryExpenseError,
} from '../../actions/expenseSheet';

import { getActiveExpenseSheets, getExpenseSheetAdmin } from '../../api/expense.api';

const actionInfos = {
  [FETCH_EXPENSE_SHEETS]: {
    endPoint: getActiveExpenseSheets,
    successAction: doFetchActiveExpensesSuccess,
    errorAction: doFetchActiveExpensesError,
  },
  [FETCH_EXPENSE_SHEETS_ADMIN]: {
    endPoint: getExpenseSheetAdmin,
    successAction: doQueryExpenseSuccess,
    errorAction: doQueryExpenseError,
  },
};

export function* fetchExpenseSheets(action) {
  const { endPoint, successAction, errorAction } = actionInfos[action.type];
  try {
    const { data } = yield call(endPoint, action.payload);

    yield all([put(successAction(data))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* postWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, endPoint, successMessage },
  } = action;

  try {
    const { data } = yield call(endPoint, payload);

    yield all([
      put(successAction(data)),
      put(
        doShowMessage({
          type: 'success',
          content: successMessage,
        }),
      ),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchFetchActiveExpenseSheets() {
  yield takeLatest(FETCH_EXPENSE_SHEETS, fetchExpenseSheets);
}

export function* watchFetchExpenseSheetAdmin() {
  yield takeLatest(FETCH_EXPENSE_SHEETS_ADMIN, fetchExpenseSheets);
}

export function* watchCreateExpenseSheet() {
  yield takeLatest(CREATE_EXPENSE_SHEET, postWorkerSaga);
}

export function* watchUpdateExpenseSheet() {
  yield takeLatest(UPDATE_EXPENSE_SHEET, postWorkerSaga);
}
