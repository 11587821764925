import * as R from 'ramda';
import { success, error } from './../../common/helpers/constanHelpers';
import createReducer from '../reducer-helpers/createReducer';
import { setSimpleLoading, setError } from './../reducer-helpers/reducerHelpers';

import { FETCH_NEWS } from '../../store/constants';

const INITAL_STATE = {
  error: false,
  loading: false,
  items: [],
};

const setItems = (action) => (state) =>
  R.evolve(
    {
      loading: R.always(false),
      items: () => R.uniqWith(R.equals, R.concat(action.payload, state.items)),
    },
    state,
  );

const newsReducer = createReducer(INITAL_STATE, {
  [FETCH_NEWS]: setSimpleLoading,
  [success(FETCH_NEWS)]: setItems,
  [error(FETCH_NEWS)]: setError,
});

export default newsReducer;
