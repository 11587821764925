import { 
    GET_WORK_HOURS,
    GET_BANK_HOLIDAYS_ERROR,
    GET_WORK_HOURS_SUCCESS,
    POST_WORK_HOURS,
    POST_WORK_HOURS_SUCCESS,
    POST_WORK_HOURS_ERROR,
    DELETE_WORK_HOURS,
    DELETE_WORK_HOURS_ERROR,
    DELETE_WORK_HOURS_SUCCESS
} from '../store/constants';


const initalState = {
    loading: false,
    error: false,
    data: []
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case GET_WORK_HOURS:
        case POST_WORK_HOURS:
        case DELETE_WORK_HOURS:
            return {...state, loading: true, error: false };
        case GET_WORK_HOURS_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case POST_WORK_HOURS_SUCCESS:
        case DELETE_WORK_HOURS_SUCCESS: 
            return {...state, loading: false }
        case GET_BANK_HOLIDAYS_ERROR:
        case POST_WORK_HOURS_ERROR:
        case DELETE_WORK_HOURS_ERROR:
            return {...state, loading: false, error: action.payload }
        default: 
            return state;
    }
}

export default reducer;