import { call, put, takeLatest, all } from 'redux-saga/effects';

import { UPDATE_CV_LANGUAGES, UPDATE_CV_META } from '../../store/constants';

import { doUpdateCvLanguagesSuccess, doUpdateCvLanguagesError } from '../../actions/cv';

import { doGetUserDetailsSuccess } from '../../actions/user';

import { doShowMessage } from '../../actions/message';

import { updateCvLanguage } from '../starbookApiCalls';

export function* cvLanguageUpdateWorker(action) {
  try {
    const result = yield call(updateCvLanguage, action.payload);

    yield all([
      put(doGetUserDetailsSuccess(result.data)),
      put(doUpdateCvLanguagesSuccess({})),
      put(
        doShowMessage({
          type: 'success',
          content: 'Successfully added language',
        }),
      ),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doUpdateCvLanguagesError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* cvDefaultUpdater(action) {
  const {
    payload,
    meta: { successAction, errorAction, endPoint, successMessage },
  } = action;
  try {
    const { data } = yield call(endPoint, payload);

    yield all([
      put(doGetUserDetailsSuccess(data)),
      put(successAction({})),
      put(
        doShowMessage({
          type: 'success',
          content: successMessage,
        }),
      ),
    ]);
  } catch (error) {
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchCvLanguageUpdate() {
  yield takeLatest(UPDATE_CV_LANGUAGES, cvLanguageUpdateWorker);
}

export function* watchCvMetaUpdate() {
  yield takeLatest(UPDATE_CV_META, cvDefaultUpdater);
}
