import { 
    FETCH_DEPARTMENTS,
    FETCH_DEPARTMENTS_SUCCESS,
    FETCH_DEPARTMENTS_ERROR,
    SET_DEPARTMENTS,
    SET_DEPARTMENTS_SUCCESS,
    SET_DEPARTMENTS_ERROR
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    list: [],
    submitLoading: false,
    submitError: false
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case FETCH_DEPARTMENTS:
            return {...state, loading: true, error: false };
        case FETCH_DEPARTMENTS_SUCCESS:
            return {...state, loading: false, list: action.payload};
        case FETCH_DEPARTMENTS_ERROR: 
            return {...state, loading: false, error: true};
        case SET_DEPARTMENTS:
            return {...state, submitLoading: true, submitError: false };
        case SET_DEPARTMENTS_SUCCESS: 
            return {...state, submitLoading: false, list: action.payload};
        case SET_DEPARTMENTS_ERROR:
            return {...state, submitLoading: false, submitError: true}
        default: 
            return state;
    }
}

export default reducer;