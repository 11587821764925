import React from 'react';
import PropTypes from 'prop-types';

import { cond, equals, T } from 'ramda';

const getSizeClass = cond([
  [equals('small'), () => 'is-small'],
  [equals('medium'), () => 'is-medium'],
  [equals('large'), () => 'is-large'],
  [T, () => ''],
]);

const InputField = ({ field, form: { touched, errors }, size, help, danger, ...props }) => {
  const displayError = touched[field.name] && errors[field.name];

  const sizeClass = getSizeClass(size);

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <div className="control">
        <input
          className={`input ${sizeClass} ${displayError ? 'is-danger' : ''}`}
          type="text"
          {...field}
          {...props}
        />
      </div>
      {displayError && <p className="help is-danger">{errors[field.name]}</p>}
      {help && <p className="help">{help}</p>}
      {danger && <p className="help is-danger">{danger}</p>}
    </div>
  );
};

InputField.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  size: PropTypes.string,
};

export default InputField;
