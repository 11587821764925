import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when, head, remove } from 'ramda';
import { useSelector } from 'react-redux';

import { getFileUpload } from './../../selectors/file-upload.selectors';

import styles from './FileUploader.module.sass';

const FileUploader = ({
  image,
  onUpload,
  uploading,
  success,
  error,
  progress,
  showProgressAndButton,
  multiple,
}) => {
  const [uploadMode, toggleUpload] = useState(() => false);
  const [files, setFiles] = useState(() => []);
  const [fileProgresses, setFileProgresses] = useState(() => {});
  const progressState = useSelector(getFileUpload);

  const handleUploadClick = () => toggleUpload(uploadMode => !uploadMode);

  const handleUploadSuccess = when(
    success => success,
    () => {
      toggleUpload(() => false);
      setFiles(() => []);
    },
  );

  useEffect(() => {
    if (!image) {
      toggleUpload(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleUploadSuccess(success);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    const fileNames = files.map(f => f.fileName);
    const keys = Object.keys(progressState);

    const reducedNewProgress = keys.reduce((acc, curr) => {
      const includes = fileNames.filter(f => curr.includes(f));

      includes.length && (acc[includes[0]] = { ...progressState[curr] });

      return acc;
    }, {});

    setFileProgresses(() => reducedNewProgress);
  }, [files, progressState]);

  const hasProgressObject = name => {
    const keys = Object.keys(progressState);
    const hasItem = keys.filter(k => k.includes(name));
    return hasItem.length > 0;
  };

  const onDrop = useCallback(acceptedFiles => {
    console.log('files', acceptedFiles);
    /* setFile(() => acceptedFiles[0]); */

    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // console.log(file);
        const binaryStr = reader.result;
        const binArray = new Uint8Array(binaryStr);
        const binString = binArray.reduce((data, byte) => data + String.fromCharCode(byte), '');

        let formData = new FormData();
        formData.append('file', binString);

        const type = file.type.split('/')[1];
        const name = head(file.name.split('.'));

        /* console.log('name', name);
        console.log('type', type);
        console.log('data', binaryStr); */

        const fileItem = {
          data: binaryStr,
          type: type,
          fileName: name,
        };

        setFiles(prev => [fileItem, ...prev]);
      };

      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(files);
    if (files.length > 0) {
      onUpload(files);
    }
  }, [files, onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
  });

  const removeFile = (index, event) => {
    event.stopPropagation();
    const newFiles = remove(index, 1, files);
    setFiles(() => newFiles);
  };

  return (
    <React.Fragment>
      {uploadMode ? (
        <div className={styles.uploadContainer}>
          <div
            className="file is-medium is-centered has-name is-boxed is-fullwidth"
            {...getRootProps()}
          >
            <input className="file-input" {...getInputProps()} />
            <label className="file-label">
              <span className="file-cta">
                <span className="file-icon">
                  <FontAwesomeIcon icon="upload" />
                </span>
                {isDragActive ? (
                  <span className="file-label">Drop the files here ...</span>
                ) : (
                  <span className="file-label">Drag 'n' drop some files here</span>
                )}
              </span>
              {files.length > 0 && (
                <>
                  {files.map((f, i) => (
                    <div key={i} onClick={e => e.stopPropagation()}>
                      <span className={`file-name ${styles.nameContainer}`}>
                        <div>
                          {f.fileName}.{f.type}
                          <button className={`delete`} onClick={e => removeFile(i, e)}></button>
                        </div>
                        {hasProgressObject(f.fileName) && fileProgresses[f.fileName] && (
                          <progress
                            className={`progress is-small ${styles.rectangleProgress}`}
                            value={fileProgresses[f.fileName].progress}
                            max="1"
                          >
                            15%
                          </progress>
                        )}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </label>
          </div>
          {showProgressAndButton && (
            <>
              <div className={styles.progressBarContainer}>
                <progress className="progress is-small" value={progress} max="1">
                  15%
                </progress>
              </div>
              <button
                className={`button is-danger is-fullwidth`}
                disabled={uploading}
                onClick={handleUploadClick}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      ) : (
        <div className={styles.imageWrapper} onClick={handleUploadClick}>
          <img className="image" src={image} alt="starpicture" />
          <p>Click to change picture.</p>
        </div>
      )}
    </React.Fragment>
  );
};

FileUploader.propTypes = {
  image: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  progress: PropTypes.number,
  showProgressAndButton: PropTypes.bool.isRequired,
};

export default FileUploader;
