import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar } from 'react-bulma-components';

import NavbarLink from '../../common/navbar-link/NavbarLink';
import {
  ADMIN,
  DEPARTMENT_ADMIN,
  MERCH_ADMIN,
  MASSAGE_ADMIN as MASSAGE_ADMIN_PERM,
  SKILL_ADMIN,
  // TS_REVIEW_ADMIN,
  // EXPENSE_SHEET_ADMIN,
} from '../../common/permissionNames';
import {
  // TIMESHEET_SETTINGS,
  LOCATION_MANAGER,
  PESO_ADMIN,
  MASSAGE_ADMIN,
  MASSAGE,
} from './../../common/paths';

import './Header.scss';

import logo from './logo-blue.svg';

const Header = ({ history, user, permissions }) => {
  const [show, setShow] = useState(() => false);

  /*   const shouldShowAdminExpenses =
    permissions.includes(EXPENSE_SHEET_ADMIN) || user.isManager || user.isDum; */

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <Navbar fixed="top" className="navigation" active={show}>
      <Navbar.Brand>
        <NavbarLink to={`/`} history={history}>
          <img src={logo} alt="Logo" className="headerLogo" />
        </NavbarLink>
        <Navbar.Burger onClick={handleShow} />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container>
          {/* <NavbarLink to="/news" exact={true} history={history}>
            News
          </NavbarLink> */}
          {/* starbook */}
          {permissions.includes(DEPARTMENT_ADMIN) || permissions.includes(SKILL_ADMIN) ? (
            <Navbar.Item dropdown={true} hoverable={true} renderAs="div">
              <Navbar.Link>
                <NavbarLink
                  arrowless={false}
                  to="/starbook"
                  activeClassName="is-active"
                  exact={true}
                  history={history}
                >
                  Starbook
                </NavbarLink>
              </Navbar.Link>
              <Navbar.Dropdown>
                {permissions.includes(DEPARTMENT_ADMIN) && (
                  <NavbarLink to="/starbook/delivery-units" history={history}>
                    Delivery Units
                  </NavbarLink>
                )}
                {permissions.includes(SKILL_ADMIN) && (
                  <NavbarLink to="/starbook/skills" history={history}>
                    Skills
                  </NavbarLink>
                )}
              </Navbar.Dropdown>
            </Navbar.Item>
          ) : (
            <NavbarLink to="/starbook" exact={true} history={history}>
              Starbook
            </NavbarLink>
          )}
          {/* <NavbarLink to="/timesheet-review" exact={true} history={history}>
            Timesheet Review
          </NavbarLink> */}
          <NavbarLink to="/starshop" exact={true} history={history}>
            Starshop
          </NavbarLink>
          {permissions.includes(MERCH_ADMIN) && (
            <NavbarLink to="/starshop-admin" exact={true} history={history}>
              Starshop Admin
            </NavbarLink>
          )}
          <NavbarLink to={MASSAGE} exact={true} history={history}>
            Massage
          </NavbarLink>
          {permissions.includes(MASSAGE_ADMIN_PERM) && (
            <NavbarLink to={MASSAGE_ADMIN} activeClassName="is-active" history={history}>
              Massage Admin
            </NavbarLink>
          )}
          {/* admin settings */}
          {/* training*/}
          {/* <Navbar.Item
            dropdown={true}
            hoverable={true}
            renderAs="div"
          >
            <Navbar.Link>
              <NavbarLink
                arrowless={true}
                to="/training"
                activeClassName="is-active"
                exact={true}
                history={props.history}
              >
                Training
              </NavbarLink>
            </Navbar.Link>
            
          </Navbar.Item> */}
          {/* {(user.isDum || user.isManager) && (
            <a
              href="http://absence-report.starschema.net/report"
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              Absence report
            </a>
          )} */}
          {permissions.includes(ADMIN) && (
            <Navbar.Item dropdown={true} hoverable={true} renderAs="div">
              <Navbar.Link>Admin</Navbar.Link>
              <Navbar.Dropdown>
                {permissions.includes(ADMIN) && (
                  <>
                    {/* <NavbarLink
                      to={TIMESHEET_SETTINGS}
                      activeClassName="is-active"
                      history={history}
                    >
                      TS Settings
                    </NavbarLink> */}
                    <NavbarLink to={LOCATION_MANAGER} activeClassName="is-active" history={history}>
                      Location Manager
                    </NavbarLink>
                    <NavbarLink to={PESO_ADMIN} activeClassName="is-active" history={history}>
                      PESO Admin
                    </NavbarLink>
                  </>
                )}
              </Navbar.Dropdown>
            </Navbar.Item>
          )}
          {/*  {!permissions.includes(EXPENSE_SHEET_ADMIN) && (
            <NavbarLink to="/expense" exact={true} history={history}>
              My expenses
            </NavbarLink>
          )}
          {shouldShowAdminExpenses && (
            <>
              <NavbarLink to="/expense/sheet/new" exact={true} history={history}>
                New Expense
              </NavbarLink>
              <NavbarLink to="/expense/admin" exact={true} history={history}>
                Expenses
              </NavbarLink>
            </>
          )} */}
        </Navbar.Container>
        <Navbar.Container position="end">
          <Navbar.Item dropdown={true} hoverable={true} renderAs="div">
            <Navbar.Link>
              <NavbarLink
                arrowless={false}
                to="/profile"
                activeClassName="is-active"
                exact={true}
                history={history}
              >
                {user.name}
                <span className="peso-count">
                  <FontAwesomeIcon icon="coins"></FontAwesomeIcon>
                  <span>{user.pesoCount}</span>
                </span>
              </NavbarLink>
            </Navbar.Link>
            <Navbar.Dropdown>
              <NavbarLink to={`/starbook/cv/${user.id}`} history={history}>
                Cvs
              </NavbarLink>
              <NavbarLink to="/timesheet-review/workdetails" history={history}>
                Work Details
              </NavbarLink>
              <a href="/api/account/logout" className="navbar-item">
                Logout
              </a>
            </Navbar.Dropdown>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Header;
