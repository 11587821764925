import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bulma-components';

import styles from './PesoModal.module.scss';
import { Field, Form, Formik } from 'formik';
import InputField from '../../../common/input-field/InputField';
import MultiSelect from '../../../common/single-select/SingleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from '../../../selectors/starbook.seletors';
import { doPostPesoGive, doPostPesoTake, doPostPesoTransfer } from '../../../actions/peso';
import TextareaField from '../../../common/textarea-field/TextareaField';
import { getPesoTransferError, getPesoTransferLoading } from '../../../selectors/peso.selectors';
import { getPermissions, getUserState } from '../../../selectors/login.selectors';
import { getModalData } from '../../../selectors/modal.selectors';
import { doFetchStarbookData } from '../../../actions/starbook';

const PesoModal = () => {
  const dispatch = useDispatch();

  const data = useSelector(getModalData);
  const user = useSelector(getUserState);
  const permissions = useSelector(getPermissions);
  const employeesList = useSelector(getEmployees).filter((employee) => employee.value !== user.id);
  const error = useSelector(getPesoTransferError);
  const loading = useSelector(getPesoTransferLoading);

  const [values, setValues] = useState(null);
  const [transferType, setTransferType] = useState('');

  const formRef = useRef();

  const initialValues = {
    username: user.id === data.id ? '' : data.id,
    amount: '',
    comment: '',
  };

  const isPesoAdmin = permissions.includes('pesoAdmin');

  const onSubmit = (values) => {
    setValues(values);

    if (!values.username || !values.amount) {
      return;
    }

    const payload = {
      targetUserId: values.username,
      amount: parseInt(values.amount, 10),
      comment: values.comment,
      sendSlackMessage: true,
    };

    if (!transferType) {
      dispatch(doPostPesoTransfer(payload));
    }

    if (transferType === 'give') {
      dispatch(doPostPesoGive(payload));
    }

    if (transferType === 'take') {
      dispatch(doPostPesoTake(payload));
    }

    setTransferType('');
  };

  const onAdminTransfer = (type) => {
    if (!formRef.current) {
      return;
    }

    setTransferType(type);

    formRef.current.submitForm();
  };

  useEffect(() => {
    dispatch(doFetchStarbookData({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal.Card className={styles.modal}>
      <Modal.Card.Head showClose={false}>
        <Modal.Card.Title>Transfer</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <Form>
            <div className="field is-danger">
              <div className="label">Username</div>
              <Field
                name="username"
                component={MultiSelect}
                list={employeesList}
                hasError={values && !values.username}
                readonly={user.id !== data.id}
              />
              {values && !values.username && <div className="help is-danger">Username is required</div>}
            </div>
            <Field
              name="amount"
              component={InputField}
              label="Amount"
              className={`input ${styles.amount} ${(values && !values.amount && 'is-danger')}`}
              danger={values && !values.amount && 'Amount is required'}
              maxLength={3}
            />
            <Field
              name="comment"
              component={TextareaField}
              label="Comment"
            />
            {error && error.data && <div className="block help is-danger">{error.data.message}</div>}

            <div className="buttons is-right">
              <button
                className={`button is-info ${loading ? 'is-loading' : ''}`}
                type="submit"
              >
                Send
              </button>
              {isPesoAdmin && (
                <>
                  <button
                    className={`button is-primary ${loading ? 'is-loading' : ''}`}
                    type="button"
                    onClick={() => onAdminTransfer('give')}
                  >
                    Give
                  </button>
                  <button
                    className={`button is-danger ${loading ? 'is-loading' : ''}`}
                    type="button"
                    onClick={() => onAdminTransfer('take')}
                  >
                    Take
                  </button>
                </>
              )}
            </div>
          </Form>
        </Formik>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

PesoModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default PesoModal;
