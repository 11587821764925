import { takeLatest, call, put, all, select, actionChannel, take } from 'redux-saga/effects';
import * as R from 'ramda';
import { success } from './../../common/helpers/constanHelpers';

import {
  doCreateInovicesSuccess,
  doCreateInovicesError,
  doDeleteInvoiceSuccess,
  doDeleteInvoiceError,
  doUpdateInvoiceSuccess,
  doUpdateInvoiceError,
  doDeleteInvoiceAttachmentSuccess,
  doDeleteInvoiceAttachmentError,
  doUploadAttachmentSuccess,
  doUploadAttachmentError,
} from '../../actions/expenseSheetInvoices';
import { doShowMessage } from './../../actions/message';
import { doUploadFile } from './../../actions/file-upload';

import {
  CREATE_INVOICE,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  DELETE_ATTACHMENT,
  UPLOAD_INVOICE_ATTACHMENT,
} from './../../store/constants';

import {
  createExpenseSheetInvoice,
  uploadExpenseSheetInvoiceAttachment,
  deleteExpenseSheetInvoice,
  updateExpenseSheetInvoice,
  deleteExpenseSheetInvoiceAttachment,
} from '../../api/expense.api';

const getInvoices = ({ expenseSheetInvoices }) => expenseSheetInvoices.items;

const operations = {
  [CREATE_INVOICE]: {
    endPoint: createExpenseSheetInvoice,
    successAction: doCreateInovicesSuccess,
    errorAction: doCreateInovicesError,
    attachmentSuccess: doUploadAttachmentSuccess,
    attachmentError: doUploadAttachmentError,
  },
  [UPDATE_INVOICE]: {
    endPoint: updateExpenseSheetInvoice,
    successAction: doUpdateInvoiceSuccess,
    errorAction: doUpdateInvoiceError,
    attachmentSuccess: doUploadAttachmentSuccess,
    attachmentError: doUploadAttachmentError,
  },
};

const hasId = R.has('id');

const appendOrReplace = data =>
  R.ifElse(
    responseItem => R.findIndex(item => R.equals(item.id, responseItem.id), data),
    responseItem => R.map(item => (R.equals(item.id, responseItem.id) ? responseItem : item), data),
    responseItem => R.append(responseItem, data),
  );

function* invoiceHandler(action) {
  const { endPoint, successAction, errorAction, attachmentSuccess, attachmentError } = operations[
    action.type
  ];

  try {
    const invoices = yield select(getInvoices);

    const payload = {
      id: action.payload.id,
      data: {
        description: action.payload.data.description,
        quantity: parseInt(action.payload.data.quantity, 10),
        unitPrice: parseInt(action.payload.data.unitPrice, 10),
        currency: action.payload.data.currency,
        attachments: action.payload.data.attachments,
      },
    };

    if (payload.data.attachments.length === 0) {
      delete payload.data.attachments;
    }

    const { data } = yield call(endPoint, payload);

    const attachments = payload.data.attachments.filter(item => !hasId(item));

    if (attachments.length > 0) {
      const actions = attachments.map(item => {
        return put(
          doUploadFile({
            actions: {
              successAction: attachmentSuccess,
              errorAction: attachmentError,
            },
            data: {
              id: data.id,
              data: item.data,
              type: item.type,
              fileName: item.fileName,
            },
            endPoint: uploadExpenseSheetInvoiceAttachment,
          }),
        );
      });

      yield all(actions);

      const newItem = R.clone(data);

      const requestChan = yield actionChannel(success(UPLOAD_INVOICE_ATTACHMENT));

      while (true) {
        const { payload } = yield take(requestChan);
        newItem.attachments = R.append(payload, newItem.attachments);

        const newArray = appendOrReplace(invoices)(newItem);

        yield put(successAction(newArray));
      }
    } else {
      const newInvoices = appendOrReplace(invoices)(data);
      yield put(successAction(newInvoices));
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(errorAction(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* deleteInvoice(action) {
  try {
    const result = yield call(deleteExpenseSheetInvoice, action.payload);

    if (result.status === 200) {
      const invoices = yield select(getInvoices);
      const newInvoices = invoices.filter(item => item.id !== action.payload.id);

      yield all([put(doDeleteInvoiceSuccess(newInvoices))]);
    } else {
      yield all([
        put(doDeleteInvoiceError()),
        put(
          doShowMessage({
            type: 'error',
            content: 'Error during delete',
          }),
        ),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(doDeleteInvoiceError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

function* deleteAttachment(action) {
  try {
    const result = yield call(deleteExpenseSheetInvoiceAttachment, {
      attachmentId: action.payload.attachmentId,
    });

    console.log('result', result);

    const invoices = yield select(getInvoices);
    const newInvoices = invoices.map(invoice => {
      if (invoice.id === action.payload.id) {
        const newInvoice = {
          ...invoice,
        };
        newInvoice.attachments = newInvoice.attachments.filter(
          item => item.id !== action.payload.attachmentId,
        );
        return newInvoice;
      } else {
        return invoice;
      }
    });

    yield all([put(doDeleteInvoiceAttachmentSuccess(newInvoices))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doDeleteInvoiceAttachmentError(error)),
      put(
        doShowMessage({
          type: 'error',
          content: error.message,
        }),
      ),
    ]);
  }
}

export function* watchCreateExpenseSheetInvoice() {
  yield takeLatest(CREATE_INVOICE, invoiceHandler);
}

export function* watchDeleteExpenseSheetInvoice() {
  yield takeLatest(DELETE_INVOICE, deleteInvoice);
}

export function* watchUpdateExpenseSheetInvoice() {
  yield takeLatest(UPDATE_INVOICE, invoiceHandler);
}

export function* watchDeletetInvoiceAttachment() {
  yield takeLatest(DELETE_ATTACHMENT, deleteAttachment);
}
