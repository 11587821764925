import {
  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from '../store/constants';

const doUploadFile = payload => ({
  type: UPLOAD_FILE,
  payload,
});

const doUploadFileProgress = payload => ({
  type: UPLOAD_FILE_PROGRESS,
  payload,
});

const doUploadFileSuccess = payload => ({
  type: UPLOAD_FILE_SUCCESS,
  payload,
});

const doUploadFileError = payload => ({
  type: UPLOAD_FILE_ERROR,
  payload,
});

export { doUploadFile, doUploadFileProgress, doUploadFileSuccess, doUploadFileError };
