import axios from 'axios';

const requestConfig = {
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

const user = process.env.REACT_APP_INTRANET;
const password = process.env.REACT_APP_INTRANET_PASSWORD;

export const fetchNews = () =>
  axios.get(`http://intranet.starschema.net/wp-json/wp/v2/posts?per_page=20&_embed`, {
    ...requestConfig,
    auth: {
      username: user,
      password: password,
    },
  });
