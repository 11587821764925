import { success, error } from './../../common/helpers/constanHelpers';
import {
  FETCH_EXPENSE_ROWS,
  DELETE_EXPENSE_ROW,
  DELETE_EXPENSE_ROW_ATTACHMENT,
  CREATE_EXPENSE,
  CREATE_EXPENSE_INIT,
  UPDATE_EXPENSE,
} from '../../store/constants';

const initState = {
  loading: false,
  error: false,
  success: false,
  rows: [],
  id: '',
};

const expenseRow = (state = initState, action) => {
  switch (action.type) {
    case FETCH_EXPENSE_ROWS:
      return { ...state, loading: true, error: false, id: action.payload.id };
    case DELETE_EXPENSE_ROW_ATTACHMENT:
      return { ...state, loading: true, error: false };
    case success(FETCH_EXPENSE_ROWS):
    case success(DELETE_EXPENSE_ROW_ATTACHMENT):
      return { ...state, loading: false, rows: [...action.payload] };
    case error(FETCH_EXPENSE_ROWS):
    case error(DELETE_EXPENSE_ROW_ATTACHMENT):
    case error(UPDATE_EXPENSE):
      return { ...state, loading: false, error: true };
    case DELETE_EXPENSE_ROW:
      return { ...state, loading: true, error: false };
    case success(DELETE_EXPENSE_ROW):
      return { ...state, loading: false };
    case error(DELETE_EXPENSE_ROW):
      return { ...state, loading: false, error: true };
    case CREATE_EXPENSE:
    case UPDATE_EXPENSE:
      return { ...state, loading: true, error: false, success: false };
    case success(CREATE_EXPENSE):
      return {
        ...state,
        loading: false,
        success: true,
        rows: [...state.rows, { ...action.payload }],
      };
    case error(CREATE_EXPENSE):
      return { ...state, loading: false, error: true };
    case success(UPDATE_EXPENSE):
      const newRows = state.rows.map(row => {
        return row.rowId === action.payload.rowId ? action.payload : row;
      });
      return { ...state, loading: false, rows: [...newRows], success: true };
    case CREATE_EXPENSE_INIT:
      return { ...state, loading: false, error: false, success: false };
    default:
      return state;
  }
};

export default expenseRow;
