import {
  FETCH_STARSHOP_DATA,
  FETCH_STARSHOP_DATA_SUCCESS,
  FETCH_STARSHOP_DATA_ERROR,
  FETCH_STARSHOP_ORDER_HISTORY,
  FETCH_STARSHOP_ORDER_HISTORY_SUCCESS,
  FETCH_STARSHOP_ORDER_HISTORY_ERROR,
  POST_STARSHOP_PURCHASE,
  POST_STARSHOP_PURCHASE_SUCCESS,
  POST_STARSHOP_PURCHASE_ERROR,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR,
  POST_STARSHOP_ADMIN_UPDATE_ORDER,
  POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS,
  POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR,
  CREATE_STARSHOP_ADMIN_MERCH,
  CREATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  CREATE_STARSHOP_ADMIN_MERCH_ERROR,
  DELETE_STARSHOP_ADMIN_MERCH,
  DELETE_STARSHOP_ADMIN_MERCH_SUCCESS,
  DELETE_STARSHOP_ADMIN_MERCH_ERROR,
  UPDATE_STARSHOP_ADMIN_MERCH,
  UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS,
  UPDATE_STARSHOP_ADMIN_MERCH_ERROR,
  SET_NEW_IMAGE_DATA,
} from '../store/constants';

const initalState = {
  loadingData: false,
  loadingOrderHistory: false,
  error: false,
  merchItems: [],
  orderHistory: [],
  search: {},
  newImageData: null,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    //  Merch Data
    case FETCH_STARSHOP_DATA:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case FETCH_STARSHOP_DATA_SUCCESS:
      return { ...state, loadingData: false, merchItems: action.payload };
    case FETCH_STARSHOP_DATA_ERROR:
      return { ...state, loadingData: false, error: true };
    //  Order History
    case FETCH_STARSHOP_ORDER_HISTORY:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case FETCH_STARSHOP_ORDER_HISTORY_SUCCESS:
      return { ...state, loadingData: false, orderHistory: action.payload };
    case FETCH_STARSHOP_ORDER_HISTORY_ERROR:
      return { ...state, loadingData: false, error: true };
    //  Purchase
    case POST_STARSHOP_PURCHASE:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case POST_STARSHOP_PURCHASE_SUCCESS:
      let _merchItems = state.merchItems.map((item) => {
        if (item.id === action.payload.merch.id) {
          item.stockAvailable = action.payload.merch.stockAvailable;
          item.latestPurchase = {
            id: action.payload.id,
            status: action.payload.status,
            userComment: action.payload.userComment,
            declineComment: action.payload.declineComment,
            purchased: action.payload.purchased,
            responded: action.payload.responded,
          };
        }
        return item;
      });
      return {
        ...state,
        loadingData: false,
        merchItems: _merchItems,
        orderHistory: [...state.orderHistory, action.payload],
      };
    case POST_STARSHOP_PURCHASE_ERROR:
      return { ...state, loadingData: false, error: true };
    // ADMIN
    //  ADMIN Order History
    case FETCH_STARSHOP_ADMIN_ORDER_HISTORY:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case FETCH_STARSHOP_ADMIN_ORDER_HISTORY_SUCCESS:
      return { ...state, loadingData: false, orderHistory: action.payload };
    case FETCH_STARSHOP_ADMIN_ORDER_HISTORY_ERROR:
      return { ...state, loadingData: false, error: true };
    //  ADMIN Update Order
    case POST_STARSHOP_ADMIN_UPDATE_ORDER:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case POST_STARSHOP_ADMIN_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loadingData: false,
        orderHistory: state.orderHistory.map((order) =>
          order.id === action.payload.id ? action.payload : order,
        ),
      };
    case POST_STARSHOP_ADMIN_UPDATE_ORDER_ERROR:
      return { ...state, loadingData: false, error: true };
    //  ADMIN Create Merch
    case CREATE_STARSHOP_ADMIN_MERCH:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case CREATE_STARSHOP_ADMIN_MERCH_SUCCESS:
      return {
        ...state,
        loadingData: false,
        merchItems: [...state.merchItems, action.payload],
        newImageData: null,
      };
    case CREATE_STARSHOP_ADMIN_MERCH_ERROR:
      return { ...state, loadingData: false, error: true, newImageData: null };
    case SET_NEW_IMAGE_DATA:
      return { ...state, newImageData: action.payload };
    //  ADMIN Delete Merch
    case DELETE_STARSHOP_ADMIN_MERCH:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case DELETE_STARSHOP_ADMIN_MERCH_SUCCESS:
      return {
        ...state,
        loadingData: false,
        merchItems: state.merchItems.filter((merch) => merch.id !== action.payload.id),
      };
    case DELETE_STARSHOP_ADMIN_MERCH_ERROR:
      return { ...state, loadingData: false, error: true };
    //  ADMIN Delete Merch
    case UPDATE_STARSHOP_ADMIN_MERCH:
      return {
        ...state,
        loadingData: true,
        error: false,
      };
    case UPDATE_STARSHOP_ADMIN_MERCH_SUCCESS:
      return {
        ...state,
        loadingData: false,
        merchItems: state.merchItems.map((merch) =>
          merch.id === action.payload.id ? action.payload : merch,
        ),
      };
    case UPDATE_STARSHOP_ADMIN_MERCH_ERROR:
      return { ...state, loadingData: false, error: true };

    default:
      return state;
  }
};

export default reducer;
