import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { handleRequests } from 'redux-saga-requests';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

import { createDriver } from 'redux-saga-requests-axios';
import { starbookApi } from './../api/api-config';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: starbookApi,
  withCredentials: true,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
});

const { requestsReducer, requestsSagas } = handleRequests({ driver: createDriver(axiosInstance) });

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'starbook',
    'starshop',
    'login',
    'adminExpense',
    'expenseSheet',
    'activeExpense',
    'expenseRow',
    'expenseCreate',
    'timesheets',
    'fileUpload',
    'expenseSheetInvoices',
    'approve',
    'reject',
    'revoke',
    'specialMonths',
    'cron',
    'createExpenseSheet',
    'user',
    'massage',
    'peso',
    'cvSkills',
    'cvExperiences',
    'cvCertificates',
    'cvEducation',
    'cv',
    'modal',
    'message',
    'timesheetHandler',
    'locationManager',
    'router',
  ],
  stateReconciler: autoMergeLevel2,
};

export const starbookPersistConfig = {
  key: 'starbook',
  storage: storage,
  blacklist: ['search', 'loading', 'error', 'response'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const middlewares = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);
  let persistor = persistStore(store);
  return { store, persistor };
}

export { history, requestsReducer, requestsSagas };
