import { MASSAGE_SET_WSS_URL } from '../store/constants';

const initalState = {
  wssUrl: '',
};

const massageReducer = (state = initalState, action) => {
  switch (action.type) {
    case MASSAGE_SET_WSS_URL:
      return { ...action.payload };
    default:
      return state;
  }
};

export default massageReducer;
