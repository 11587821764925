import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import LoaderContainer from './../../loader/LoaderContainer';
import CategoryHeader from './../../skill-multi-select/category-header/CategoryHeader';
import SubCategoryHeader from './../../skill-multi-select/subcategory-header/SubCategoryHeader';
import SkillsHeader from './../../skill-multi-select/skill-header/SkillHeader';

import useOutsideClick from '../../hooks/useOutsideClick';

import styles from './ListContainer.module.sass';

import * as R from 'ramda';

const { has } = R;

const hasSubCategory = has('subCategories');
const hasSkillset = has('skills');

const ListContainer = ({ skillList, onSelectSkill, onCloseList }) => {
  const ref = useRef(null);
  const [list, setList] = useState(() => []);
  const [skills, setSkills] = useState(() => null);

  useEffect(() => {
    setList(() => skillList);
  }, [skillList]);

  useOutsideClick(ref, () => {
    onCloseList(false);
  });

  const filterCategory = id => {
    const category = skillList.filter(item => item.id === id);
    setList(() => category);
  };

  const filterSubCategory = subCategoryId => {
    const filteredList = skillList.reduce((acc, curr) => {
      if (curr.subCategories.some(i => i.id === subCategoryId)) {
        return curr.subCategories.filter(i => i.id === subCategoryId);
      } else {
        return acc;
      }
    }, []);

    setList(() => filteredList);
  };

  const selectSkill = (id, label) => {
    onSelectSkill(id, label);
  };

  const skillListCreator = item => {
    if (hasSubCategory(item)) {
      return (
        <CategoryHeader
          key={item.id}
          label={item.label}
          id={item.id}
          doFilterCategory={filterCategory}
        >
          {item.subCategories.map(skillListCreator)}
        </CategoryHeader>
      );
    } else if (hasSkillset(item)) {
      return (
        <SubCategoryHeader
          key={item.id}
          label={item.label}
          id={item.id}
          doFilterSubCategory={filterSubCategory}
        >
          {item.skills.map(skillListCreator)}
        </SubCategoryHeader>
      );
    } else {
      return <SkillsHeader key={item.id} item={item} onSelectSkill={selectSkill} />;
    }
  };

  useEffect(() => {
    const generatedSkills = list.map(item => skillListCreator(item));
    setSkills(() => generatedSkills);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, setSkills]);

  return (
    <div ref={ref} className={styles.list}>
      {skills ? <>{skills}</> : <LoaderContainer size="small" />}
    </div>
  );
};

ListContainer.propTypes = {
  skillList: PropTypes.array.isRequired,
  onSelectSkill: PropTypes.func.isRequired,
  onCloseList: PropTypes.func.isRequired,
};

export default ListContainer;
