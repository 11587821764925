import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  FETCH_STARBOOK_DATA,
  FETCH_STARBOOK_DATA_SUCCESS,
  FETCH_STARBOOK_DATA_ERROR,
  SHOW_MESSAGE,
} from './../../store/constants';
import { getStarbookData } from './../starbookApiCalls';

export function* getStarbookDataSaga(action) {
  try {
    const result = yield call(getStarbookData, action.payload);

    yield all([
      put({
        type: FETCH_STARBOOK_DATA_SUCCESS,
        payload: result.data,
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: FETCH_STARBOOK_DATA_ERROR,
        payload: error,
      }),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* watchGetStarbookDataSaga() {
  yield takeLatest(FETCH_STARBOOK_DATA, getStarbookDataSaga);
}
