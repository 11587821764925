import {
  START_POLLING,
  STOP_POLLING,
  STATUS_UPDATE,
  HANDLE_TIMESHEET,
  JOB_CLEANUP,
} from './../store/constants';
import { success, error } from './../common/helpers/constanHelpers';
import { createSimpleAction, createConstantAction, createComplexAction } from './actionCreator';

const doStartPolling = createConstantAction(START_POLLING);
const doStopPolling = createConstantAction(STOP_POLLING);
const doStopPollingError = createConstantAction(error(STOP_POLLING));

const doStatusUpdate = createSimpleAction(STATUS_UPDATE);
const doStatusUpdateSuccess = createSimpleAction(success(STATUS_UPDATE));
const doStatusUpdateError = createSimpleAction(error(STATUS_UPDATE));

const doHandleTimesheet = createSimpleAction(HANDLE_TIMESHEET);
const doHandleTimesheetSuccess = createSimpleAction(success(HANDLE_TIMESHEET));
const doHandleTimesheetError = createSimpleAction(error(HANDLE_TIMESHEET));

const doTimesheetCleanup = createComplexAction(JOB_CLEANUP);
const doTimesheetCleanupSuccess = createSimpleAction(success(JOB_CLEANUP));
const doTimesheetCleanupError = createSimpleAction(error(JOB_CLEANUP));

export {
  doStartPolling,
  doStopPolling,
  doStopPollingError,
  doHandleTimesheet,
  doStatusUpdate,
  doHandleTimesheetSuccess,
  doHandleTimesheetError,
  doStatusUpdateSuccess,
  doStatusUpdateError,
  doTimesheetCleanup,
  doTimesheetCleanupSuccess,
  doTimesheetCleanupError,
};
