import { createSelector } from 'reselect';
import * as R from 'ramda';

const { has, ifElse } = R;

const hasSubCategory = has('subCategories');

const parseSubCategory = ifElse(
  (data) => hasSubCategory(data),
  (data) => {
    const item = {
      label: data.name,
      id: data.id,
      subCategories: data.subCategories.map(parseSubCategory),
    };
    return item;
  },
  (data) => {
    // console.log('sub', data);
    const skills = data.skillsets.map((item) => ({
      label: item.name,
      id: item.id,
    }));
    return {
      label: data.name,
      id: data.id,
      skills: skills,
    };
  },
);

const parseSkill = (skill) => parseSubCategory(skill);

const getSkillsState = (state) => state.skills;

const fetchLoading = createSelector([getSkillsState], (skills) => skills.loading);

const fetchError = createSelector([getSkillsState], (skills) => skills.error);

const fetchSkillList = createSelector([getSkillsState], (skills) => skills.list);

const getParsedSkills = createSelector([getSkillsState], (skills) => skills.list.map(parseSkill));

export { fetchLoading, fetchError, fetchSkillList, getParsedSkills };
