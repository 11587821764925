import * as R from 'ramda';
import { success, error } from './../../common/helpers/constanHelpers';
import createReducer from '../reducer-helpers/createReducer';
import { setLoading, setError } from '../reducer-helpers/reducerHelpers';

import {
  FETCH_LOCATIONS,
  POST_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
} from './../../store/constants';

const INITAL_STATE = {
  error: false,
  loading: false,
  items: [],
  success: false,
};

const setItems = (action) => (state) =>
  R.evolve(
    {
      loading: R.always(false),
      items: () => action.payload,
    },
    state,
  );

const setSuccess = (_) =>
  R.evolve({
    loading: R.always(false),
    success: R.always(true),
  });

const locationManagerReducer = createReducer(INITAL_STATE, {
  [FETCH_LOCATIONS]: setLoading,
  [success(FETCH_LOCATIONS)]: setItems,
  [error(FETCH_LOCATIONS)]: setError,
  [POST_LOCATION]: setLoading,
  [error(POST_LOCATION)]: setError,
  [success(POST_LOCATION)]: setSuccess,
  [DELETE_LOCATION]: setLoading,
  [error(DELETE_LOCATION)]: setError,
  [success(DELETE_LOCATION)]: setSuccess,
  [UPDATE_LOCATION]: setLoading,
  [error(UPDATE_LOCATION)]: setError,
  [success(UPDATE_LOCATION)]: setSuccess,
});

export default locationManagerReducer;
