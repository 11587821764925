import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bulma-components';

import { doCloseModal } from './../../../actions/modal';

import { doConfirmDelete } from './../../../actions/skills';

import styles from './DeleteConfirmModal.module.scss';

const DeleteConfirmModal = (props) => {
  return (
    <Modal.Card>
      <Modal.Card.Head>
        <Modal.Card.Title>Confirm delete</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        This item is used in {props.deleteCount} places. Are you sure?
        <div className={styles.buttonContainer}>
          <button type="button" className="button" onClick={props.onConfirm}>
            Confirm
          </button>
          <button
            type="button"
            onClick={props.onClose}
            className={'button is-danger ' + styles.buttonSpace}
          >
            Cancel
          </button>
        </div>
      </Modal.Card.Body>
    </Modal.Card>
  );
};

DeleteConfirmModal.propTypes = {
  deleteCount: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    deleteCount: state.skills.count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(doCloseModal()),
    onConfirm: () => dispatch(doConfirmDelete()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmModal);
