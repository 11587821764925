import {
  AUTHENTICATED,
  IS_AUTHENTICATED,
  NOT_AUTHENTICATED,
  DECREASE_PESOS,
} from '../store/constants';

const initalState = {
  authenticated: false,
  fetched: false,
  loading: false,
  error: false,
};

const loginReducer = (state = initalState, action) => {
  switch (action.type) {
    case IS_AUTHENTICATED:
      return { ...state, loading: true };
    case NOT_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        authenticated: false,
        fetched: true,
      };
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        authenticated: true,
        fetched: true,
        ...action.payload,
      };
    case DECREASE_PESOS:
      return {
        ...state,
        user: { ...state.user, pesoCount: state.user.pesoCount - action.payload },
      };
    default:
      return state;
  }
};

export default loginReducer;
