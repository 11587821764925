import { 
    GET_CRON,
    GET_CRON_SUCCESS,
    GET_CRON_ERROR,
} from '../store/constants';

const initalState = {
    loading: false,
    error: false,
    settings: false
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case GET_CRON:
            return {...state, loading: true, error: false };
        case GET_CRON_SUCCESS:
            return {...state, loading: false, settings: action.payload};
        case GET_CRON_ERROR: 
            return {...state, loading: false, error: true}
        default: 
            return state;
    }
}

export default reducer;