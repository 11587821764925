const levels = ['Novice', 'Intermediate', 'Advanced', 'Expert'];

const langLeves = [
  'Limited working',
  'Professional working',
  'Full professional',
  'Native or bilingual',
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const educationLevels = ['BA', 'BSc', 'BENG', 'MA', 'MSc', 'MBA', 'PhD'];

const currencys = ['eur', 'huf', 'usd', 'gbp', 'aud'];

const expenseSheetOrderFields = [
  { value: 'created', label: 'Created' },
  { value: 'employeeName', label: 'Name' },
  { value: 'fromDate', label: 'From' },
  { value: 'toDate', label: 'To' },
  { value: 'approved', label: 'Approved?' },
];

const metaDataLabels = {
  fullName: 'Name',
  birthDate: 'Date of birth',
  birthCity: 'City of birth',
  birthCountry: 'Country of birth',
  phoneNumber: 'Phone number',
  travelDocumentId: 'Travel document ID',
  estaRenewalNeeded: 'ESTA renewal needed',
  flightRequestDetails: 'Flight request details',
  hotelRequestDetails: 'Hotel request details',
  holidaysDuringTravel: 'Holidays during travel',
};

const permissionLabels = {
  public: 'Public',
  admin: 'Admin',
  departmentAdmin: 'Department admin',
  skillAdmin: 'Skill admin',
  permissionAdmin: 'Permission admin',
  trainingAdmin: 'Training admin',
  starbookAdmin: 'Starbook admin',
  tsReviewAdmin: 'Timesheet review admin',
  expenseSheetsReader: 'Expense sheets reader',
  expenseSheetsAdmin: 'Expense sheets admin',
  cvAdmin: 'CV admin',
  locationAdmin: 'Location admin',
  pesoAdmin: 'PESO admin',
  merchAdmin: 'Merchandise admin',
};

export {
  levels,
  langLeves,
  months,
  educationLevels,
  currencys,
  expenseSheetOrderFields,
  metaDataLabels,
  permissionLabels,
};
