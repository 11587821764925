import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  FETCH_STARSHOP_DATA,
  FETCH_STARSHOP_ORDER_HISTORY,
  POST_STARSHOP_PURCHASE,
  FETCH_STARSHOP_ADMIN_ORDER_HISTORY,
  POST_STARSHOP_ADMIN_UPDATE_ORDER,
  CREATE_STARSHOP_ADMIN_MERCH,
  SHOW_MESSAGE,
  DELETE_STARSHOP_ADMIN_MERCH,
  UPDATE_STARSHOP_ADMIN_MERCH,
} from './../store/constants';

import {
  doFetchStarshopData,
  doFetchStarshopDataSuccess,
  doFetchStarshopDataError,
  doFetchStarshopOrderHistory,
  doFetchStarshopOrderHistorySuccess,
  doFetchStarshopOrderHistoryError,
  doPostStarshopPurchase,
  doPostStarshopPurchaseSuccess,
  doPostStarshopPurchaseError,
  doFetchStarshopAdminOrderHistory,
  doFetchStarshopAdminOrderHistorySuccess,
  doFetchStarshopAdminOrderHistoryError,
  doPostStarshopAdminUpdateOrder,
  doPostStarshopAdminUpdateOrderSuccess,
  doPostStarshopAdminUpdateOrderError,
  doCreateStarshopAdminMerch,
  doCreateStarshopAdminMerchSuccess,
  doCreateStarshopAdminMerchError,
  doDeleteStarshopAdminMerchSuccess,
  doUpdateStarshopAdminMerch,
  doUpdateStarshopAdminMerchSuccess,
  doUpdateStarshopAdminMerchError,
} from './../actions/starshop';
import { doCloseModal } from './../actions/modal';

import {
  loadAdminOrderHistory,
  loadMerchItems,
  loadOrderHistory,
  postPurchase,
  updateAdminOrder,
  deleteAdminMerch,
  createAdminMerch,
  updateAdminMerch,
} from './apiCalls';
import * as R from 'ramda';
import { doDecreasePeso } from '../actions/app';

export function* getFetchStarshopDataSaga(action) {
  try {
    const result = yield call(loadMerchItems);
    yield all([put(doFetchStarshopDataSuccess(result.data.items))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchStarshopDataError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* getFetchStarshopOrderHistory(action) {
  try {
    const result = yield call(loadOrderHistory);
    yield all([put(doFetchStarshopOrderHistorySuccess(result.data.items))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchStarshopOrderHistoryError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* postStarshopPurchase(action) {
  try {
    const result = yield call(postPurchase, action.payload);
    yield all([
      put(doPostStarshopPurchaseSuccess(result.data)),
      put(doDecreasePeso(result.data.merch.cost)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Successful purchase!',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doPostStarshopPurchaseError(error)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.response.data.message,
        },
      }),
    ]);
  }
}

// ADMIN
export function* getFetchStarshopAdminOrderHistory(action) {
  try {
    const result = yield call(loadAdminOrderHistory, action.payload);
    yield all([put(doFetchStarshopAdminOrderHistorySuccess(result.data.items))]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doFetchStarshopAdminOrderHistoryError(error)),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.message,
        },
      }),
    ]);
  }
}

export function* postStarshopUpdateOrder(action) {
  try {
    const result = yield call(updateAdminOrder, action.payload);
    yield all([
      put(doPostStarshopAdminUpdateOrderSuccess(result.data)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Successful update!',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doPostStarshopAdminUpdateOrderError(error)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.response.data.message,
        },
      }),
    ]);
  }
}

export function* createStarshopAdminMerch(action) {
  try {
    const result = yield call(createAdminMerch, action.payload);
    yield all([
      put(doCreateStarshopAdminMerchSuccess(result.data)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Successful create!',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doCreateStarshopAdminMerchError(error)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.response.data.message,
        },
      }),
    ]);
  }
}
export function* deleteStarshopAdminMerch(action) {
  try {
    yield call(deleteAdminMerch, action.payload);
    yield all([
      put(doDeleteStarshopAdminMerchSuccess(action.payload)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Successful delete!',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doDeleteStarshopAdminMerchSuccess(error)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.response.data.message,
        },
      }),
    ]);
  }
}
export function* updateStarshopAdminMerch(action) {
  try {
    const result = yield call(updateAdminMerch, action.payload);
    yield all([
      put(doUpdateStarshopAdminMerchSuccess(result.data)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'success',
          content: 'Successful update!',
        },
      }),
    ]);
  } catch (error) {
    console.log(error);
    yield all([
      put(doUpdateStarshopAdminMerchError(error)),
      put(doCloseModal()),
      put({
        type: SHOW_MESSAGE,
        payload: {
          type: 'error',
          content: error.response.data.message,
        },
      }),
    ]);
  }
}

export function* watchFetchStarshopData() {
  yield takeLatest(FETCH_STARSHOP_DATA, getFetchStarshopDataSaga);
}
export function* watchFetchStarshopOrderHistory() {
  yield takeLatest(FETCH_STARSHOP_ORDER_HISTORY, getFetchStarshopOrderHistory);
}
export function* watchPostStarshopPurchase() {
  yield takeLatest(POST_STARSHOP_PURCHASE, postStarshopPurchase);
}
export function* watchFetchStarshopAdminOrderHistory() {
  yield takeLatest(FETCH_STARSHOP_ADMIN_ORDER_HISTORY, getFetchStarshopAdminOrderHistory);
}
export function* watchPostStarshopAdminUpdateOrder() {
  yield takeLatest(POST_STARSHOP_ADMIN_UPDATE_ORDER, postStarshopUpdateOrder);
}
export function* watchCreateStarshopAdminMerch() {
  yield takeLatest(CREATE_STARSHOP_ADMIN_MERCH, createStarshopAdminMerch);
}
export function* watchDeleteStarshopAdminMerch() {
  yield takeLatest(DELETE_STARSHOP_ADMIN_MERCH, deleteStarshopAdminMerch);
}
export function* watchUpdateStarshopAdminMerch() {
  yield takeLatest(UPDATE_STARSHOP_ADMIN_MERCH, updateStarshopAdminMerch);
}
