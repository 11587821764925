import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PROFILE_FETCH, SET_PROFILE_QUERY } from '../store/constants';

import { loadProfile as getProfile } from './apiCalls';

import {
  doFetchProfile,
  doFetchProfileSuccess,
  doFetchProfileError,
} from './../actions/work-details';

const getLoginState = state => state.login.jwtToken;

export function* fetchProfile(action) {
  try {
    const token = yield select(getLoginState);

    const profile = yield call(getProfile, {
      data: {
        ...action.payload,
        month: action.payload.month - 1,
      },
      token,
    });

    const colorCodedLines = profile.data.lines.map(line => {
      const returnObject = {
        ...line,
      };
      returnObject.weekend =
        line.day === 'Sunday' || line.day === 'Saturday'
          ? true
          : false;

      return returnObject;
    });

    const payload = {
      ...profile.data,
      lines: colorCodedLines,
    };

    yield put(doFetchProfileSuccess(payload));
  } catch (error) {
    yield put(doFetchProfileError(error));
  }
}

export function* setProfilyQuery(action) {
  yield put(doFetchProfile(action.payload));
}

export function* watchProfileRequest() {
  yield takeLatest(PROFILE_FETCH, fetchProfile);
}

export function* watchSetQueryRequest() {
  yield takeLatest(SET_PROFILE_QUERY, setProfilyQuery);
}
