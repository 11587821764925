import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './InputContainer.module.sass';

const InputContainer = ({ onToggleList, onSearch, onClear, placeholder, selected, listShow }) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(() => '');

  const showList = () => onToggleList(true);
  const toggleList = () => {
    if (!listShow) {
      inputRef.current.focus();
    }
    onToggleList(!listShow);
  };

  const search = e => {
    const term = e.target.value;
    setValue(() => term);
  };

  useEffect(() => {
    if (selected.length === 0) {
      const timer = setTimeout(() => {
        onSearch(value);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [value, selected.length, onSearch]);

  const clearValue = () => {
    onClear();
  };

  useEffect(() => {
    if (selected.length > 0) {
      const newValue = selected[0].label;
      setValue(() => newValue);
    } else {
      setValue(() => '');
    }
  }, [selected]);

  return (
    <div>
      <div className="control has-icons-right">
        <input
          className="input"
          type="text"
          onClick={showList}
          placeholder={placeholder}
          value={value}
          onChange={search}
          ref={inputRef}
        />
        {selected.length === 0 && (
          <span className={`icon is-small is-right ${styles.selectIcon}`} onClick={toggleList}>
            {listShow ? (
              <FontAwesomeIcon icon="chevron-up" />
            ) : (
              <FontAwesomeIcon icon="chevron-down" />
            )}
          </span>
        )}
        {selected.length > 0 && (
          <span className={`icon is-small is-right ${styles.selectIcon}`} onClick={clearValue}>
            <FontAwesomeIcon icon="times" />
          </span>
        )}
      </div>
    </div>
  );
};

InputContainer.propTypes = {
  onToggleList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
};

export default InputContainer;
