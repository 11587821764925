import * as R from 'ramda';
import createReducer from './../reducer-helpers/createReducer';
import { success, error } from './../../common/helpers/constanHelpers';
import { setLoading, setError } from './../reducer-helpers/reducerHelpers';

import { UPDATE_CV_LANGUAGES, UPDATE_CV_META } from '../../store/constants';

export const setSuccess = (_) =>
  R.evolve({
    loading: R.always(false),
    error: R.always(false),
    success: R.always(true),
  });

const INITIAL_STATE = {
  error: false,
  loading: false,
  success: false,
};

const cvReducer = createReducer(INITIAL_STATE, {
  [UPDATE_CV_LANGUAGES]: setLoading,
  [success(UPDATE_CV_LANGUAGES)]: setSuccess,
  [error(UPDATE_CV_LANGUAGES)]: setError,
  [UPDATE_CV_META]: setLoading,
  [success(UPDATE_CV_META)]: setSuccess,
  [error(UPDATE_CV_META)]: setError,
});

export default cvReducer;
