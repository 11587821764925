import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-reborn';

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#dbdbdb',
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: '0.75rem',
    marginBottom: '8px',
    textAlign: 'left',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(54,54,54, 0.3)',
  }),
};

export const errorStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: 'red',
  }),
};

const MultiSelect = ({
  field,
  form: { touched, errors, /* handleChange */ setFieldValue, setTouched },
  list,
  loading,
  readonly,
  hasError,
  ...props
}) => {
  const [value, setValue] = useState(() => '');

  const handleChange = (value) => {
    const newValue = value ? value.value : '';
    setFieldValue(field.name, newValue);
    setTouched({ ...touched, [field.name]: true });
  };

  useEffect(() => {
    const newValue = field.value ? list.filter((o) => field.value === o.value) : '';
    setValue(() => newValue);
  }, [field.value, list]);

  return (
    <Select
      id={props.id}
      styles={{ ...customStyles, ...(hasError && errorStyles) }}
      isClearable
      isDisabled={readonly || loading}
      isLoading={loading}
      onChange={handleChange}
      options={list}
      value={value}
      placeholder={props.placeholder}
    />
  );
};

MultiSelect.propTypes = {
  list: PropTypes.array.isRequired,
};

export default MultiSelect;
