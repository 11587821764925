import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoaderContainer from './../../../common/loader/LoaderContainer';

import { doSetSearchParams } from './../../../actions/starbook';

import styles from './StarCard.module.scss';

import ninja from './../../../common/user-ninja.svg';

const createLink = (user, loggedInUserId) =>
  user.adminViewAvailable || loggedInUserId === user.id
    ? `/profile/extended/${user.id}`
    : `/profile/normal/${user.id}`;

const StarCard = React.memo(({ user, onSetSearchParams, loggedInUser }) => {
  const [imgLoading, setImgLoading] = useState(() => true);
  const userImage = user.imageLinks.large ? (
    <>
      {imgLoading && (
        <div className={styles.imageLoader}>
          <LoaderContainer size="small" />
        </div>
      )}
      <img
        src={user.imageLinks.large}
        alt="Profile"
        className={styles.squareImage}
        onLoad={() => setImgLoading(() => false)}
      />
    </>
  ) : (
    <img src={ninja} alt="Placeholder" className={styles.placeholderImage} />
  );

  const linkToProfile = createLink(user, loggedInUser.id);

  const inline = {
    backgroundColor: user.department ? user.department.color + '99' : 'FFFFFF99',
  };

  const doSearch = (key, value) => onSetSearchParams({ [key]: value });

  return (
    <div className={`card ${styles.flexCard}`}>
      <header
        className={`card-header ${styles.clickableHeader}`}
        onClick={() => doSearch('departmentIds', [user.department.maconomyId])}
      >
        <p className={`card-header-title ${styles.cardDepartentHeader}`} style={inline}>
          {user.department ? user.department.name : 'The Lonely Island'}
        </p>
      </header>
      <div className="card-image">
        <Link to={linkToProfile}>
          <figure className={`image is-square ${styles.figureBorder}`}>{userImage}</figure>
        </Link>
      </div>
      <h4 className={`title is-4 has-text-left ${styles.nameTitle}`}>
        <Link to={linkToProfile} className={styles.title4Link}>
          {user.name}
        </Link>
      </h4>
      <div className={`has-text-left ${styles.position}`}>
        <button
          className={`${styles.cleanButton}`}
          onClick={() => doSearch('positions', [user.position])}
        >
          <h6 className="title is-6 has-text-left">{user.position}</h6>
        </button>
      </div>
      <div className="card-content">
        <div className={`content has-text-left`}>
          <ul className={styles.infoList}>
            <li>
              <a
                href={`slack://user?team=T08PB8M0A&id=${user.slackId}`}
                className={styles.starCardLink}
              >
                <FontAwesomeIcon icon={['fab', 'slack']} className={styles.iconSpacer} /> Start
                conversation
              </a>
            </li>
            <li className={styles.emailLi}>
              <FontAwesomeIcon icon="envelope" className={styles.iconSpacer} />{' '}
              <a
                className={styles.starCardLink}
                href={`mailto://${user.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.email}
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon="phone" className={styles.iconSpacer} />
              {user.companyPhoneNo || user.privatePhoneNo}
            </li>
            <li>
              <FontAwesomeIcon icon="couch" className={styles.iconSpacer} />
              {user.manager ? (
                <button
                  className={styles.linkButton}
                  onClick={() => doSearch('managerIds', [user.manager.id])}
                >
                  {user.manager.name}
                </button>
              ) : (
                <>-</>
              )}
            </li>
            <li>
              <FontAwesomeIcon icon="file-alt" className={styles.iconSpacer} />
              <Link to={`/starbook/cv/${user.id}`} className={styles.starCardLink}>
                CVs
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

StarCard.propTypes = {
  user: PropTypes.object.isRequired,
  onFetchStarbookData: PropTypes.func,
  loggedInUser: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSearchParams: (params) => dispatch(doSetSearchParams(params)),
  };
};

export default connect(null, mapDispatchToProps)(StarCard);
