import { createSelector } from 'reselect';

const pesoState = (state) => state.peso;

const getPesoHistoryLoading = createSelector([pesoState], (peso) => peso.loading);
const getPesoHistoryError = createSelector([pesoState], (peso) => peso.error);
const getPesoHistoryData = createSelector(pesoState, (peso) => peso.data);

const getPesoUserHistoryLoading = createSelector(pesoState, (peso) => peso.loading);
const getPesoUserHistoryError = createSelector(pesoState, (peso) => peso.error);
const getPesoUserHistoryData = createSelector(pesoState, (peso) => peso.data);

const getPesoTransferLoading = createSelector(pesoState, (peso) => peso.loading);
const getPesoTransferError = createSelector(pesoState, (peso) => peso.error);
const getPesoTransferData = createSelector(pesoState, (peso) => peso.transfer);

const getPesoGiveLoading = createSelector(pesoState, (peso) => peso.loading);
const getPesoGiveError = createSelector(pesoState, (peso) => peso.error);
const getPesoGiveData = createSelector(pesoState, (peso) => peso.transfer);

const getPesoTakeLoading = createSelector(pesoState, (peso) => peso.loading);
const getPesoTakeError = createSelector(pesoState, (peso) => peso.error);
const getPesoTakeData = createSelector(pesoState, (peso) => peso.transfer);

export {
    getPesoHistoryLoading,
    getPesoHistoryError,
    getPesoHistoryData,
    getPesoUserHistoryLoading,
    getPesoUserHistoryError,
    getPesoUserHistoryData,
    getPesoTransferLoading,
    getPesoTransferError,
    getPesoTransferData,
    getPesoGiveLoading,
    getPesoGiveError,
    getPesoGiveData,
    getPesoTakeLoading,
    getPesoTakeError,
    getPesoTakeData,
};
