import React from 'react';
import PropTypes from 'prop-types';

import styles from './SkillHeader.module.sass';

const SkillHeader = ({ item, onSelectSkill }) => {
  return (
    <div className={styles.skill} onClick={() => onSelectSkill(item.id, item.label)}>
      {item.label}
    </div>
  );
};

SkillHeader.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectSkill: PropTypes.func.isRequired,
};

export default SkillHeader;
