import React from 'react';
import PropTypes from 'prop-types';

const TextareaField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  help,
  ...props
}) => {
  const displayError = touched[field.name] && errors[field.name];

  return (
    <div className="field">
      <label className="label">
        {/* {props.checkbox && <div>checbox</div>} */}
        {props.label}
      </label>
      <div className="control">
        <textarea
          className={`textarea  ${displayError ? 'is-danger' : ''}`}
          {...field}
          {...props}
        ></textarea>
      </div>
      {displayError && <p className="help is-danger">{errors[field.name]}</p>}
      {help && <p className="help">{help}</p>}
    </div>
  );
};

TextareaField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default TextareaField;
