import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { 
    GET_CRON,
    GET_CRON_SUCCESS,
    GET_CRON_ERROR,
    SHOW_MESSAGE
} from '../../store/constants';
import { getCronSettings } from '../apiCalls'; 

const getLoginState = (state) => state.login.jwtToken;

export function* getCrons(action) {
    try {
        const token = yield select(getLoginState);
        
        const cronSettings = yield call(getCronSettings, {...action.payload, token});
        
        yield all([
            put({
                type: GET_CRON_SUCCESS,
                payload: cronSettings.data
            })       
        ]);
    }
    catch(error) {
        console.log(error);
        yield all([
            put({
                type: GET_CRON_ERROR,
                payload: error
            }),
            put({
                type: SHOW_MESSAGE,
                payload: {
                    type: 'error',
                    content: 'Error getting cron job settings.'
                }
            })
        ])
    }
}

export function* watchGetCron() {
    yield takeLatest(GET_CRON, getCrons); 
}
